/*-----------------------------------------------------------------------------------

Template Name:Rica
Template URI: themes.pixelstrap.com/rica
Description: This is booking website
Author: Pixelstrap
Author URI: https://themeforest.net/user/pixelstrap

----------------------------------------------------------------------------------- */


/* 1. General CSS
1.1. Button CSS
1.2. Title CSS
1.3. Image Ratio CSS */


/* 2. Header CSS */


/* 3. Home CSS  */


/* 4. Slick slider CSS  */


/* 5. Instagram CSS */


/* 6. Logo CSS  */


/* 7. Blog CSS  */


/* 8. App section CSS  */


/* 9. Service CSS */


/* 10. Testimonial CSS */


/* 11. Subscribe CSS */


/* 12. About CSS */


/* 13. Ticket/video section CSS */


/* 14. Gallery section CSS */


/* 15. Category section CSS */


/* 16. Filter section CSS */


/* 17. Full banner section css */


/* 18. Other section CSS */


/* 19. Theme modal CSS */


/* 20. Map CSS */


/* 21. Inner pages CSS */


/* 22. Portfolio CSS */


/* 23. Blog pages CSS*/


/* 24. Footer CSS */


/* 25. Loader CSS */


/* 26. Tap to top CSS */


/* 27. Animation & effect CSS */


/* 28. Theme setting CSS */


/* 29. Menu CSS */


/* 30. Price range CSS */


/* 31. RTL CSS */


/* 32. Dark CSS */


/* 33. Responsiveenu CSS */


/*=====================
01. General css start
==========================*/

body {
    font-family: $font_0, $font_1;
    position: relative;
    background: white;
    font-size: 14px;
    color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font_0, $font_1;
    font-weight: normal;
}

h1 {
    font-size: calc(26px + (50 - 26) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 1.1;
    text-transform: uppercase;
}

h2 {
    font-size: calc(22px + (36 - 22) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    letter-spacing: 0.001em;
    line-height: 32px;
    text-transform: capitalize;
    color: black;
}

h3 {
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1.2;
    color: black;
}

h4 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1.2;
    text-transform: capitalize;
}

h5 {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1.2;
}

h6 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.03em;
}

ul {
    padding-left: 0;
    margin-bottom: 0;
}

li {
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.03em;
}

p {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    letter-spacing: 0.03em;
}

a {
    transition: 0.5s ease;
    letter-spacing: 0.03em;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        transition: 0.5s ease;
    }

    &:focus {
        outline: none;
    }
}

label {
    letter-spacing: 0.03em;
}

button,
.btn {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

section,
.section-t-space {
    padding-top: 100px;
}

.section_space {
    padding-top: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.section-b-space {
    padding-bottom: 100px;
}

.medium-section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.small-section {
    padding-top: 70px;
    padding-bottom: 70px;
}

.xs-section {
    padding: 30px 0;
}

.parallax-img {
    background-attachment: fixed;
}

.radius-cls {
    border-radius: 20px;

    .social-box {
        border-radius: 6px;
    }
}

.bg-inner {
    background-color: $inner-bg;
}

.lh-cls {
    line-height: 1 !important;
}

.w-80 {
    width: 80%;
}

.mb-down {
    margin-bottom: -30px;
}

.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 15px;
}

.form-check-input {
    &:focus {
        box-shadow: none;
    }
}

.media {
    display: flex;
    align-items: flex-start;

    .media-body {
        flex: 1;
    }
}

.radio_animated {
    cursor: pointer;
    margin-left: 0;
    position: relative;
    margin-right: 12px;

    &:before {
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: scale(0, 0);
        content: "";
        position: absolute;
        top: 0;
        left: 0.125rem;
        z-index: 1;
        width: 0.75rem;
        height: 0.75rem;
        background: $theme-color1;
        border-radius: 50%;
    }

    &:after {
        content: "";
        position: absolute;
        top: -0.25rem;
        left: -0.125rem;
        width: 1.25rem;
        height: 1.25rem;
        background: white;
        border: 2px solid #e8ebf2;
        border-radius: 50%;
    }

    &:checked:before {
        transform: scale(1, 1);
    }
}

.checkbox_animated {
    cursor: pointer;
    margin-left: -1.25rem;
    position: relative;
    margin-right: 12px;
    border: none;

    &:before {
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: rotate(-45deg) scale(0, 0);
        content: "";
        position: absolute;
        left: 0.25rem;
        top: 0.225rem;
        z-index: 1;
        width: 0.75rem;
        height: 0.375rem;
        border: 2px solid $theme-color1;
        border-top-style: none;
        border-right-style: none;
    }

    &:after {
        content: "";
        position: absolute;
        top: -0.125rem;
        left: 0;
        width: 1.3rem;
        height: 1.3rem;
        background: white;
        border: 2px solid #e8ebf2;
        cursor: pointer;
    }

    &:checked:before {
        transform: rotate(-45deg) scale(1, 1);
    }
}

.form-check {
    display: flex;
}

.input-group-prepend, .input-group-append {
    display: flex;
}

.mrg-cls {
    margin-bottom: -3px;
}

.rounded5 {
    border-radius: $radious5 !important;
}

.rounded10 {
    border-radius: $radious10 !important;
}

.rounded15 {
    border-radius: $radious15 !important;
}

.rounded20 {
    border-radius: $radious20 !important;
}

.rounded25 {
    border-radius: $radious25 !important;
}

.breadcrumb {
    padding: 0.75rem 1rem;
}

.zindex-1 {
    z-index: 1;
}

.row {
    div[class*="col-"], .col {
        position: relative;
    }
}

/*Lazy load */

.blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;

    &.lazyloaded {
        -webkit-filter: blur(0);
        filter: blur(0);
    }
}


/*=====================
1.1. Button start
==========================*/
.btn-group-showcase {
    margin-bottom: -20px;

    h3 {
        margin-bottom: 15px;
    }

    .btn {
        margin-bottom: 20px;

        & + .btn {
            margin-left: 15px;
        }
    }
}

.btn-cards {
    .card {
        margin-bottom: 30px;
    }
}

.btn {
    line-height: 20px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    transition: 0.3s ease-in-out;

    &.btn-lower {
        text-transform: capitalize;
    }

    &.btn-sm {
        padding: 8px 24px !important;
        text-transform: capitalize;
    }

    &.white-btn {
        background-color: white !important;
        color: $theme-color1 !important;
        box-shadow: none !important;
    }

    &.black-btn {
        background-color: black !important;
        color: white !important;
        box-shadow: 1px 11px 20px 0px rgba(black, 0.12) !important;

        &:hover {
            box-shadow: 1px 11px 20px 0px rgba(black, 0.22);
            border: 1px solid black;
            background-color: white !important;
            color: black !important;
        }
    }

    &.btn-solid {
        background-color: $theme-color1;
        color: white;
        padding: 9px 20px;
        box-shadow: 1px 11px 20px 0px rgba($theme-color1, 0.12);

        &:hover {
            transition: 0.3s ease-in-out;
            background-color: white;
            color: $theme-color1;
            border: 1px solid $theme-color1;
            box-shadow: 1px 11px 20px 0px rgba($theme-color1, 0.22);
        }

        &.btn-outline {
            background-color: transparent;
            border: 1px solid $theme-color1;
            color: $theme-color1;

            &:hover {
                color: white;
                background-color: $theme-color1;
            }
        }

        &.color2 {
            background-color: $theme-color2;
            box-shadow: 2.5px 4.33px 25px 0px rgba($theme-color2, 0.3);

            &:hover {
                background-color: white;
                color: $theme-color2;
                transition: all 0.5s ease;
                border: 1px solid $theme-color2;
                box-shadow: 1px 11px 20px 0px rgba($theme-color2, 0.22);
            }
        }
    }

    &.btn-curve {
        border-radius: 5px;
        background-color: $theme-color1;
        color: white;
        padding: 9px 20px;
        box-shadow: 1px 11px 20px 0px rgba($theme-color1, 0.12);

        &.btn-lower {
            text-transform: capitalize;
        }
    }

    &.btn-rounded {
        border-radius: 100px;
        color: white;
        padding: 10px 20px;

        &.color1 {
            background-color: $theme-color1;
            box-shadow: 1px 11px 20px 0px rgba($theme-color1, 0.12);

            &:hover {
                background-color: white;
                color: $theme-color1;
                transition: all 0.5s ease;
                border: 1px solid $theme-color1;
                box-shadow: 1px 11px 20px 0px rgba($theme-color1, 0.22);
            }
        }

        &.color2 {
            background-color: $theme-color2;
            box-shadow: 2.5px 4.33px 25px 0px rgba($theme-color2, 0.3);

            &:hover {
                background-color: white;
                color: $theme-color2;
                transition: all 0.5s ease;
                border: 1px solid $theme-color2;
                box-shadow: 1px 11px 20px 0px rgba($theme-color2, 0.22);
            }
        }
    }

    &:focus {
        box-shadow: none;
    }
}

.btn-outline {
    background-color: white;
    border: 1px solid $theme-color1;
    color: $theme-color1;
}

.btn-close {
    &:focus {
        box-shadow: none;
    }
}


/*=====================
1.2. Title css start
==========================*/

.title-1 {
    text-align: center;

    .title-label {
        font-size: 14px;
        color: white;
        background-color: $theme-color1;
        text-transform: uppercase;
        padding: 4px 18px;
        line-height: 1.7;
        border-radius: 3px;
        font-family: $font_2;
        font-weight: 600;

        &.white-label {
            background-color: white;
            color: $theme-color1;
        }
    }

    h2 {
        padding-top: 25px;
        margin-bottom: 0;
        padding-bottom: 50px;
        font-weight: 700;
        color: $theme-color3;
        font-size: 3rem;
        line-height: 3rem;
    }

    &.title-5 {
        .title-label {
            border-radius: 50px;
        }

        h2 {
            padding-top: 22px;
            padding-bottom: 15px;
        }

        p {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
            margin-bottom: 50px;
        }
    }

    &.detail-title {
        .title-label {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }

        h2 {
            padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        }

        p {
            max-width: 60%;
            margin: 0 auto;
            color: rgba(0, 0, 0, 0.41);
            text-transform: capitalize;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.7;
        }
    }

    &.rounded {
        .title-label {
            border-radius: 12px;
        }
    }
}

.title-2 {
    text-align: center;

    h2 {
        font-size: calc(22px + (36 - 22) * ((100vw - 320px) / (1920 - 320)));
        color: $title-grey;
        font-weight: 400;
        margin-bottom: 22px;
        line-height: 32px;
        margin-top: -2px;

        span {
            color: $theme-color2;
            font-weight: 900;
        }
    }

    p {
        margin-bottom: 70px;
        color: $grey-1;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
    }

    &.color-1 {
        h2 {
            span {
                color: $theme-color1;
            }
        }
    }
}

.title-3 {
    text-align: center;

    .title-label {
        font-size: 14px;
        color: white;
        background-color: $theme-color1;
        text-transform: capitalize;
        padding: 4px 18px 6px;
        line-height: 1;
        font-family: $font_2;
        font-weight: 600;
    }

    h2 {
        font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        margin-top: 20px;
        margin-bottom: 90px;
        font-weight: 700;
        position: relative;

        span {
            font-family: $font_3, $font_4;
            font-size: calc(35px + (85 - 35) * ((100vw - 320px) / (1920 - 320)));
            position: absolute;
            color: rgba($theme-color1, 0.1);
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
            z-index: -1;
            font-weight: 400;
        }
    }

    &.detail-title {
        .title-label {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }

        h2 {
            padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 30px;
        }

        p {
            max-width: 60%;
            margin: 0 auto;
            color: rgba(0, 0, 0, 0.41);
            text-transform: capitalize;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.7;
            margin-bottom: 30px;
        }
    }

    &.rounded {
        .title-label {
            border-radius: 50px;
        }
    }

    &.white-title {
        .title-label {
            background-color: white;
            color: $theme-color1;
        }

        h2 {
            color: white;

            span {
                z-index: unset;
                color: rgba(white, 0.07);
            }
        }
    }
}

.title-basic {
    h2 {
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: black;
        font-weight: 600;
    }

    p {
        margin-top: -4px;
        color: rgba(0, 0, 0, 0.7);
    }
}


/*=====================
1.3. Image-ratio start
==========================*/

.ratio_40 {
    .bg-size {
        &:before {
            padding-top: 40%;
            content: "";
            display: block;
        }
    }
}

.ratio_45 {
    .bg-size {
        &:before {
            padding-top: 45%;
            content: "";
            display: block;
        }
    }
}

.ratio2_1 {
    .bg-size {
        &:before {
            padding-top: 50%;
            content: "";
            display: block;
        }
    }
}

.ratio_55 {
    .bg-size {
        &:before {
            padding-top: 55%;
            content: "";
            display: block;
        }
    }
}

.ratio_59 {
    .bg-size {
        &:before {
            padding-top: 59%;
            content: "";
            display: block;
        }
    }
}

.ratio2_3 {
    .bg-size {
        &:before {
            padding-top: 60%;
            content: "";
            display: block;
        }
    }
}

.ratio3_2 {
    .bg-size {
        &:before {
            padding-top: 66.66%;
            content: "";
            display: block;
        }
    }
}

.ratio_90 {
    .bg-size {
        &:before {
            padding-top: 93%;
            content: "";
            display: block;
        }
    }
}

.ratio_landscape {
    .bg-size {
        &:before {
            padding-top: 75%;
            content: "";
            display: block;
        }
    }
}

.ratio_square {
    .bg-size {
        &:before {
            padding-top: 100%;
            content: "";
            display: block;
        }
    }
}

.ratio_asos {
    .bg-size {
        &:before {
            padding-top: 127.7777778%;
            content: "";
            display: block;
        }
    }
}

.ratio_portrait {
    .bg-size {
        &:before {
            padding-top: 150%;
            content: "";
            display: block;
        }
    }
}

.ratio1_2 {
    .bg-size {
        &:before {
            padding-top: 200%;
            content: "";
            display: block;
        }
    }
}

.b-top {
    background-position: top !important;
}

.b-bottom {
    background-position: bottom !important;
}

.b-center {
    background-position: center !important;
}

.b-left {
    background-position: left !important;
}

.b-right {
    background-position: right !important;
}

.b_size_content {
    background-size: contain !important;
}

.shadow-cls {
    box-shadow: 0px 1px 9px 0px rgba(228, 228, 228, 0.7);
}

.container-medium {
    padding: 0 30px;
}


/*=====================
2. Header section css
==========================*/

header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .menu {
        display: flex;
        margin: 0 auto;
        align-items: center;

        nav {
            margin: 0 auto;
        }

        .coupon-box {
            background-color: white;
            border-radius: 4px;
            padding: 3px;

            h6 {
                font-size: 14px;
                text-transform: capitalize;
                font-weight: 700;
                color: $theme-color1;
                margin-bottom: 0;
                border: 1px dashed rgba($theme-color1, 0.4);
                border-radius: 4px;
                padding: 5px 10px;
            }
        }

        .header-right {
            display: flex;
            align-items: center;

            .front-setting {
                background-color: rgba(255, 255, 255, 0.14);

                select {
                    border: none;
                    color: white;
                    -webkit-appearance: none;
                    padding: 5px 28px 4px 10px;
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: 900;
                    background-color: transparent;
                    background: url(../images/icon/down.png) no-repeat 80%;
                    position: relative;
                    cursor: pointer;

                    option {
                        color: black;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            .user {
                background-color: white;
                padding: 6px 12px;

                i {
                    color: $theme-color2;
                    font-size: 12px;
                }

                &.user-light {
                    background-color: rgba(white, 0.14);

                    i {
                        color: white;
                    }
                }

                &.bg_dark {
                    background-color: $theme-color1;
                }
            }

            .setting {
                background-color: rgba(255, 255, 255, 0.14);
                padding: 6px 12px;
                display: none;
                position: relative;

                i {
                    color: white;
                    font-size: 12px;
                }

                .setting-open {
                    position: absolute;
                    width: 100%;
                    display: block;
                    width: 120px;
                    right: 0;
                    background-color: white;
                    bottom: -84px;
                    display: none;

                    li {
                        display: block;

                        select {
                            color: black;
                            font-weight: 400;
                            width: 100%;
                        }

                        + li {
                            margin-left: 0;
                        }
                    }

                    &.show {
                        display: block;
                    }
                }
            }

            .search-bar {
                background-color: rgba(255, 255, 255, 0.14);
                padding: 6px 12px;
                cursor: pointer;
                position: relative;

                i {
                    color: white;
                }

                .form-control-search {
                    top: 50px;
                    position: absolute;
                    transition: all 0.3s linear;
                    right: 0;
                    transform: translateY(-35px) scaleY(0);
                    opacity: 0;
                    visibility: hidden;
                    margin-bottom: 0;

                    input {
                        width: 260px;
                        background-color: rgba(255, 255, 255, 0.32);
                        border: none;
                        padding: 10px 10px 10px 70px;
                        border-radius: 0;

                        &:focus {
                            outline: none;
                        }
                    }

                    i {
                        position: absolute;
                        top: 16px;
                        left: 20px;
                        z-index: 1;
                        color: rgba(black, 0.3);
                    }

                    &:before {
                        position: absolute;
                        content: "";
                        width: 1px;
                        height: 25px;
                        background: #e8ebf2;
                        left: 50px;
                        top: 9px;
                    }

                    &.open {
                        transform: translateY(0) scaleY(1);
                        opacity: 1;
                        visibility: visible;
                        -webkit-transition: all 0.3s linear;
                        transition: all 0.3s linear;
                        z-index: 100;
                    }
                }
            }

            li {
                + li {
                    margin-left: 15px;
                }
            }
        }
    }

    &.overlay-white {
        background-color: rgba(white, 0.08);
    }

    &.overlay-black {
        background-color: rgba(black, 0.18);
    }

    &.overlay-transparent {
        background-color: transparent;
        box-shadow: none;
    }

    &.inner-page {
        border-bottom: 1px solid rgba(white, 0.05);
    }

    &.light_header {
        background-color: white;
        position: relative;
        z-index: unset;

        .main-navbar {
            .nav-menu {
                > li {
                    > a {
                        color: $grey-darker;
                    }
                }
            }
        }

        .menu {
            .header-right {
                .front-setting {
                    background-color: rgb(243, 243, 245);

                    select {
                        color: $grey-darker;
                        background: url(../images/icon/down-black.png) no-repeat 80%;
                    }
                }

                .user {
                    &.user-light {
                        background-color: rgb(243, 243, 245);

                        i {
                            color: black;
                        }
                    }
                }

                .setting {
                    background-color: rgb(243, 243, 245);

                    i {
                        color: black;
                    }
                }
            }

            .brand-logo {
                img {
                    filter: invert(1);
                    width: 75%;
                }
            }
        }

        .main-navbar {
            .nav-menu {
                > li {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }

        .toggle-nav {
            i {
                color: black;
            }
        }

        &.absolute-header {
            position: absolute;
            box-shadow: 0 2px 4px 0 $box-shadow;
        }
    }

    &.fixed-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        box-shadow: 0 2px 4px 0 $box-shadow;
    }

    .mix-pills {
        margin-top: 15px;
        margin-bottom: 12px;

        &.nav-pills .nav-link.active,
        &.nav-pills .show > .nav-link {
            background-color: transparent;
            color: $theme-color1;

            svg {
                fill: $theme-color1;
            }

            &:after {
                opacity: 1;
            }
        }

        &.nav-pills {
            .nav-link {
                padding: 0 13px;
                color: black;
                position: relative;
                display: flex;
                align-items: center;
                font-size: 16px;

                svg {
                    width: 14px;
                    height: auto;
                    margin-right: 8px;
                }
            }
        }

        .nav-item {
            margin-right: 15px;
        }
    }

    &.tour-header {
        z-index: 10;
    }
}

.header-sidebar {
    header {
        padding: 14px;

        .main-navbar {
            .nav-menu {
                position: fixed;
                background-color: white;
                width: 300px;
                border-radius: 0;
                border: 1px solid #eee;
                top: 0;
                height: 100vh;
                right: -300px;
                z-index: 99;
                transition: all 0.3s ease;
                overflow: scroll;

                &.opennav {
                    right: 0;
                }

                .back-btn {
                    display: block;
                    width: 100%;
                    padding: 0;

                    .mobile-back {
                        padding: 20px;
                        font-size: 18px;
                        color: $grey-darker;
                        font-weight: 700;
                        text-transform: uppercase;
                        border-bottom: 1px solid #efefef;
                        cursor: pointer;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                }

                > li {
                    display: block;
                    float: none;
                    width: 100%;
                    padding: 0;

                    a {
                        padding: 10px 15px;
                        font-size: 16px;
                        font-weight: 700;
                        position: relative;
                        color: $grey-darker;

                        .sub-arrow {
                            right: 20px;
                            position: absolute;

                            &:before {
                                content: "+";
                                font-family: inherit;
                                position: relative;
                            }
                        }
                    }

                    .nav-submenu {
                        width: 100%;
                        padding: 0;
                        position: relative;
                        display: none;
                        opacity: 1;
                        visibility: visible;

                        &.opensubmenu {
                            display: block;
                        }

                        li {
                            .nav-sub-childmenu {
                                display: none;
                                position: relative;
                                right: 0;
                                width: 100%;
                                padding: 0;

                                &.opensubchild {
                                    display: block;
                                }

                                li {
                                    a {
                                        padding: 0 45px;
                                    }
                                }
                            }

                            a {
                                &:after {
                                    display: none;
                                }

                                &:hover {
                                    margin-left: 0;

                                    &:after {
                                        display: none;
                                    }
                                }
                            }

                            .level1 {
                                li {
                                    &:hover {
                                        .level2 {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .level1 {
                                    display: none;
                                }
                            }
                        }
                    }

                    .mega-menu-container {
                        display: none;
                        padding: 0;

                        &.opensubmenu {
                            display: block;
                        }

                        .mega-box {
                            width: 100%;
                            padding: 0;

                            .link-section {
                                .submenu-title {
                                    position: relative;

                                    h5 {
                                        margin-bottom: 0;
                                        font-weight: 400;
                                        line-height: 1.9;
                                        padding: 2px 25px;
                                    }

                                    .according-menu {
                                        top: 4px;
                                    }
                                }

                                .submenu-content {
                                    display: none;

                                    &.opensubmegamenu {
                                        display: block;
                                        padding: 0 45px;
                                    }

                                    ul {
                                        li {
                                            a {
                                                line-height: 1.9;

                                                &:hover {
                                                    margin-left: 0;

                                                    &:after {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .nav-link {
                        &.active {
                            background-color: transparent;
                            box-shadow: none;
                            color: $grey-darker;
                            transition: all 0.5s ease;

                            &:hover {
                                color: $theme-color1;
                                transition: all 0.5s ease;
                            }
                        }
                    }

                    &:hover {
                        .nav-submenu {
                            //opacity: 0;
                            //visibility: hidden;
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .menu {
            nav {
                margin-left: auto;
                margin-right: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .mega-box {
        .link-section {
            .demo {
                ul {
                    li {
                        a {
                            span {
                                img {
                                    top: 30px;
                                    right: -60px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .mega-box {
        &.col {
            flex-basis: unset;
        }
    }

    .according-menu {
        position: absolute;
        right: 20px;
    }

    .main-menu {
        &.border-section {
            border: none;
        }

        .menu-left {
            .main-menu-right {
                .toggle-nav {
                    position: absolute;
                    z-index: 2;
                    right: 145px;
                    top: 6px;

                    .sidebar-name {
                        font-size: 18px;
                        padding-left: 5px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .sidebar-toggle {
        cursor: pointer;

        i {
            font-size: 20px;
            color: white;
        }
    }

    .mobile-back {
        display: block !important;
    }
}


/*=====================
3. Home section css
==========================*/

.cab-section {
    position: relative;
    background-image: url("../images/cab-bg.jpg");
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    z-index: 1;

    .cab-content {
        height: 100vh;
        padding-top: 75px;
        display: flex;
        align-items: center;

        > div {
            width: 100%;
        }

        .call-section {
            display: inline-block;

            .call {
                padding: 12px 20px;
                border-radius: 50px;
                background-color: rgba(white, 0.15);
                align-items: center;
                display: flex;
                position: relative;
                z-index: 1;
                margin-bottom: 35px;

                i {
                    margin-right: 50px;
                    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                    padding-top: 4px;
                    padding-left: 4px;
                }

                h2 {
                    font-size: calc(16px + (40 - 16) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                    margin-bottom: 0;
                    font-weight: 700;
                    display: inline-block;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 70px;
                    height: 70px;
                    border-radius: 100%;
                    background-color: $theme-color1;
                    z-index: -1;
                    left: 0;
                    box-shadow: 1px 11px 20px 0px rgba(239, 63, 62, 0.12);
                }
            }
        }

        h2 {
            font-size: calc(16px + (45 - 16) * ((100vw - 320px) / (1920 - 320)));
            text-transform: uppercase;
            color: white;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 5px;
        }

        h3 {
            font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: white;
            font-weight: 400;
            margin-bottom: 40px;
        }

        form {
            .form-control {
                border: none;
                padding: 15px 20px;
                border-radius: 0;
            }

            ::placeholder {
                color: rgba(black, 0.5);
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;
                font-weight: 700;
            }

            .form-group {
                position: relative;
                margin-bottom: 25px;

                img {
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-left: 1px solid #e5e5e5;
                    padding: 17px;
                    z-index: 1;
                    background-color: white;
                }
            }

            .gj-datepicker,
            .gj-timepicker {
                button {
                    width: 55px;
                    position: relative;
                    border: none;
                    background-color: white;
                    display: flex;
                    align-items: center;
                    border-left: 1px solid #e5e5e5 !important;
                    justify-content: center;
                    border-radius: 0;

                    &:hover {
                        box-shadow: none;
                    }

                    &:active {
                        background-color: white;
                        border-color: white;
                        color: #6c757d;
                    }

                    &:focus {
                        box-shadow: none !important;
                    }

                    i {
                        position: relative;
                        top: 0;
                        left: 0;
                        opacity: 0.2;
                    }
                }
            }
        }

        .radio-form {
            margin-bottom: 25px;

            label {
                margin-right: 20px;

                + label {
                    margin-left: 30px;
                }
            }

            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }

            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
                position: relative;
                padding-left: 30px;
                cursor: pointer;
                display: inline-block;
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: white;
                text-transform: capitalize;
                font-weight: 700;
            }

            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
                content: "";
                position: absolute;
                left: 0;
                top: 3px;
                width: 18px;
                height: 18px;
                border: 4px solid white;
                border-radius: 100%;
                transition: all 0.5s ease;
            }

            [type="radio"]:checked + label:before {
                background-color: white;
                border: 4px solid $theme-color1;
                transition: all 0.5s ease;
            }
        }

        .car-select {
            display: flex;

            ul {
                display: flex;
                width: 75%;

                li {
                    background-color: white;
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: rgba(black, 0.5);
                    padding: 10px 0;
                    border-radius: 4px;
                    text-transform: capitalize;
                    font-weight: 700;
                    transition: all 0.5s ease;
                    width: 100%;
                    margin-right: 25px;
                    text-align: center;
                    cursor: pointer;

                    &.active {
                        color: $theme-color1;
                        transition: all 0.5s ease;
                    }
                }
            }

            .btn {
                width: 25%;
                text-align: center;
                font-size: 14px;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                border-radius: 4px;
                font-weight: 900;
                justify-content: center;
                height: 100%;
                line-height: 26px;
            }
        }
    }

    &.flight-section {
        background-image: url("../images/bg-flight.png");

        .cloud {
            height: 100%;
            bottom: 0;
            width: 100%;
            position: absolute;
            z-index: -1;
        }

        .cab-content {
            padding-top: 86px;

            .radio-form {
                margin-bottom: 5px;
            }

            .top-cls {
                font-size: 14px;
                color: white;
                text-transform: uppercase;
                margin-bottom: 30px;
                background-color: rgba(white, 0.2);
                padding: 2px 16px;
                display: inline-block;
                border-radius: 30px;
                font-weight: 700;
            }

            h2 {
                margin-bottom: 10px;
            }

            h3 {
                font-size: calc(14px + (35 - 14) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 700;
                text-transform: uppercase;
            }

            .btn-rounded {
                padding: 14px 32px;
            }

            .form-group {
                &.row {
                    margin-left: -10px;
                    margin-right: -10px;

                    .col {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }

            .gj-datepicker,
            .gj-timepicker {
                ::placeholder {
                    font-size: 14px;
                }
            }
        }
    }
}

.selector-box {
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 3;
    border: 1px solid $box-shadow;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    top: auto;
    display: none;

    .title {
        padding: 10px 10px;
        font-size: 14px;
        color: rgba(black, 0.6);
        margin-bottom: 0;
        text-transform: capitalize;
    }

    ul {
        padding: 0 0 10px 0;
        max-height: 180px;
        overflow-y: scroll;

        li {
            display: block;
            position: relative;
            margin-bottom: 10px;
            padding: 0 10px;

            h5 {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: black;
                margin-bottom: 0;
                text-transform: capitalize;
            }

            h6 {
                font-size: 14px;
                color: rgba(black, 0.6);
                margin-bottom: 0;
                text-transform: capitalize;
            }

            span {
                position: absolute;
                border: 1px solid rgba(black, 0.2);
                right: 10px;
                font-weight: 700;
                top: 3px;
                padding: 3px 8px;
                text-transform: uppercase;
                color: black;
                border-radius: 3px;
            }
        }

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: $theme-color1;
        }
    }

    &.show {
        display: block;
    }
}

section {
    .black-layer {
        position: relative;
        z-index: -1;

        &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            top: 0;
            z-index: -1;
        }
    }
}

.home_section {
    position: relative;
    overflow: hidden;

    .home {
        background-position: center;
        background-size: cover;
        height: 100vh;
        overflow: hidden;

        &.black-layer {
            &:before {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.2);
            }

            h1 {
                color: white !important;
            }

            h4 {
                color: white;
            }
        }

        .banner-content {
            &.banner-center-text {
                text-align: center;
                margin: 0 auto;
                width: 100%;
            }
        }

        &.home-padding {
            height: auto;

            .home-content {
                height: 100%;
                padding: 140px 0 40px 0;
            }
        }

        &.home-60 {
            height: 60vh;

            .home-content {
                height: 60vh;
            }
        }

        &.home-70 {
            height: 70vh;

            .home-content {
                height: 70vh;
            }

            .a-canvas {
                height: 70vh !important;
            }
        }

        &.home-long {
            .home-content {
                align-items: flex-end;
                padding-bottom: calc(50px + (100 - 50) * ((100vw - 320px) / (1920 - 320)));

                &.mix-layout {
                    text-align: left;
                    justify-content: left;

                    &.search-bottom {
                        content: "";
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0.15);
                    }

                    h1 {
                        margin-bottom: 0px;
                    }

                    h3 {
                        font-size: calc(12px + (22 - 12) * ((100vw - 320px) / (1920 - 320)));
                        margin-bottom: calc(5px + (40 - 5) * ((100vw - 320px) / (1920 - 320)));
                        font-weight: 300;
                    }
                }
            }

            &.home-full {
                height: 100vh;

                .home-content {
                    height: 100vh;
                }
            }
        }

        &.search-bottom {
            .home-content {
                align-items: flex-end;

                &.mix-layout {
                    > div {
                        width: 100%;
                    }
                }
            }

            .flight-search {
                .flight-search-detail {
                    .form-group {
                        .form-control {
                            border: none;
                            border-right: 1px solid #dddddd;
                            padding: 20px 20px;
                        }
                    }

                    .search-btn {
                        align-items: center;
                        height: 100%;

                        .btn {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .input-group-append {
                        display: none;
                    }
                }
            }
        }

        .smoke-effect {
            position: relative;
            width: 100%;
            height: 100%;

            #canvas {
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                bottom: 390px;
            }
        }

        .home-content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            padding-top: 87px;
            width: 100%;
            text-align: center;

            .package-detail {
                margin-bottom: -3px;
                margin-top: 15px;

                li {
                    color: white;
                    padding-right: 10px;
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

                    i {
                        margin-right: 10px;
                    }

                    a {
                        color: white;
                    }
                }
            }

            > div {
                padding: 70px 140px;
                background-color: rgba(black, 0.5);
                box-shadow: 0px 0px 50px 20px rgba(0,0,0,0.6);
            }

            h1 {
                font-family: $font_5, $font_4;
                font-size: calc(26px + (160 - 26) * ((100vw - 320px) / (1920 - 320)));
                color: white;
                text-transform: capitalize;
                margin-bottom: 10px;
                margin-top: -16px;
            }

            h5 {
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: rgba(white, 0.70);
                text-transform: uppercase;
                letter-spacing: 0.3em;
                margin-bottom: 15px;
            }

            h2 {
                font-size: calc(16px + (50 - 16) * ((100vw - 320px) / (1920 - 320)));
                color: rgba(255, 255, 255, 0.8);
                text-transform: uppercase;
                letter-spacing: 0.3em;
                font-weight: 400;
                margin-bottom: 30px;
            }

            &.mix-layout {
                > div {
                    padding: 0;
                    box-shadow: none;
                    background-color: rgba(0, 0, 0, 0.50);
                    padding: 60px;

                    &.bg-transparent {
                        padding: 0;
                    }
                }

                h1 {
                    font-size: calc(18px + (60 - 18) * ((100vw - 320px) / (1920 - 320)));
                    font-family: $font_0, $font_0;
                    margin-bottom: calc(5px + (30 - 5) * ((100vw - 320px) / (1920 - 320)));
                    position: relative;

                    span {
                        font-family: $font_5;
                        font-size: calc(18px + (300 - 18) * ((100vw - 320px) / (1920 - 320)));
                        position: absolute;
                        color: rgba(white, 0.11);
                        text-transform: capitalize;
                        left: 0;
                        right: 0;
                        top: -100px;
                        font-weight: 700;
                    }
                }

                h3 {
                    font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                    font-weight: 700;
                    margin-bottom: calc(5px + (50 - 5) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: capitalize;
                }

                &.smaller-content {
                    .search-section {
                        .search-box {
                            .left-part {
                                .search-body {
                                    ::placeholder {
                                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                        line-height: 32px;
                                    }

                                    .form-control {
                                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                        line-height: 32px;
                                    }
                                }
                            }
                        }
                    }

                    .search-panel {
                        .title-top {
                            font-size: calc(14px + (32 - 14) * ((100vw - 320px) / (1920 - 320)));
                            letter-spacing: 0.06em;
                            font-weight: 600;
                            line-height: 1.2;
                        }

                        .search-section {
                            .search-box {
                                .left-part {
                                    .search-body {
                                        padding: 30px 30px;
                                    }
                                }
                            }

                            &.search-fixed {
                                position: fixed;
                                left: 0;
                                top: 0;
                                width: 100vw;
                                z-index: 2;
                                box-shadow: 0 0 8px #dddddd;
                                background-color: white;

                                .search-box {
                                    .left-part {
                                        .search-body {
                                            padding: 15px 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.food_content {
                > div {
                    background-color: transparent;
                    padding: 0;
                }

                h4 {
                    font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
                    text-align: left;
                    margin-left: 15%;
                    margin-bottom: 0;
                    color: white;
                    font-family: $font_6, $font_4;
                    letter-spacing: 0.05em;
                }

                h1 {
                    font-family: $font_7, $font_4;
                    font-size: calc(30px + (180 - 30) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                    letter-spacing: 0.05em;
                    text-transform: capitalize;
                    line-height: 1;
                    margin-bottom: 0;
                    position: relative;
                    display: inline-block;
                    margin-bottom: 40px;
                    margin-top: 0;
                }

                .book-table {
                    .table-form {
                        padding: 25px 50px;
                        margin-top: 0;

                        form {
                            .form-group {
                                margin-right: 0;

                                input {
                                    padding: 10px 20px 8px;
                                    background-color: #f2f2f2;
                                }

                                img {
                                    top: 10px;
                                }
                            }

                            ::placeholder {
                                font-weight: 600;
                                color: rgba(black, 0.4);
                            }
                        }
                    }
                }
            }

            &.mrg-cls {
                h1 {
                    margin-bottom: -12px;
                }
            }

            .flight-search {
                .flight-search-detail {
                    .form-group {
                        text-align: left;

                        label {
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .banner-content {
            position: absolute;
            top: 40%;

            &.banner-center-text {
                top: 50%;
                left: 0;
            }

            h4 {
                margin-bottom: 20px;
            }

            h1 {
                color: black;
                margin-bottom: 20px;
            }
        }
    }

    .offer-text {
        position: absolute;
        left: -148px;
        top: 48%;

        h6 {
            color: white;
            transform: rotate(-90deg);
            text-transform: uppercase;
            letter-spacing: 0.3em;
            font-weight: 700;
            position: relative;

            span {
                position: relative;
                display: inline-block;
                animation: ani 1s infinite alternate cubic-bezier(0.86, 0, 0.07, 1);

                &:nth-last-child(1n) {
                    animation-delay: -0.1666666667s;
                }

                &:nth-last-child(2n) {
                    animation-delay: -0.3333333333s;
                }

                &:nth-last-child(3n) {
                    animation-delay: -0.5s;
                }
            }

            &:after {
                content: "";
                position: absolute;
                width: 80px;
                height: 3px;
                background-color: white;
                top: 5px;
                left: -100px;
                background: linear-gradient(to right, rgba(231, 56, 39, 0) 0%, rgba(255, 255, 255, 1) 100%);
            }
        }
    }

    .slick-next {
        right: 0;
        opacity: 1;
        background-color: rgba(black, 0.65);
        padding: 35px;
        font-weight: 900;
        font-family: "Font Awesome 5 Free";

        &:before {
            color: transparent;
        }

        &:after {
            content: "\f054";
            font-size: 20px;
            position: absolute;
            color: white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .slick-prev {
        left: 0;
        z-index: 1;
        opacity: 1;
        background-color: rgba(black, 0.65);
        padding: 35px;
        font-weight: 900;
        font-family: "Font Awesome 5 Free";

        &:before {
            color: transparent;
        }

        &:after {
            content: "\f053";
            font-size: 20px;
            position: absolute;
            color: white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .view-icon {
        position: absolute;
        bottom: 20px;
        left: 20px;
        filter: invert(1);
    }
}

/*.error {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    color: #eee;
    padding: 20px;
    display: none;
}*/

.search-section {
    margin-top: -75px;

    .search-box {
        background-color: white;
        box-shadow: 1px 3px 20px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        position: relative;

        input {
            &:focus {
                box-shadow: none;
            }
        }

        .left-part {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;

            .search-body {
                display: inline-block;
                text-align: center;
                padding: 40px;
                position: relative;

                h6 {
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 700;
                }

                h3 {
                    font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
                    color: black;
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: -8px;
                }

                .form-control {
                    border: none;
                    padding: 0;
                    text-align: left;
                    font-size: calc(10px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: #051d3c;
                    text-transform: capitalize;
                    font-weight: 600;
                    margin-bottom: 0;
                }

                ::placeholder {
                    font-size: calc(10px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: #051d3c;
                    text-transform: capitalize;
                    font-weight: 600;
                    margin-bottom: 0;
                }

                .quantity-left-minus,
                .quantity-right-plus {
                    position: absolute;
                    background-color: transparent;
                    padding: 0;
                    border: none;
                    font-size: 10px;
                    right: 30px;
                    color: grey;

                    &:hover {
                        box-shadow: none;
                        border: none;
                    }
                }

                .quantity-left-minus {
                    bottom: -10px;
                }

                .quantity-right-plus {
                    top: -5px;
                }

                .input-group-append {
                    display: none;
                }

                &:after {
                    content: "";
                    width: 1px;
                    height: 40px;
                    background-color: #e8e8e8;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .right-part {
            margin-left: 45px;

            .search-icon {
                width: 96px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $theme-color1;
                height: 100%;
                position: relative;
                z-index: 1;

                img {
                    position: absolute;
                    left: 18px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: -37px;
                    width: 70px;
                    height: 100%;
                    background-color: $theme-color1;
                    border-radius: 100%;
                    z-index: -1;
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: -45px;
                    width: 70px;
                    height: 100%;
                    background-color: rgba($theme-color1, 0.3);
                    border-radius: 100%;
                    z-index: -1;
                }
            }
        }
    }
}

.classic-slider {
    margin-bottom: 0 !important;

    .slick-dots {
        top: 50%;
        bottom: unset;
        width: auto;
        display: inline-block;
        right: 50px;
        transform: translateY(-50%);

        li {
            display: block;
            width: auto;
            height: auto;
            margin: 20px 0;

            .dot {
                color: white;
                font-weight: 900;
                font-size: calc(18px + (35 - 18) * ((100vw - 320px) / (1920 - 320)));
                line-height: 1;
                opacity: 0.5;
            }

            &.slick-active {
                .dot {
                    font-size: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
                    opacity: 1;
                }
            }
        }
    }
}

.location-option {
    margin-bottom: 14px;

    .form-check {
        margin-right: calc(5px + (18 - 5) * ((100vw - 320px) / (1920 - 320)));
        padding-left: 0;
    }

    .form-check-label {
        color: white;
        text-transform: capitalize;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        display: inline-block;
        font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
        color: white;
        text-transform: capitalize;
        font-weight: 600;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: -1px;
        width: 18px;
        height: 18px;
        border: 4px solid white;
        border-radius: 100%;
        transition: all 0.5s ease;
    }

    [type="radio"]:checked + label:before {
        background-color: white;
        border: 4px solid $theme-color1;
        transition: all 0.5s ease;
    }

    &.dark-circle {
        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label {
            color: black;
        }

        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
            border: 4px solid #b5b5b5;
        }

        [type="radio"]:checked + label:before {
            border: 4px solid $theme-color1;
        }
    }
}

.home-section {
    .flight-left {
        display: flex;
        align-items: center;
        padding-top: 86px;
    }

    &.cab-section {
        .cab-content {
            h3 {
                margin-bottom: 20px;
            }
        }
    }
}

.home_effect {
    .effect_image {
        position: relative;
        background: url(../images/tour/background/1.jpg);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        z-index: 1;

        img {
            position: absolute;
            width: 100%;
            bottom: -150px;
            left: 0;
        }

        .effect_content {
            position: absolute;
            width: 100%;
            top: 14%;
            left: 0;
            z-index: -1;
            display: inline-block;
            text-align: center;

            h1 {
                font-size: 580%;
                color: white;
                font-weight: 900;
                display: inline-block;
            }
        }
    }

    canvas {
        display: block;
    }

    .snow {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
    }
}

.mix-pills {
    &.nav-pills {
        .nav-link.active,
        .show > .nav-link {
            color: #fff;
            background-color: $theme-color1;
        }

        .nav-link {
            color: white;
            text-transform: capitalize;
            font-size: calc(12px + (17 - 12) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
        }
    }
}

.form-icon {
    .form-group {
        position: relative;

        .form-control {
            padding: 20px 20px 20px 50px !important;
        }

        .icon {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            color: rgba(0, 0, 0, 0.22);
            font-size: 16px;
            z-index: 4;
        }
    }
}


/*=====================
4. Slick slider css
==========================*/

.no-arrow {
    .slick-next,
    .slick-prev {
        display: none !important;
    }
}

.slick-slider {
    line-height: 1;
}

.arrow-classic {
    .slick-next,
    .slick-prev {
        &:before {
            font-family: $font-themify;
            color: #dfdfdf;
            background-color: white;
            opacity: 1;
            padding: 8px;
            box-shadow: 5px 8.66px 61px 0px rgba(0, 0, 0, 0.24);
            border-radius: 12px;
            font-size: 18px;
            transition: all 0.5s ease;
        }

        &:hover {
            &:before {
                color: $theme-color1;
                transition: all 0.5s ease;
            }
        }
    }

    .slick-prev {
        left: -100px;

        &:before {
            content: "\e64a";
        }
    }

    .slick-next {
        right: -88px;

        &:before {
            content: "\e649";
        }
    }
}

.arrow-dark {
    .slick-prev {
        left: 1px;
        z-index: 1;
        transition: all 0.5s ease;
        background-color: transparent;
        height: 36px;

        &:before {
            content: "\f053";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }
    }

    .slick-next {
        right: 0;
        z-index: 1;
        transition: all 0.5s ease;
        background-color: transparent;
        height: 36px;

        &:before {
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }
    }

    .slider-nav {
        margin-top: 3px;

        .slick-slide {
            margin: 0 3px;
            cursor: pointer;
        }

        .slick-list {
            margin-left: -3px;
            margin-right: -3px;
        }
    }

    &:hover {
        .special-img {
            .bg-size {
                transform: scale(1);
            }
        }

        .slick-next,
        .slick-prev {
            background-color: #252525;
            transition: all 0.5s ease;
        }
    }
}

.variable-width-height {
    .slick-slide {
        img {
            height: calc(280px + (400 - 280) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}

.variable-width {
    margin-bottom: -4px;
}


/*=====================
5. Instagram css
==========================*/

.instgram-slider {
    .slick-slide {
        margin-bottom: -4px;
    }

    .instagram-box {
        position: relative;
        overflow: hidden;

        .overlay {
            opacity: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($theme-color1, 0.4);
            transform: scale(1);
            transition: all 0.5s ease;

            i {
                color: white;
            }
        }

        &:hover {
            .overlay {
                opacity: 1;
                transform: scale(4);
                transition: all 0.5s ease;
                cursor: pointer;
            }
        }
    }
}


/*=====================
6. Logo css
==========================*/

.logo-box {
    img {
        margin: 0 auto;
    }
}


/*=====================
7. Blog css
==========================*/

.blog-section {
    .slick-list {
        margin-left: -10px;
        margin-right: -10px;
    }

    .slick-slide {
        > div {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .blog-box {
        width: 100%;
        display: flex;
        background-color: white;
        box-shadow: 0px 0.66px 6px 0px #00000021;

        .img-part {
            position: relative;

            img {
                min-width: 300px;
            }

            .blog-date {
                padding: 10px 12px;
                background-color: $theme-color1;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 35px;
                left: 35px;
                box-shadow: 2.5px 4.33px 25px 0px rgba($theme-color1, 0.4);
                text-align: center;

                h5 {
                    color: white;
                    margin-bottom: 0;
                    font-weight: 700;
                }

                h6 {
                    font-size: 10px;
                    color: white;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    font-weight: 700;
                }
            }
        }

        .blog-content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 70px 55px;

            h5 {
                font-size: 14px;
                color: $theme-color1;
                text-transform: capitalize;
                font-weight: 600;
                margin-bottom: 20px;
            }

            p {
                font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                color: black;
                font-weight: 700;
                line-height: 1.5;
            }

            h6 {
                font-size: 14px;
                color: $grey-9;
                font-weight: 600;
                margin-bottom: 20px;
            }
        }
    }
}

.blog_section {
    overflow: hidden;

    .blog-wrap {
        border-radius: 25px;
        box-shadow: 2.5px 4.33px 35px 0px rgba(0, 0, 0, 0.04);
        transition: all 0.5s ease;
        overflow: hidden;
        margin-bottom: 30px;

        .blog-image {
            position: relative;
            overflow: hidden;

            .bg-size {
                transition: all 0.5s ease;
            }

            .blog-label {
                position: absolute;
                background-color: white;
                padding: 14px 15px;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                left: 30px;
                bottom: 30px;
                border-radius: 10px;

                h3 {
                    color: black;
                    font-weight: 700;
                    margin-bottom: 0;
                    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
                }

                h6 {
                    font-size: 14px;
                    color: $grey-12;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
            }
        }

        .blog-details {
            padding: 40px 35px;

            h6 {
                color: $grey-13;
                text-transform: capitalize;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 12px;

                i {
                    color: $theme-color2;
                    margin-right: 3px;

                    &.color-1 {
                        color: $theme-color1;
                    }
                }
            }

            h5 {
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                color: $grey-3;
                font-weight: 700;
                line-height: 1.5;
                margin-bottom: 2px;
                transition: all 0.5s ease;
            }

            p {
                font-size: 14px;
                color: $grey-13;
                line-height: 1.5;
                margin-bottom: -3px;
            }
        }

        &:hover {
            box-shadow: 2.5px 4.33px 35px 0px rgba(0, 0, 0, 0.1);
            transition: all 0.5s ease;

            .blog-image {
                .bg-size {
                    transform: scale(1.03);
                    transition: all 0.5s ease;
                }
            }

            .blog-details {
                h5 {
                    color: $theme-color1;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .slick-slide {
        > div {
            margin: 0 25px;
        }
    }

    .slick-list {
        margin-left: -25px;
        margin-right: -25px;
    }

    &.section-b-space {
        padding-bottom: 70px;
    }

    &.destination-section {
        .slick-slide {
            > div {
                margin: 0 30px;
            }
        }

        .slick-list {
            margin-left: -30px;
            margin-right: -30px;
        }

        .destination-details {
            padding: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            h5 {
                font-size: 14px;
                color: #959595;
                text-transform: uppercase;
                letter-spacing: 0.4em;
                font-weight: 600;
                margin-bottom: 5px;
            }

            h2 {
                color: black;
                font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 700;
                margin-bottom: 10px;
            }

            h6 {
                font-weight: 600;
                text-transform: capitalize;
                font-size: 14px;
                margin-bottom: 30px;
                color: rgba(black, 0.56);
                letter-spacing: 0.05em;
                line-height: 1.5;
            }

            .btn {
                text-transform: capitalize;
                font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    &.inner-blog {
        .blog-wrap {
            border-radius: 0;

            .blog-details {
                h6 {
                    i {
                        color: $theme-color1;
                    }
                }
            }

            .blog-image {
                .blog-label {
                    border-radius: 0;
                }
            }
        }
    }
}


/*=====================
8. App Section css
==========================*/

.app-section {
    overflow: hidden;
    position: relative;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.05);
        top: 0;
        z-index: -1;
    }

    .app-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .label {
            margin-bottom: 35px;

            span {
                font-size: 14px;
                color: white;
                background-color: $theme-color1;
                text-transform: uppercase;
                padding: 5px 18px;
                line-height: 1;
                border-radius: 3px;
                font-family: $font_2;
                font-weight: 600;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    right: -65px;
                    top: 0;
                    background: linear-gradient(to right, rgba($theme-color1, 0.25) 0%, rgba($theme-color1, 0.05) 100%);
                    width: 70px;
                    height: 26px;
                }
            }

            &.label-white {
                span {
                    background-color: white;
                    color: $theme-color1;

                    &:after {
                        background: linear-gradient(to right, rgba(white, 0.25) 0%, rgba(white, 0.05) 100%);
                        width: 40px;
                        right: -35px;
                    }
                }
            }
        }

        .title {
            color: white;
            font-size: calc(24px + (50 - 24) * ((100vw - 320px) / (1920 - 320)));
            text-transform: unset;
            line-height: 1.2;
            margin-bottom: 20px;
            margin-top: -7px;

            span {
                display: block;
            }
        }

        p {
            font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(255, 255, 255, 0.78);
            letter-spacing: 0.02em;
            line-height: 1.7;
            margin-bottom: 30px;
        }

        h3 {
            color: rgba(white, 0.85);
            font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 42px;
            font-weight: 700;
            text-transform: capitalize;
        }

        .app-buttons {
            a {
                text-transform: capitalize;
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                padding: 10px 16px;

                + a {
                    margin-left: 30px;
                }

                i {
                    margin-right: 5px;
                }
            }
        }
    }

    .app-image {
        > div {
            justify-content: flex-end;
            display: flex;
        }

        .image {
            position: relative;

            img {
                position: relative;
                z-index: 1;
            }

            &:last-child {
                margin-left: 45px;
            }
        }
    }

    .app-box {
        padding: 65px 80px;
        background-color: rgba(white, 0.04);

        h5 {
            color: white;
            text-transform: capitalize;
            font-style: italic;
            font-weight: 700;
            margin-bottom: 30px;
            margin-top: -4px;

            span {
                color: $theme-color1;
            }
        }

        h2 {
            color: white;
            letter-spacing: 0.01em;
            text-transform: unset;
            font-size: calc(24px + (50 - 24) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 30px;
        }

        p {
            color: rgba(white, 0.68);
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.5;
            margin-bottom: 40px;
        }

        .app-buttons {
            a {
                font-size: 14px;
                text-transform: capitalize;
                letter-spacing: 0.05em;
                padding: 10px 22px;

                i {
                    margin-right: 5px;
                }
            }

            .app-btn {
                background-color: rgba(white, 0.2);
                margin-left: 15px;
            }
        }
    }

    &.app-right-sec {
        .app-image {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        .app-content {
            background-color: $inner-bg;
            padding: 110px;

            .title,
            h3 {
                color: $grey-darker;
            }

            p {
                color: rgba(black, 0.65);
            }

            h3 {
                margin-bottom: 14px;
            }
        }
    }

    &.app-dark {
        .app-content {
            .title {
                color: black;
            }

            p,
            h3 {
                color: rgba(black, 0.8);
            }
        }
    }
}


/*=====================
9. Service css
==========================*/

.service-section {
    margin-left: -25px;
    margin-right: -25px;

    > div {
        padding-left: 25px;
        padding-right: 25px;
    }

    .service-box {
        padding: 70px 60px;
        background-color: white;
        border-radius: 20px;
        text-align: center;
        box-shadow: 10px 17.321px 30px 0px rgba(206, 206, 206, 0.4);
        transition: all 0.5s ease;

        .service-icon {
            width: 90px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            background-color: rgba($theme-color1, 0.05);
            border-radius: 100%;
            margin-bottom: 40px;
            transition: all 0.5s ease;

            svg {
                width: 45px;
                height: 45px;
                fill: $theme-color1;
            }
        }

        h3 {
            text-align: center;
            color: black;
            font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
            text-transform: capitalize;
            margin-bottom: 15px;
        }

        p {
            text-align: center;
            color: $grey-9;
            line-height: 1.7;
            margin-bottom: 8px;
        }

        .lower-section {
            display: flex;
            justify-content: center;

            h6 {
                margin-bottom: 0;
                color: $theme-color1;
                margin-left: 12px;
                text-transform: capitalize;
                font-weight: 700;
            }
        }

        .btn {
            margin-top: 30px;
        }

        &:hover {
            transform: translateY(-3px);
            transition: all 0.5s ease;

            .service-icon {
                transform: scale(1.06);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
                transition: all 0.5s ease;
            }
        }
    }

    &.color-svg {
        .service-box {
            .service-icon {
                svg {
                    fill: unset;
                }
            }
        }
    }
}

.service_section {
    > div {
        &:nth-last-child(-n+3) {
            margin-top: 60px;
        }
    }

    .service-wrap {
        text-align: center;
        padding: 50px 30px;
        transition: all 0.5s ease;
        border-radius: 15px;

        .service-icon {
            text-align: center;

            img {
                height: 80px;
                padding: 18px 20px;
                border-radius: 20px;
                background-color: $theme-color1;
                box-shadow: 7.5px 12.99px 40px 0px rgba($theme-color1, 0.25);
                margin-bottom: 30px;
            }
        }

        h5 {
            text-transform: capitalize;
            font-weight: 700;
            margin-bottom: 8px;
            letter-spacing: 0.001em;
        }

        p {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: $grey-light-2;
            margin-bottom: -6px;
            line-height: 1.5;
            letter-spacing: 0.01em;
        }

        &:hover {
            box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
            transition: all 0.5s ease;

            .service-icon {
                img {
                    box-shadow: none;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.service-part {
    .service-wrapper {
        box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.05);
        padding: 40px;
        border-radius: 20px;
        transition: all 0.5s ease;

        h3 {
            font-weight: 700;
            color: $grey-3;
            margin-bottom: 10px;
            letter-spacing: 0.01em;
            margin-top: -4px;
            position: relative;

            i {
                position: absolute;
                font-size: 16px;
                right: 0;
                top: 0;
                color: $red-heart;
                background-color: rgba($red-heart, 0.1);
                padding: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;

                .effect {
                    border-radius: 50%;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    right: 0;
                    transition: height 5s ease, width 5s ease;
                    z-index: 0;
                    opacity: 0;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        border-radius: 50%;
                        border: 1px solid $red-heart;
                        animation: effect 2s linear 1s infinite;
                    }
                }
            }
        }

        h6 {
            color: #292929;
            font-weight: 600;
            margin-bottom: 20px;
        }

        p {
            color: #949494;
            margin-bottom: 30px;
            letter-spacing: 1.1px;
            line-height: 1.5;
        }

        .service-btn {
            text-align: right;

            .btn {
                letter-spacing: 0.05em;
            }
        }

        &:hover {
            transform: translateY(-5px);
            transition: all 0.5s ease;
            box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.15);

            h3 {
                .effect {
                    opacity: 0.5;
                    transition: all 0.5s ease;
                }
            }

            .service-btn {
                .btn {
                    box-shadow: 2.5px 4.33px 25px 0px rgba($theme-color1, 0.5);
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.rating {
    display: flex;
    align-items: center;

    i {
        font-size: 12px;
        margin: 0 2px;
        color: $rate-yellow;
    }
}

.highlight-section {
    margin-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: -20px;

    .highlight-box {
        margin-bottom: 20px;
        text-align: center;
        border: 1px solid #f7f7f7;
        padding: 30px;
        border-radius: 5px;
        box-shadow: 2.5px 4.33px 35px 0px rgba(0,0,0,0.04);

        svg,
        img {
            width: 50px;
            height: auto;
            fill: rgba(black, 0.67);
        }

        .content-sec {
            h5 {
                color: $theme-color1;
                text-transform: capitalize;
                font-weight: 700;
                margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            }

            p {
                width: 80%;
                margin: 0 auto;
                color: rgba(black, 0.9);
                margin-bottom: -4px;
                text-transform: capitalize;
                line-height: 1.8;
            }
        }
    }
}


/*=====================
10. Testimonial css
==========================*/

.testimonial-section {
    .testimonial {
        display: flex;
        padding-bottom: 100px;

        .left-part {
            display: inline-block;
            position: relative;

            img {
                width: 140px;
                height: 140px;
                border-radius: 100%;
                background-color: white;
                box-shadow: 10px 17.321px 40px 0px rgba(0, 0, 0, 0.1);
            }

            .design {
                background-color: $theme-color1;
                padding: 20px;
                border-radius: 100%;
                position: absolute;
                top: 0;
                right: -45px;
                z-index: -1;
                width: 70px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 28px;
                    color: white;
                }

                .light {
                    position: absolute;
                    color: rgba(white, 0.2);
                    top: -8px;
                    right: -18px;
                    font-size: 42px;
                }
            }
        }

        .right-part {
            max-width: 70%;
            margin-left: 13%;

            p {
                margin-top: 14px;
                font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: $grey-6;
                line-height: 1.6;
                margin-bottom: 25px;
            }

            .detail {
                display: flex;

                h6 {
                    text-transform: uppercase;
                    margin-left: 18px;
                    margin-bottom: 0;
                    font-weight: 900;
                }
            }
        }

        .quote-icon {
            position: absolute;
            right: 90px;
            transform: rotate(-12deg);

            i {
                font-size: 102px;
                color: rgba($theme-color1, 0.05);
            }
        }
    }

    .slick-prev,
    .slick-next {
        background-color: rgba($theme-color1, 0.12);
        border-radius: 100%;
        display: flex;
        z-index: 1;
        top: 30%;

        &:before {
            font-family: unset;
            font-size: 26px;
            color: $theme-color1;
            position: absolute;
            top: -4px;
        }
    }

    .slick-prev {
        left: 0;

        &:before {
            left: 5px;
        }
    }

    .slick-next {
        right: 0;

        &:before {
            right: 5px;
        }
    }
}

.testimonial_section {
    overflow: hidden;

    .testimonial {
        .top-part {
            display: flex;
            align-items: center;
            justify-content: center;

            .img-part {
                display: inline-block;
                position: relative;
                margin-bottom: 60px;
                margin-top: 70px;

                img {
                    width: 130px;
                    height: 130px;
                    border-radius: 100%;
                    position: relative;
                }

                .heart-icon {
                    color: $red-heart;
                    font-size: 16px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: white;
                    padding: 10px;
                    box-shadow: 2.5px 4.33px 18px 0px rgba(0, 0, 0, 0.06);
                    border-radius: 100%;
                    bottom: -13%;
                }
            }
        }

        .bottom-part {
            text-align: center;
            position: relative;

            p {
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: $grey-7;
                font-weight: 500;
                line-height: 1.5;
                margin-bottom: 40px;
                max-width: 78%;
                margin-left: auto;
                margin-right: auto;
            }

            h3 {
                text-transform: capitalize;
                font-weight: 900;
                letter-spacing: 0.01em;
                margin-bottom: 10px;
            }

            .rating {
                justify-content: center;
            }

            .quote-icon {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -6px;
                z-index: -1;

                i {
                    font-size: 72px;
                    color: rgba($theme-color1, 0.05);
                }
            }
        }
    }
}


/*=====================
11. Subscribe css
==========================*/

.subscribe-section {
    background-color: rgba(black, 0.32);
    background-blend-mode: overlay;

    .input-group {
        input {
            color: $grey-7;
            font-weight: 700;
        }

        .form-control {
            border-radius: 0;
            border: none;
            padding: 15px 24px;

            &:focus {
                box-shadow: none;
            }
        }

        .btn {
            padding: 18px 40px;
            box-shadow: none;
        }

        ::placeholder {
            color: $grey-8;
            font-weight: 600;
            opacity: 1;
        }
    }
}

.subscribe-footer {
    padding: 40px 0;

    .service-left {
        float: left;

        .service-box {
            display: flex;
            padding: 0 50px;
            border-left: 1px solid white;

            .icon {
                svg {
                    fill: white;
                    width: 40px;
                    height: 40px;
                }
            }

            .detail {
                margin-left: 12px;
                display: flex;
                align-items: center;

                h5 {
                    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: uppercase;
                    color: white;
                    font-weight: 700;
                    margin-bottom: 0;
                }

                p {
                    font-size: 12px;
                    color: rgba(white, 0.6);
                    margin-bottom: 0;
                    text-transform: capitalize;
                }
            }
        }

        ul {
            li {
                &:first-child {
                    .service-box {
                        padding-left: 0;
                        border-left: none;
                    }
                }
            }
        }
    }

    .service-right {
        float: right;

        .input-group {
            input {
                color: rgba(white, 0.5);
                padding: 12px 25px;
                background-color: rgba(white, 0.16);
                border: none;
                border-radius: 50px 0 0 50px;

                &:focus {
                    box-shadow: none;
                }
            }

            ::placeholder {
                color: rgba(white, 0.4);
                opacity: 1;
            }
        }

        .btn-subscribe {
            background-color: white;
            color: $theme-color1;
            text-transform: capitalize;
            border-radius: 0 50px 50px 0;
            padding: 8px 20px;

            &:hover {
                box-shadow: none;
                border: 1px solid transparent;
            }
        }
    }

    &.zig-zag-effect {
        &:after {
            filter: invert(0.9);
        }
    }
}

.subscribe_section {
    .subscribe-detail {
        text-align: right;
        padding-right: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &:after {
            content: "";
            position: absolute;
            width: 3px;
            height: 30px;
            background-color: $theme-color1;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        h2 {
            position: relative;
            font-weight: 900;
            display: inline-block;
            margin-bottom: 15px;
            font-size: calc(16px + (36 - 16) * ((100vw - 320px) / (1920 - 320)));
            color: $theme-color3;
        }

        span {
            position: absolute;
            font-size: 14px;
            color: white;
            background-color: $theme-color1;
            left: -40%;
            border-radius: 50px;
            padding: 5px 14px 3px;
            line-height: 1;
            font-weight: 600;
            text-transform: uppercase;
            top: 4px;
        }

        p {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 200;
            line-height: 1.5;
            margin-bottom: 0;
            color: $theme-color3;
        }
    }

    .input-section {
        padding-left: 60px;
        position: relative;

        input {
            padding: 40px 175px 40px 50px;
            border: none;
            box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.05);
            border-radius: 25px;
            background: rgb(52 165 241 / 10%);
        }

        .form-control {
            &:focus {
                box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.1);
            }
        }

        ::placeholder {
            color: $grey-light;
            opacity: 1;
            font-weight: 600;
        }

        .btn {
            position: absolute;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);
            padding: 8px 20px;
        }
    }
}


/*=====================
12. About css
==========================*/

.about-section {
    .image-section {
        display: flex;

        .img-box {
            position: relative;

            + .img-box {
                margin-left: 60px;
            }

            img {
                box-shadow: 5px 8.66px 20px 0px rgba(0, 0, 0, 0.15);
            }

            .no-class {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $theme-color1;
                padding: 5px;
                left: -15px;
                box-shadow: 2.5px 4.33px 25px 0px rgba($theme-color1, 0.4);

                h3 {
                    font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                    margin-bottom: 0;
                    font-weight: 700;
                }
            }
        }
    }

    .about-text {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 60px;

        h5 {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-style: italic;
            color: $grey-10;
            text-transform: capitalize;
            font-weight: 700;
            margin-bottom: 15px;
            margin-top: -2px;

            span {
                color: $theme-color1;
            }
        }

        h3 {
            font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
            text-transform: capitalize;
            font-weight: 700;
            letter-spacing: 0.001em;
            margin-bottom: 35px;
        }

        h2 {
            font-size: calc(18px + (45 - 18) * ((100vw - 320px) / (1920 - 320)));
            color: $theme-color1;
            font-weight: 700;
            margin-bottom: 35px;
        }

        p {
            font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.5;
            font-weight: 600;
            margin-bottom: 25px;
        }
    }

    &.three-image {
        .image-section {
            .img-box {
                + .img-box {
                    margin-left: 45px;
                }
            }
        }

        .about-text {
            padding-left: 20px;

            h5 {
                font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: 30px;
            }

            h2 {
                color: black;
                font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: 10px;

                span {
                    font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: lowercase;
                    margin-bottom: 0;
                    display: block;
                    margin-top: 14px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.6);
                }
            }

            p {
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    &.about_page {
        .image-section {
            .img-box {
                &:nth-child(odd) {
                    margin-bottom: 40px;

                    .title-box {
                        bottom: -40px;
                    }
                }

                &:nth-child(even) {
                    margin-top: 40px;

                    .title-box {
                        top: -40px;
                    }
                }

                .title-box {
                    padding: 20px;
                    background-color: white;
                    display: inline-block;
                    text-align: center;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    box-shadow: -2px 0px 20px 0px rgba(0, 0, 0, 0.08);
                    min-width: 162px;

                    h3 {
                        font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
                        text-transform: capitalize;
                        color: black;
                        margin-top: -4px;
                        font-weight: 700;
                        margin-bottom: 7px;
                    }

                    h6 {
                        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                        text-transform: uppercase;
                        margin-bottom: -4px;
                        word-break: break-word;
                        color: gray;
                    }
                }
            }
        }

        .about-text {
            padding-left: 50px;
            align-items: flex-start;

            .title-3 {
                text-align: left;
                margin-bottom: 35px;

                .title-label {
                    text-transform: uppercase;
                    box-shadow: 5px 8.66px 20px 0px rgba(239, 63, 62, 0.22);
                }
            }

            h2 {
                font-weight: 700;

                span {
                    font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: capitalize;
                    margin-top: 20px;
                }
            }

            .buttons-about {
                margin-top: 45px;

                .btn {
                    + .btn {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

.about_section {
    .about_img {
        position: relative;
        margin: 20px 0;

        img {
            border-radius: 25px;
            transition: all 0.5s ease;
        }

        .side-effect {
            &:after {
                content: "";
                position: absolute;
                top: -12px;
                height: calc(100% - 18px);
                background: url(../images/restaurant/about.jpg);
                border-radius: 25px;
                width: calc(100% - 65px);
                left: 76px;
                z-index: -1;
                transition: all 0.5s ease;
                background-blend-mode: overlay;
                opacity: 0.3;
            }

            &:before {
                content: "";
                position: absolute;
                top: -20px;
                height: calc(100% - 44px);
                background: url(../images/restaurant/about.jpg);
                border-radius: 25px;
                width: calc(100% - 97px);
                left: 120px;
                z-index: -1;
                -webkit-transition: all 0.5s ease;
                transition: all 0.5s ease;
                background-blend-mode: overlay;
                opacity: 0.1;
            }

            span {
                &:after {
                    content: "";
                    position: absolute;
                    bottom: -12px;
                    height: calc(100% - 18px);
                    background: url(../images/restaurant/about.jpg);
                    border-radius: 25px;
                    width: calc(100% - 65px);
                    right: 76px;
                    z-index: -1;
                    transition: all 0.5s ease;
                    background-blend-mode: overlay;
                    opacity: 0.3;
                }

                &:before {
                    content: "";
                    position: absolute;
                    bottom: -20px;
                    height: calc(100% - 44px);
                    background: url(../images/restaurant/about.jpg);
                    border-radius: 25px;
                    width: calc(100% - 97px);
                    right: 120px;
                    z-index: -1;
                    transition: all 0.5s ease;
                    background-blend-mode: overlay;
                    opacity: 0.1;
                }
            }
        }

        &:hover {
            img {
                transform: scale(1.02);
                transition: all 0.5s ease;
            }

            .side-effect {
                &:before,
                &:after {
                    top: 0;
                    left: 20px;
                    transition: all 0.5s ease;
                }

                span {
                    &:after,
                    &:before {
                        bottom: 0;
                        right: 20px;
                        transition: all 0.5s ease;
                    }
                }
            }
        }
    }

    .about_content {
        margin-left: 85px;
        display: flex;
        align-items: center;
        height: 100%;

        h5 {
            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            color: $grey-10;
            text-transform: capitalize;
            font-weight: 700;
            font-style: italic;
            margin-bottom: 25px;

            span {
                color: $theme-color1;
            }
        }

        h2 {
            font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1920 - 320)));
            color: black;
            font-weight: 700;
            text-transform: capitalize;
            margin-bottom: 30px;
        }

        p {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: $grey-light2;
            line-height: 1.4;
            font-weight: 600;
            margin-bottom: 38px;
        }

        .about_bottom {
            display: flex;
            align-items: center;

            h6 {
                margin-left: 25px;
                background-color: #f7f7f7;
                color: #acacac;
                font-size: 14px;
                text-transform: capitalize;
                margin-bottom: 0;
                padding: 8px 12px;
                border-radius: 20px;
                font-weight: 600;
            }
        }
    }
}


/*=====================
13. Ticket/video section css
==========================*/

.ticket-section {
    .ticket-box {
        position: relative;

        .image-box {
            position: relative;
            overflow: hidden;
            box-shadow: 5px 8.66px 22px 0px rgba(black, 0.3);

            img {
                transition: all 0.5s ease;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(black, 0.2);
                opacity: 0;
                top: 0;
                z-index: 0;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }

                img {
                    transform: scale(1.04);
                    transition: all 0.5s ease;
                }
            }
        }

        .content {
            padding: 50px 150px 50px 50px;
            background-color: white;
            position: absolute;
            left: -56%;
            top: 50%;
            transform: translateY(-50%);
            box-shadow: 11px 4px 40px 0px rgba(0, 0, 0, 0.11);

            .detail {
                max-width: 395px;

                h4 {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 600;
                    color: rgba(black, 0.6);
                    margin-bottom: 15px;

                    span {
                        color: $theme-color1;
                        font-weight: 900;
                        font-size: calc(16px + (27 - 16) * ((100vw - 320px) / (1920 - 320)));
                    }
                }

                h3 {
                    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                    color: black;
                    font-weight: 900;
                    text-transform: capitalize;
                    margin-bottom: 18px;
                }

                p {
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    margin-bottom: 25px;
                    line-height: 1.5;
                    color: $grey-light2;
                    font-weight: 600;
                }
            }

            .barcode-design {
                position: absolute;
                right: 50px;
                top: 50%;
                transform: translateY(-50%);
            }

            &:after {
                content: "";
                position: absolute;
                background-image: url(../images/zig-zag.png);
                right: -10px;
                height: 100%;
                width: 10px;
                top: 0;
            }

            &:before {
                content: "";
                position: absolute;
                background-image: url(../images/zig-zag.png);
                left: -10px;
                height: 100%;
                width: 10px;
                top: 0;
                transform: rotate(180deg);
            }
        }

        .ticket-title {
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            right: -11%;
            display: flex;
            z-index: 1;

            h6 {
                background-color: white;
                padding: 10px;
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: 0;
                text-transform: capitalize;
                font-weight: 700;
            }

            span {
                background-color: red;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                color: white;
            }
        }
    }

    .slick-prev,
    .slick-next {
        font-family: $font-themify;
        right: 40px;
        left: unset;
        z-index: 1;
        transition: all 0.5s ease;

        &:before {
            color: $theme-color1;
            background-color: white;
            opacity: 1;
            padding: 10px;
        }

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.5) 5px 8.66px 40px 0px;
            transition: all 0.5s ease;
        }
    }

    .slick-next {
        top: calc(50% - 30px);

        &:before {
            font-family: $font-themify;
            content: "\e649";
        }
    }

    .slick-prev {
        top: calc(50% + 30px);

        &:before {
            font-family: $font-themify;
            content: "\e64a";
        }
    }

    &.white-section {
        .ticket-box {
            .ticket-title {
                right: -9%;
            }

            .content {
                .detail {
                    h4 {
                        margin-bottom: 20px;
                    }

                    h2 {
                        font-weight: 700;
                        margin-bottom: 10px;
                    }

                    h6 {
                        font-size: 14px;
                        color: $grey-10;
                        text-transform: capitalize;
                        font-weight: 700;
                        margin-bottom: 10px;

                        span {
                            color: $theme-color1;
                        }
                    }
                }
            }
        }

        .slick-prev,
        .slick-next {
            box-shadow: rgba(0, 0, 0, 0.5) 5px 8.66px 40px 0px;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.8) 5px 8.66px 40px 0px;
                transition: all 0.5s ease;
            }
        }
    }
}

.video-section {
    padding: 170px 0;

    &.parallax-img {
        background-color: rgba(0, 0, 0, 0.35);
        background-blend-mode: overlay;
    }

    .basic-section {
        h2 {
            font-family: $font_5;
            font-size: calc(25px + (65 - 25) * ((100vw - 320px) / (1920 - 320)));
            color: white;
            text-align: center;
            font-weight: 700;
            line-height: 0.8;
            margin-bottom: 35px;
        }

        h4 {
            text-align: center;
            color: rgba(255, 255, 255, 0.70);
            font-weight: 700;
            letter-spacing: 0.4em;
            text-transform: uppercase;
            margin-bottom: 40px;
        }

        .video-icon {
            position: relative;
            cursor: pointer;
            top: 0;
            left: 0;
            transform: none;
            margin: 0 auto;

            .animation-circle-inverse {
                background: rgba($youtube-red, 0.51);

                i {
                    background: rgba($youtube-red, 0.5);
                    border-radius: 15px;
                    opacity: 0.8;
                    z-index: -1;

                    &:nth-child(2) {
                        animation: icon 3s linear infinite;
                    }
                }
            }

            @keyframes icon {
                0% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1.4);
                }
            }
        }
    }
}

.video_section {
    padding: 138px 0;
    position: relative;
    overflow: hidden;

    .video-image {
        position: relative;
        z-index: 1;

        img {
            border-radius: 30px;
            transition: all 0.5s ease;
        }

        .video-icon {
            box-shadow: -1px 0px 25px 7px rgba(black, 0.5);
            transition: all 0.5s ease;

            &:hover {
                transform: translate(-50%, -50%) scale(1.06);
                transition: all 0.5s ease;
            }
        }

        .side-effect {
            &:after {
                content: "";
                position: absolute;
                top: 15px;
                height: calc(100% - 30px);
                background-color: rgba(white, 0.12);
                border-radius: 30px;
                width: calc(100% + 30px);
                left: -15px;
                z-index: -1;
                transition: all 0.5s ease;
            }

            &:before {
                content: "";
                position: absolute;
                top: 35px;
                height: calc(100% - 70px);
                background-color: rgba(white, 0.05);
                border-radius: 30px;
                width: calc(100% + 60px);
                left: -30px;
                z-index: -1;
                transition: all 0.5s ease;
            }
        }

        &:hover {
            img {
                transform: translateY(-25px);
                transition: all 0.5s ease;
            }

            .side-effect {
                &:after {
                    left: 10px;
                    width: calc(100% + 10px);
                    top: 0;
                    height: calc(100% - 10px);
                    transition: all 0.5s ease;
                }

                &:before {
                    width: 100%;
                    top: 35px;
                    left: 38px;
                    height: calc(100% - 30px);
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .video-content {
        display: flex;
        align-items: center;
        height: 100%;

        h5 {
            color: white;
            font-weight: 700;
            text-transform: unset;
            margin-bottom: 30px;
            margin-top: -3px;
            font-style: italic;

            span {
                color: $theme-color1;
            }
        }

        h2 {
            font-size: calc(22px + (60 - 22) * ((100vw - 320px) / (1920 - 320)));
            text-transform: capitalize;
            font-weight: 700;
            color: white;
            margin-bottom: 26px;

            span {
                font-size: calc(22px + (40 - 22) * ((100vw - 320px) / (1920 - 320)));
                padding-left: 20px;
                margin-left: 5px;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 3px;
                    height: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: white;
                }
            }
        }

        p {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(white, 0.7);
            line-height: 1.6;
            margin-bottom: 24px;
            padding-right: 10px;
        }

        .bottom-section {
            display: flex;
            align-items: center;
            margin-top: 35px;

            .timer {
                margin-bottom: 35px;
            }

            .info-btn {
                margin-left: 25px;

                h6 {
                    font-size: 14px;
                    color: white;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    background-color: rgba(white, 0.05);
                    border-radius: 50px;
                    padding: 10px 16px;
                }

                &.red-info {
                    margin-left: 10px;

                    h6 {
                        color: $theme-color1;
                    }
                }
            }
        }
    }

    .offer-text {
        position: absolute;
        right: -90px;
        top: 41%;

        h6 {
            color: white;
            transform: rotate(90deg);
            text-transform: uppercase;
            letter-spacing: 0.3em;
            font-weight: 700;
            position: relative;

            span {
                position: relative;
                display: inline-block;
                animation: ani 1s infinite alternate cubic-bezier(0.86, 0, 0.07, 1);

                &:nth-last-child(1n) {
                    animation-delay: -0.1666666667s;
                }

                &:nth-last-child(2n) {
                    animation-delay: -0.3333333333s;
                }

                &:nth-last-child(3n) {
                    animation-delay: -0.5s;
                }
            }

            &:after {
                content: "";
                position: absolute;
                width: 80px;
                height: 3px;
                background-color: white;
                top: 5px;
                right: -100px;
                background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(231, 56, 39, 0) 100%);
            }
        }
    }
}

.timer {
    li {
        font-size: 14px;
        list-style-type: none;
        padding: 10px 24px;
        text-transform: capitalize;
        color: white;
        background: rgba(white, 0.15);
        text-align: center;
        font-weight: 700;
        border-radius: 15px;
        margin-right: 25px;

        &:last-child {
            margin-right: 0;
        }
    }

    li span {
        display: block;
        font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1920 - 320)));
    }
}

.video-icon {
    width: 80px;
    height: 55px;
    background-color: $youtube-red;
    border-radius: 15px;
    box-shadow: 2.5px 4.33px 25px 0px rgba($youtube-red, 0.6);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;

    span {
        width: 0;
        position: absolute;
        height: 0;
        border-top: 12px solid transparent;
        border-left: 24px solid white;
        border-bottom: 12px solid transparent;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.video-slider {
    height: 227px;
    width: auto;
}


/*=====================
14. Gallery section css
==========================*/

.gallery-box {
    position: relative;
    overflow: hidden;

    .gallery-img {
        position: relative;
        transition: all 0.5s ease;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, rgba(black, 0.63) 0%, rgba(231, 56, 39, 0) 100%);
            background-blend-mode: overlay;
            box-shadow: inset 0px -200px 120px -53px rgba(0,0,0,0.6);
        }
    }

    .gallery-content {
        position: absolute;
        text-align: center;
        bottom: 30px;
        transition: all 0.5s ease;
        width: 100%;

        h5 {
            color: white;
            font-weight: 700;
            margin-bottom: 15px;
            text-transform: capitalize;
        }

        .btn {
            padding: 8px 18px;
            line-height: 1;
        }
    }

    &:hover {
        .gallery-img {
            transform: scale(1.04);
            transition: all 0.5s ease;
        }

        .gallery-content {
            bottom: 40px;
            transition: all 0.5s ease;
        }
    }
}

.gallery_box {
    position: relative;
    overflow: hidden;

    .gallery-img {
        position: relative;
        transition: all 0.5s ease;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, rgba(black, 0.63) 0%, rgba(231, 56, 39, 0) 100%);
            background-blend-mode: overlay;
        }
    }

    .gallery-content {
        position: absolute;
        bottom: 40px;
        left: 40px;
        transition: all 0.5s ease;
        z-index: 1;

        h3 {
            color: white;
            font-weight: 700;
            margin-bottom: 10px;
            text-transform: capitalize;
        }
    }

    .view-cls {
        width: 40px;
        height: 40px;
        background-color: rgba(white, 0.15);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 40px;
        bottom: 40px;
        opacity: 0;
        transform: scale(0);
        transition: all 0.5s ease;

        i {
            color: white;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 58%, rgba(0, 0, 0, 0.75) 100%);
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    &:hover {
        .gallery-img {
            transform: scale(1.04);
            transition: all 0.5s ease;
        }

        .view-cls {
            transform: scale(1);
            opacity: 1;
            transition: all 0.5s ease;
        }
    }
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-title {
    display: none;
}


/*=====================
15. Category section css
==========================*/

.special-section {
    .special-box {
        box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.05);
        transition: all 0.5s ease;
        margin-bottom: 30px;
        box-shadow: 0px 1px 9px 0px rgba(228,228,228,0.7);

        &.p-0 {
            .special-img {
                .top-icon {
                    top: 10px;
                    right: 10px;
                }
            }
        }

        .special-img {
            position: relative;
            overflow: hidden;

            .bg-size {
                transition: all 0.5s ease;
            }

            .label {
                position: absolute;
                left: 30px;
                top: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;

                h3 {
                    padding: 7px 12px;
                    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                    margin-bottom: 0;
                    background-color: white;
                    color: $theme-color1;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    del {
                        font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
                        color: black;
                        margin-right: 3px;
                    }
                }

                i {
                    padding: 12px 14px;
                    background-color: $theme-color1;
                    color: white;
                    box-shadow: 2.5px 4.33px 25px 0px rgba(darken($theme-color1, 30%), 0.4);
                }
            }

            .content-inner {
                position: absolute;
                width: 60%;
                bottom: -70px;
                background-color: white;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                padding: 15px;
                transition: all 0.5s ease;

                h6 {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 500;
                    color: black;
                    text-transform: uppercase;
                    margin-bottom: -4px;
                }

                h5 {
                    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
                    color: black;
                    font-weight: 700;
                    line-height: 1.5;
                    margin-bottom: 0;
                    margin-top: -7px;
                    text-transform: capitalize;
                }
            }

            .content_inner {
                position: absolute;
                bottom: 0;
                width: 100%;
                text-align: center;
                padding: 30px;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(231, 56, 39, 0) 100%);

                h6 {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 500;
                    color: white;
                    text-transform: uppercase;
                    margin-bottom: -4px;
                }

                h5 {
                    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                    font-weight: 700;
                    line-height: 1.5;
                    margin-bottom: 0;
                    margin-top: -7px;
                    text-transform: capitalize;
                }
            }

            .top-icon {
                position: absolute;
                top: 10px;
                right: 10px;

                i {
                    color: white;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    padding: 8px;
                    background-color: rgba(0, 0, 0, 0.60);
                    border-radius: 100%;
                }
            }
        }

        .special-content {
            padding: 40px;

            h5 {
                color: black;
                font-weight: 700;
                line-height: 1.5;
                margin-bottom: 15px;
                margin-top: -7px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            p {
                font-size: 14px;
                color: $grey-9;
                font-weight: 600;
                line-height: 1.5;
                margin-bottom: 15px;
            }

            .bottom-part {
                display: flex;

                .package-cls {
                    padding-left: 20px;
                    color: $theme-color1;
                    font-weight: 700;
                }
            }
        }

        &.cab-box {
            padding: 30px;
        }

        &:hover {
            box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.1);
            transition: all 0.5s ease;

            .special-img {
                .bg-size {
                    transform: scale(1.03);
                    transition: all 0.5s ease;
                }

                .content-inner {
                    bottom: 15px;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .slick-slide {
        > div {
            margin: 0 25px;
        }
    }

    .slick-list {
        margin-left: -25px;
        margin-right: -25px;
    }

    &.section-b-space {
        padding-bottom: 70px;
    }

    &.grid-box {
        margin-top: 30px;

        .special-box {
            position: relative;
            background-color: white;

            .special-content {
                padding: 20px 25px 25px;
                position: relative;

                h5 {
                    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: capitalize;
                    margin-bottom: 0px;
                    align-items: center;
                    margin-top: -4px;
                    display: flex;
                    overflow: hidden;
                    max-width: 280px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: block;

                    span {
                        color: $grey-13;
                        font-size: 14px;
                        margin-left: 8px;
                        font-weight: 500;
                        vertical-align: 3px;

                        i {
                            color: #a7a7a7;
                            margin-right: 3px;
                            font-size: 10px;
                        }
                    }
                }

                p {
                    margin-bottom: 10px;
                    color: $grey-13;
                    font-weight: 500;
                    letter-spacing: 0.05em;
                }

                .bottom-section {
                    .rating {
                        span {
                            color: rgba(black, 0.65);
                            font-size: 12px;
                            font-weight: 600;
                            margin-left: 18px;
                        }
                    }

                    .price {
                        display: flex;
                        align-items: center;
                        margin-top: 13px;
                        margin-bottom: -6px;

                        del {
                            font-size: 14px;
                            color: black;
                            font-weight: 700;
                            margin-right: 10px;
                        }

                        span {
                            color: $grey-3;
                            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                            font-weight: 700;
                        }

                        .package-cls {
                            padding-left: 20px;
                            color: $theme-color1;
                            font-weight: 700;
                            font-size: 12px;
                        }

                        .facility-detail {
                            margin-left: 7px;

                            span {
                                color: rgba(black, 0.5);
                                font-weight: 600;
                                font-size: 12px;
                                text-transform: capitalize;
                                padding: 2px 8px;
                                border: 1px solid #ddd;
                                border-radius: 5px;
                            }
                        }
                    }
                }

                .tour-detail {
                    span {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        font-weight: 600;
                        color: $theme-color1;
                        text-transform: capitalize;
                    }

                    .include-sec {
                        margin-top: 14px;

                        ul {
                            text-transform: capitalize;
                            color: rgba(0, 0, 0, 0.78);
                            margin-top: 10px;

                            li {
                                margin-right: 12px;
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                text-align: center;

                                img {
                                    width: 20px;
                                    display: block;
                                    margin: 0 auto;
                                }

                                i {
                                    font-size: 14px;
                                    margin-right: 5px;
                                    display: block;
                                }

                                &.not-include {
                                    opacity: 0.4;
                                }
                            }
                        }
                    }

                    h6 {
                        margin-top: 4px;
                        text-transform: capitalize;
                        color: rgba(0, 0, 0, 0.78);
                        max-width: 360px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 13px;

                        i {
                            font-size: 12px;
                            color: gray;
                        }
                    }

                    .bottom-section {
                        .price {
                            display: block;
                            margin-top: 15px;

                            h6 {
                                color: $theme-color1;
                                font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                                font-weight: 700;
                                margin-bottom: 0;
                                display: flex;
                                align-items: center;

                                del {
                                    font-size: 14px;
                                    color: black;
                                    font-weight: 700;
                                    margin-right: 10px;
                                }
                            }

                            span {
                                font-size: 14px;
                                font-weight: 500;
                                color: rgba(black, 0.57);
                                text-transform: capitalize;
                            }
                        }
                    }

                    .nowrap-cls {
                        max-width: 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &.restaurant-detail {
                    h5 {
                        margin-top: -4px;

                        span {
                            font-size: 14px;
                            margin-left: 10px;
                            font-weight: 500;
                            padding: 3px 10px;
                            color: white;
                            display: inline-block;

                            &.positive {
                                background-color: rgba(green, 0.83);
                            }

                            &.nagative {
                                background-color: rgba(red, 0.83);
                            }

                            i {
                                color: white;
                                margin-right: 0;
                            }
                        }
                    }

                    ul {
                        li {
                            display: block;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                            text-transform: capitalize;
                            line-height: 24px;
                            position: relative;
                            padding-left: 15px;
                            color: gray;

                            &:last-child {
                                margin-bottom: -4px;
                            }

                            &:after {
                                content: "";
                                width: 6px;
                                height: 1px;
                                background-color: rgba(0, 0, 0, 0.85);
                                left: 0;
                                position: absolute;
                                top: 50%;
                                transform: translateX(-50%);
                                z-index: 0;
                            }
                        }
                    }
                }

                &.cab-detail {
                    ul {
                        li {
                            display: block;
                            font-size: 16px;
                            text-transform: capitalize;

                            span {
                                font-weight: 600;
                            }

                            + li {
                                margin-top: 5px;
                            }
                        }
                    }

                    .button-botton {
                        display: flex;

                        a {
                            &:last-child {
                                margin-left: 10px;
                            }
                        }

                        .btn {
                            font-size: 14px;
                            text-transform: capitalize;
                            padding: 5px 12px;
                            width: 50%;
                            margin-top: 10px;
                            background-color: transparent;
                            color: $theme-color1;
                            border: 1px solid $theme-color1;
                            box-shadow: none;
                            transition: all 0.5s ease;

                            &:hover {
                                color: white;
                                background-color: $theme-color1;
                                transition: all 0.5s ease;
                            }
                        }
                    }
                }
            }

            &.cab-box {
                .special-content {
                    padding: 10px 0 0;

                    ul {
                        li {
                            display: block;
                            font-size: 16px;
                            text-transform: capitalize;
                            position: relative;
                            padding-left: 15px;

                            &:after {
                                content: "";
                                width: 6px;
                                height: 1px;
                                background-color: rgba(0, 0, 0, 0.85);
                                left: 0;
                                position: absolute;
                                top: 50%;
                                -webkit-transform: translateX(-50%);
                                transform: translateX(-50%);
                            }

                            span {
                                font-weight: 600;
                            }

                            + li {
                                margin-top: 5px;
                            }
                        }
                    }

                    .button-botton {
                        display: flex;

                        a {
                            &:last-child {
                                margin-left: 10px;
                            }
                        }

                        .btn {
                            font-size: 14px;
                            text-transform: capitalize;
                            padding: 10px 12px;
                            width: 50%;
                            margin-top: 10px;
                            background-color: transparent;
                            color: $theme-color1;
                            border: 1px solid $theme-color1;
                            box-shadow: none;
                            transition: all 0.5s ease;

                            &:hover {
                                color: white;
                                background-color: $theme-color1;
                                transition: all 0.5s ease;
                            }
                        }
                    }
                }
            }

            &.slider-sec {
                .slick-prev {
                    left: 1px;
                    z-index: 1;
                    transition: all 0.5s ease;
                    background-color: transparent;
                    height: 36px;

                    &:before {
                        content: "\f053";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                    }
                }

                .slick-next {
                    right: 0;
                    z-index: 1;
                    transition: all 0.5s ease;
                    background-color: transparent;
                    height: 36px;

                    &:before {
                        content: "\f054";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                    }
                }

                &:hover {
                    .special-img {
                        .bg-size {
                            transform: scale(1);
                        }
                    }

                    .slick-next,
                    .slick-prev {
                        background-color: #252525;
                        transition: all 0.5s ease;
                    }
                }
            }

            .label-offer {
                position: absolute;
                background-color: $theme-color1;
                color: white;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;
                top: 10px;
                left: -10px;
                padding: 2px 10px;
                font-weight: 700;

                &:after {
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-top: 15px solid darken($theme-color1, 20%);
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -15px;
                }
            }
        }
    }

    &.related-box {
        margin-bottom: -3px;
        overflow: hidden;
    }
}

.category-section {
    overflow: hidden;

    .category-box {
        border-radius: 25px;
        margin-bottom: 15px;
        position: relative;

        .bg-size,
        img {
            transition: all 0.5s ease;
            border-radius: 25px;
        }

        .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 25px;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(231, 56, 39, 0) 100%);
        }

        .top-bar {
            display: flex;
            position: absolute;
            top: 25px;
            left: 25px;

            .offer {
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 13px;
                background-color: $theme-color1;
                border-radius: 15px;
                text-transform: uppercase;
                font-size: 12px;
                margin-right: 10px;
            }

            h5 {
                font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: $theme-color1;
                padding: 3px 10px;
                background-color: white;
                border-radius: 20px;
                margin-bottom: 0;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 0.09em;

                del {
                    font-size: 12px;
                    margin-right: 5px;
                }
            }
        }

        .bottom-bar {
            position: absolute;
            bottom: 25px;
            left: 25px;
            transition: all 0.5s ease;

            .top {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 4px;

                h5 {
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: 0;
                    margin-right: 15px;
                }

                h6 {
                    color: white;
                    margin-bottom: 0;
                    font-weight: 700;
                    margin-left: 20px;
                    font-size: 14px;
                }
            }

            p {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 600;
                color: rgba(white, 0.7);
            }
        }

        .like-cls {
            position: absolute;
            right: 25px;
            top: 25px;

            i {
                color: $red-heart;
                background-color: white;
                padding: 8px;
                border-radius: 100%;
                font-size: 16px;

                .effect {
                    border-radius: 50%;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    right: 0;
                    transition: height 5s ease, width 5s ease;
                    z-index: 0;
                    opacity: 0;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        border-radius: 50%;
                        border: 1px solid rgba(white, 0.6);
                        animation: effect 2s linear 1s infinite;
                    }
                }
            }
        }

        .side-effect {
            &:after {
                content: "";
                position: absolute;
                top: 8px;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 30px;
                width: calc(100% - 16px);
                left: 8px;
                z-index: -1;
                transition: all 0.5s ease;
            }

            &:before {
                content: "";
                position: absolute;
                top: 15px;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.05);
                border-radius: 30px;
                width: calc(100% - 66px);
                left: 33px;
                z-index: -1;
                transition: all 0.5s ease;
            }
        }

        &:hover {
            .like-cls {
                .effect {
                    opacity: 1;
                }
            }

            .bottom-bar {
                bottom: 34px;
                transition: all 0.5s ease;
            }
        }
    }

    .slick-slide {
        > div {
            margin: 0 25px;
        }
    }

    .slick-list {
        margin-left: -25px;
        margin-right: -25px;
    }
}

.category-sec {
    overflow: hidden;

    .category-box {
        border-radius: 25px;
        transition: all 0.5s ease;

        .img-category {
            border-radius: 25px;
            position: relative;
            z-index: 1;

            .bg-size,
            img {
                transition: all 0.5s ease;
                border-radius: 25px;
            }

            .top-bar {
                position: absolute;
                top: 25px;
                left: 25px;

                .offer {
                    color: white;
                    display: inline-block;
                    padding: 5px 13px;
                    background-color: $theme-color1;
                    border-radius: 15px;
                    text-transform: uppercase;
                    font-size: 12px;
                    margin-bottom: 12px;
                }

                h5 {
                    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                    padding: 3px 10px;
                    background-color: rgba(black, 0.3);
                    border-radius: 20px;
                    margin-bottom: 0;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    letter-spacing: 0.09em;

                    del {
                        font-size: 12px;
                        margin-right: 5px;
                    }
                }
            }

            .like-cls {
                position: absolute;
                right: 13%;
                bottom: -22px;

                i {
                    color: $red-heart;
                    background-color: white;
                    padding: 12px;
                    border-radius: 100%;
                    box-shadow: 2.5px 4.33px 18px 0px rgba(0, 0, 0, 0.06);
                    font-size: 21px;

                    .effect {
                        border-radius: 50%;
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        right: 0;
                        transition: height 5s ease, width 5s ease;
                        z-index: 0;
                        opacity: 0;

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            border-radius: 50%;
                            border: 1px solid rgba(white, 0.6);
                            animation: effect 2s linear 1s infinite;
                        }
                    }
                }
            }

            .side-effect {
                &:after {
                    content: "";
                    position: absolute;
                    top: 8px;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 30px;
                    width: calc(100% - 16px);
                    left: 8px;
                    z-index: -1;
                    transition: all 0.5s ease;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 15px;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.05);
                    border-radius: 30px;
                    width: calc(100% - 66px);
                    left: 33px;
                    z-index: -1;
                    transition: all 0.5s ease;
                }
            }
        }

        .content-category {
            padding: 60px 35px 25px 35px;
            background-color: white;
            border-radius: 0 0 25px 25px;
            box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.05);
            margin-bottom: 40px;
            margin-top: -20px;

            .top {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                margin-top: -3px;

                h3 {
                    margin-bottom: 0;
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: capitalize;
                    color: $grey-3;
                    font-weight: 700;
                    margin-right: 15px;
                }
            }

            p {
                font-size: 14px;
                color: $grey-2;
                font-weight: 600;
                margin-bottom: 10px;
            }

            h6 {
                font-size: 14px;
                color: #858585;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 0;

                span {
                    color: $theme-color1;
                    float: right;
                }
            }
        }

        &:hover {
            box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.05);
            transition: all 0.5s ease;

            .img-category {
                .like-cls {
                    i {
                        .effect {
                            opacity: 1;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }

    .slick-slide {
        > div {
            margin: 0 25px;
        }
    }

    .slick-list {
        margin-left: -25px;
        margin-right: -25px;
    }

    &.section-b-space {
        padding-bottom: 60px;
    }
}

.category-wrapper {
    overflow: hidden;

    .title-1 {
        &.title-5 {
            p {
                margin-bottom: 40px;
            }
        }
    }

    .category-wrap {
        position: relative;
        margin: 30px 0;

        .category-img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.5s ease;

            img {
                width: 230px;
                height: 230px;
                border-radius: 25px;
            }

            .side-effect {
                &:after {
                    content: "";
                    position: absolute;
                    top: 35px;
                    height: calc(100% - 70px);
                    background-color: rgba(0, 0, 0, 0.05);
                    border-radius: 20px;
                    width: 100%;
                    left: 16px;
                    z-index: -1;
                    transition: all 0.5s ease;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 15px;
                    height: calc(100% - 30px);
                    background-color: rgba(0, 0, 0, 0.05);
                    border-radius: 25px;
                    width: 100%;
                    left: 8px;
                    z-index: -1;
                    transition: all 0.5s ease;
                }
            }
        }

        .category-content {
            margin-left: 55px;
            background-color: white;
            border-radius: 25px;
            padding: 55px 30px 55px 220px;
            box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.05);
            transition: all 0.5s ease;

            .top {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                margin-top: -4px;
                position: relative;

                h3 {
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: 0;
                }

                h6 {
                    font-size: 14px;
                    color: $theme-color1;
                    text-transform: uppercase;
                    font-weight: 700;
                    margin-bottom: 0;
                    margin-left: 25px;
                }

                .like-cls {
                    i {
                        position: absolute;
                        font-size: 16px;
                        right: 0;
                        top: -3px;
                        color: $red-heart;
                        background-color: rgba($red-heart, 0.05);
                        padding: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;

                        .effect {
                            border-radius: 50%;
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            top: 0;
                            right: 0;
                            transition: height 5s ease, width 5s ease;
                            z-index: 0;
                            opacity: 0;

                            &:after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                border-radius: 50%;
                                border: 1px solid $red-heart;
                                animation: effect 2s linear 1s infinite;
                            }
                        }
                    }
                }
            }

            .rating {
                margin-bottom: 15px;
            }

            p {
                font-size: 14px;
                line-height: 1.5;
                color: #757575;
                font-weight: 500;
                margin-bottom: 20px;
                letter-spacing: 0.08em;
            }

            .bottom {
                display: flex;
                align-items: center;

                h3 {
                    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                    color: $theme-color1;
                    font-weight: 700;
                    margin-bottom: 0;

                    del {
                        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                        color: #c5c5c5;
                        padding-right: 10px;
                    }
                }

                .coupon-code {
                    text-transform: capitalize;
                    padding: 8px 20px;
                    border-radius: 50px;
                    border: 1px dashed $grey-13;
                    color: $grey-13;
                    font-weight: 700;
                    display: inline-block;
                    margin-left: 15px;
                    margin-bottom: 0;
                }
            }
        }

        &:hover {
            .category-img {
                left: 15px;
                transition: all 0.5s ease;

                .side-effect {
                    &:before {
                        left: -8px;
                        transition: all 0.5s ease;
                    }

                    &:after {
                        left: -15px;
                        transition: all 0.5s ease;
                    }
                }
            }

            .category-content {
                box-shadow: 2.5px 4.33px 20px 0px rgba(0, 0, 0, 0.1);
                transition: all 0.5s ease;

                .like-cls {
                    i {
                        .effect {
                            opacity: 0.4;
                        }
                    }
                }
            }
        }
    }

    &.section-b-space {
        padding-bottom: 70px;
    }

    .slick-slide {
        > div {
            margin: 0 25px;
        }
    }

    .slick-list {
        margin-left: -25px;
        margin-right: -25px;
    }
}

.category-bg {
    overflow: hidden;

    .slick-slide {
        > div {
            margin: 0 15px;
        }
    }

    .slick-list {
        margin-left: -15px;
        margin-right: -15px;
    }

    .category-block {
        padding: 90px 50px 50px;
        background-color: white;
        border-radius: 25px;
        margin: 35px 0 10px;
        box-shadow: 1px 0px 11px 0 rgba(0, 0, 0, 0.04);
        position: relative;
        text-align: center;
        transition: all 0.5s ease;

        .category-img {
            position: absolute;
            top: -35px;
            left: 50%;
            transform: translateX(-50%);

            img {
                animation: none;
                transition: all 0.5s ease;
            }
        }

        .rating {
            justify-content: center;
            margin-bottom: 20px;
        }

        h6 {
            font-size: 12px;
            color: $grey-13;
            text-transform: uppercase;
            padding: 6px 12px;
            background-color: #f7f7f7;
            border-radius: 5px;
            display: inline-block;
            letter-spacing: 0.07em;
            font-weight: 700;
            margin-bottom: 16px;

            span {
                color: $theme-color1;
            }
        }

        h4 {
            color: $theme-color1;
            font-weight: 700;
            text-transform: capitalize;
            margin-bottom: 5px;
        }

        h5 {
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 20px;
            line-height: 1.4;
        }

        &:hover {
            box-shadow: 1px 0px 11px 0 rgba(0, 0, 0, 0.1);
            transition: all 0.5s ease;

            .category-img {
                img {
                    animation: pulse 2s infinite;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    &.section-b-space {
        padding-bottom: 90px;
    }
}

.routes-section {
    .routes-box {
        border-radius: 25px;
        transition: all 0.5s ease;

        .routes-img {
            border-radius: 25px;
            position: relative;
            z-index: 1;
            overflow: hidden;

            .price-round {
                position: absolute;
                left: -11px;
                bottom: -4px;
                background-color: darken($theme-color2, 10%);
                width: 100px;
                height: 100px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                > div {
                    margin-left: 10px;
                }

                h6 {
                    font-size: 14px;
                    color: white;
                    font-weight: 700;
                    margin-bottom: 5px;
                }

                h3 {
                    font-size: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                    font-weight: 700;
                    margin-bottom: 0;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: darken($theme-color2, 15%);
                    border-radius: 100%;
                    z-index: -1;
                    top: 0;
                    left: 8px;
                    transition: all 0.5s ease;
                }
            }

            .discount {
                position: absolute;
                top: 25px;
                left: 25px;
                background-color: $theme-color1;
                border-radius: 30px;
                padding: 5px 12px;

                h6 {
                    font-size: 12px;
                    margin-bottom: 0;
                    color: white;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }

        .routes-content {
            padding: 45px 25px 25px 25px;
            background-color: white;
            border-radius: 0 0 25px 25px;
            box-shadow: 2.5px 4.33px 45px 0 rgba(0, 0, 0, 0.05);
            margin-bottom: 0;
            margin-top: -20px;

            .top-bar {
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                h5 {
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: $grey-3;
                    font-weight: 900;
                    text-transform: capitalize;
                    margin-bottom: 0;
                }

                h6 {
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-left: auto;
                    text-transform: capitalize;
                    font-weight: 700;
                    color: #858585;
                }
            }

            .bottom-bar {
                display: flex;
                align-items: center;

                h6 {
                    margin-bottom: 0;
                    color: $theme-color1;
                    font-size: 12px;
                    text-transform: capitalize;
                    font-weight: 700;
                }

                .rating {
                    margin-left: auto;
                }
            }
        }

        &:hover {
            box-shadow: 1px 0px 11px 0 rgba(0, 0, 0, 0.2);

            .routes-img {
                .price-round {
                    &:after {
                        left: 0;
                        transition: all 0.5s ease;
                    }
                }
            }
        }
    }
}

.top-category {
    padding: 0 105px;

    .top_box {
        display: flex;
        background-color: white;
        position: relative;
        overflow: hidden;
        box-shadow: 1px 10px 14px 1px rgba(0, 0, 0, 0.05);

        .right-content {
            padding: 0 25px;
            background-color: white;
            display: flex;
            align-items: center;
            position: relative;
            overflow: hidden;

            h5 {
                font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;
                font-weight: 700;
                margin-bottom: 5px;
                display: flex;
                align-items: center;

                i {
                    font-size: 12px;
                    color: $red-heart;
                    margin-left: 10px;
                }
            }

            p {
                color: rgba(black, 0.4);
                margin-bottom: 5px;
            }

            .rating {
                display: flex;
                margin-bottom: 6px;

                span {
                    color: $rate-yellow;
                    margin-right: 8px;
                    font-weight: 700;
                }
            }

            h6 {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: #343434;
                font-weight: 700;
                margin-bottom: 0;

                del {
                    font-size: 12px;
                    color: #717171;
                    margin-right: 10px;
                }
            }
        }

        .new-label {
            position: absolute;
            right: -20px;
            background-image: url(../images/label.png);
            bottom: -20px;
            background-size: cover;
            background-repeat: no-repeat;
            width: 66px;
            height: 66px;

            span {
                font-size: 12px;
                font-weight: 900;
                text-transform: capitalize;
                color: white;
                position: absolute;
                right: 20px;
                bottom: 28px;
                transform: rotate(-50deg);
            }
        }
    }

    .slick-slide {
        > div {
            margin: 0 25px;
        }
    }

    .slick-list {
        margin-left: -25px;
        margin-right: -25px;
    }

    &.margin-cls {
        margin-top: -70px;
    }

    &.radius-cls {
        .top_box {
            border-radius: 25px;
        }
    }
}

.category-part {
    .category-block {
        .category-image {
            border-radius: 100%;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 15px;
            transition: all 0.5s ease;

            img {
                border-radius: 100%;
            }

            &:hover {
                box-shadow: -1px 7px 8px #b9b9b9;
                transition: all 0.5s ease;
            }
        }

        .category-details {
            text-align: center;
            text-transform: capitalize;

            h3 {
                color: black;
                font-weight: 700;
                margin-bottom: 0;
            }

            h6 {
                margin-bottom: -2px;
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }

    &.radius-category {
        .category-block {
            .category-image {
                border-radius: 22px;
            }
        }
    }
}


/*=====================
16. Filter section css
==========================*/

.filter-section {
    background-image: url("../images/restaurant/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    .filters {
        position: relative;

        .filter-btn {
            border-radius: 19px;
            background-color: white;
            box-shadow: 0px 15px 40px 0px rgba(193, 0, 38, 0.25);
            padding: 10px 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            text-transform: capitalize;

            h6 {
                color: $theme-color1;
                margin-bottom: 0;
                line-height: 1;
                text-transform: capitalize;
                font-weight: 700;
                padding-right: 15px;
                position: relative;

                i {
                    position: absolute;
                    right: 0;
                    top: -3px;
                }
            }
        }

        .filter-content {
            position: absolute;
            top: 45px;
            right: 0;
            width: 100%;
            z-index: 1;
            background-color: rgb(190, 0, 37);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);

            h4 {
                margin-bottom: 0;
                text-align: center;
                background-color: rgba(black, 0.5);
                margin-top: 5px;
                padding: 5px;
                cursor: pointer;
            }

            li {
                display: block;
                color: white;
                margin-top: 6px;
                text-align: center;
                text-transform: capitalize;
                cursor: pointer;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .top-section {
        display: flex;
        align-items: center;
        margin-bottom: 70px;

        .title {
            h2 {
                font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
                color: white;
                text-transform: uppercase;
                font-weight: 700;
            }

            h6 {
                font-size: 14px;
                color: white;
                opacity: 0.5;
                margin-bottom: 0;
            }
        }

        .coupon {
            margin: 0 auto;

            h6 {
                text-transform: capitalize;
                color: white;
                padding: 5px 10px;
                border: 1px dashed rgba(white, 0.5);
                border-radius: 25px;
                margin-bottom: 0;
            }
        }
    }

    .content {
        margin-left: -40px;
        margin-right: -40px;

        .grid-item {
            padding: 0 40px;

            .img-part {
                position: relative;

                img {
                    border-radius: 25px;
                    width: 100%;
                    box-shadow: 1px 10px 14px 1px rgba(0, 0, 0, 0.16);
                }

                .up-btn {
                    background-color: $theme-color1;
                    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.5);
                    position: absolute;
                    width: 56px;
                    height: 56px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 50%;
                    transform: translateX(-50%) scale(0.5);
                    bottom: 15px;
                    opacity: 0;
                    transition: all 0.5s ease;

                    i {
                        color: white;
                        font-size: 18px;
                    }
                }

                &:after {
                    width: 70%;
                    height: 70%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-color: black;
                    opacity: 0.702;
                    position: absolute;
                    //content: "";
                }
            }

            .filter-detail {
                opacity: 0.3;
                transition: all 0.5s ease;

                h6 {
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: white;
                    letter-spacing: 0.3em;
                    margin-top: 15px;
                    margin-bottom: 6px;
                }

                .rating {
                    i {
                        font-size: 10px;
                    }
                }
            }

            &:hover {
                .img-part {
                    .up-btn {
                        transform: translateX(-50%) scale(1);
                        opacity: 1;
                        transition: all 0.5s ease;
                    }
                }

                .filter-detail {
                    opacity: 1;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}


/*=====================
17. full banner section css
==========================*/

.full-banner {
    padding: 120px 0;
    position: relative;
    overflow: hidden;

    .banner-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        h5 {
            color: white;
            font-weight: 700;
            text-transform: capitalize;
            font-style: italic;
            margin-bottom: 30px;
            margin-top: -4px;

            span {
                color: $theme-color1;
            }
        }

        h2 {
            font-size: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
            text-transform: capitalize;
            font-weight: 700;
            color: white;
            margin-bottom: 30px;

            span {
                font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        p {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(white, 0.8);
            line-height: 1.5;
            margin-bottom: 25px;
        }

        .price {
            font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1920 - 320)));

            del {
                font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
                color: rgba(255, 255, 255, 0.9);
                font-weight: 400;
            }
        }

        .bottom-section {
            display: flex;
            align-items: center;
            margin-top: 35px;

            .info-btn {
                margin-left: 25px;

                h6 {
                    font-size: 14px;
                    color: white;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    background-color: rgba(white, 0.05);
                    border-radius: 50px;
                    padding: 10px 16px;
                }

                &.red-info {
                    margin-left: 10px;

                    h6 {
                        color: $theme-color1;
                    }
                }
            }
        }
    }

    .offer-text {
        position: absolute;
        top: 20%;
        left: -64%;

        .offer {
            padding: 3px 5px;
            background-color: $theme-color1;
            color: white;
            font-size: 10px;
            text-transform: uppercase;
            border-radius: 25px;
            font-weight: 700;
        }

        h6 {
            color: white;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-weight: 700;
            position: relative;
            margin-top: 10px;

            span {
                position: relative;
                display: inline-block;
                animation: ani 1s infinite alternate cubic-bezier(0.86, 0, 0.07, 1);

                &:nth-last-child(1n) {
                    animation-delay: -0.1666666667s;
                }

                &:nth-last-child(2n) {
                    animation-delay: -0.3333333333s;
                }

                &:nth-last-child(3n) {
                    animation-delay: -0.5s;
                }
            }

            &:after {
                content: "";
                position: absolute;
                width: 80px;
                height: 3px;
                background-color: white;
                top: 5px;
                right: -100px;
                background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(231, 56, 39, 0) 100%);
            }
        }
    }

    &.banner-section {
        background-image: url(../images/flights/bg-2.jpg);
        background-size: cover;
        background-position: center center;

        .place-image {
            position: relative;

            .animation-place {
                .plane-l {
                    animation: mover 1s infinite alternate;
                    position: absolute;
                    top: 22%;
                    left: -11%;

                    img {
                        width: 70%;
                    }
                }

                .plane-r {
                    position: absolute;
                    right: 7%;
                    top: 0;
                    animation: mover 1.1s infinite alternate;
                }

                .cloud-l {
                    position: absolute;
                    top: 0;
                    left: 7%;

                    img {
                        width: 75%;
                    }
                }

                .cloud-r {
                    top: 0;
                    position: absolute;
                    right: 42%;
                }
            }
        }
    }

    &.small-section {
        padding: 30px 0;
    }

    &.padding-cls {
        padding-top: calc(90px + (200 - 90) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(90px + (200 - 90) * ((100vw - 320px) / (1920 - 320)));
    }

    &.overlay-banner {
        background-blend-mode: overlay;
        background-color: rgba(255, 255, 255, 0.7);

        .banner-content {
            h2 {
                color: black;
            }

            h5 {
                color: rgba(black, 0.80);
            }

            p {
                color: rgba(black, 0.70);
            }

            .bottom-section {
                .info-btn {
                    h6 {
                        color: black;
                        background-color: rgba(black, 0.05);
                    }
                }
            }
        }
    }
}

.food-banner {
    padding: 160px 0;
    position: relative;
    background-image: url("../images/restaurant/bg-2.png");
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: bottom;

    .food-content {
        h3 {
            font-size: calc(22px + (60 - 22) * ((100vw - 320px) / (1920 - 320)));
            font-family: $font_6, $font_4;
            color: white;
            margin-bottom: 20px;
            margin-top: -4px;
        }

        h2 {
            font-family: $font_7, $font_4;
            font-size: calc(30px + (158 - 30) * ((100vw - 320px) / (1920 - 320)));
            color: white;
            letter-spacing: 0.05em;
            text-transform: capitalize;
            line-height: 1;
            margin-bottom: 0;
            position: relative;
            display: inline-block;

            span {
                font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320)));
                position: absolute;
                top: -20px;
                right: 0;
                font-family: $font_0;
                letter-spacing: 0.05em;
            }
        }

        .strip-cls {
            position: relative;
            display: inline-block;
            margin-top: 40px;
            left: 50%;
            transform: translateX(-50%);

            .timer {
                width: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                display: inline-block;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    padding: 0;
                    background: none;
                    color: $theme-color1;
                    font-size: 14px;
                    font-weight: 700;
                    position: relative;
                    margin: 0 18px;

                    span {
                        font-family: $font_7, $font_4;
                        font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
                        font-weight: 400;
                        line-height: 1;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        height: 20px;
                        width: 2px;
                        background-color: rgba($theme-color1, 0.3);
                        top: 50%;
                        transform: translateY(-50%);
                        left: 45px;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .buttons {
            position: relative;
            display: block;
            text-align: center;
            margin-top: 30px;
        }
    }

    .absolute-design {
        .left-design {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -29%;
        }

        .right-design {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -60%;
        }
    }
}

.discount-banner {
    padding: 215px 0;
    position: relative;
    background-image: url("../images/restaurant/bg-3.png");
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;

    .banner-content {
        text-align: center;

        h4 {
            font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
            font-family: $font_6, $font_4;
            color: rgba(white, 0.95);
            margin-bottom: 40px;
            margin-top: -4px;
        }

        h2 {
            font-family: $font_7, $font_4;
            font-size: calc(30px + (95 - 30) * ((100vw - 320px) / (1920 - 320)));
            color: white;
            letter-spacing: 0.05em;
            text-transform: capitalize;
            line-height: 1;
            position: relative;
            display: block;
            margin-bottom: 35px;
        }

        .coupon {
            padding: 6px;
            border: 1px dashed rgba(white, 0.4);
            display: inline-block;
            border-radius: 25px;
            margin-bottom: 35px;

            h5 {
                font-family: $font_2;
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;
                color: white;
                background-color: $theme-color1;
                padding: 5px 15px;
                border-radius: 25px;
                margin-bottom: 0;
                font-weight: 600;
            }
        }

        p {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(white, 0.95);
            text-transform: uppercase;
            margin-bottom: 25px;
            letter-spacing: 0.5em;
            font-weight: 700;
        }

        .rating {
            justify-content: center;

            i {
                font-size: 8px;
            }
        }
    }
}

.collection-banner {
    position: relative;
    overflow: hidden;

    .img-part {
        transition: all 0.5s ease;
        width: 100%;
    }

    &.p-left {
        .contain-banner {
            justify-content: flex-start;
        }
    }

    &.p-right {
        .contain-banner {
            justify-content: flex-end;
        }
    }

    &.p-center {
        .contain-banner {
            justify-content: center;
        }
    }

    .contain-banner {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: calc(10px + (50 - 10) * ((100vw - 320px) / (1920 - 320)));
        padding-right: calc(10px + (50 - 10) * ((100vw - 320px) / (1920 - 320)));

        h4 {
            color: $theme-color1;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            margin-bottom: 10px;
            font-size: 16px;
        }

        h2 {
            font-size: calc(14px + (40 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
            color: $grey-darker;
            letter-spacing: 0.1em;
            line-height: 1;
            margin-bottom: -10px;
        }
    }

    &:hover {
        .img-part {
            transform: scale(1.05);
            transition: all 0.5s ease;
        }
    }
}


/*=====================
18. other section css
==========================*/

.about-text {
    p {
        line-height: 28px;
        letter-spacing: 0.06em;
        text-align: left;
        margin-bottom: -7px;
        color: rgba(0, 0, 0, 0.68);
    }
}

.deals {
    overflow: hidden;

    .slick-slider {
        margin: 0 16px;

        .slick-slide {
            > div {
                margin: 0 40px;
            }
        }

        .slick-list {
            margin-left: -40px;
            margin-right: -40px;
        }
    }

    .deals-box {
        display: flex;

        .deals-img {
            padding: 0;
        }

        .left-portion {
            background-color: white;
            position: relative;
            padding: 0;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;

            &:after {
                content: "";
                position: absolute;
                background-image: url(../images/zig-zag.png);
                right: -10px;
                height: 100%;
                width: 10px;
                top: 0;
            }

            &:before {
                content: "";
                position: absolute;
                background-image: url(../images/zig-zag.png);
                left: -10px;
                height: 100%;
                width: 10px;
                top: 0;
                transform: rotate(180deg);
            }

            .deals-content {
                .detail {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    display: flex;
                    writing-mode: vertical-lr;
                    transform: translateY(-50%);
                    height: 100%;
                    justify-content: space-between;
                    padding: 18px 0;

                    h2 {
                        font-size: calc(17px + (27 - 17) * ((100vw - 320px) / (1920 - 320)));
                        white-space: nowrap;
                        color: $theme-color1;
                        margin-bottom: 0;

                        span {
                            font-size: 16px;
                            color: rgba(black, 0.38);
                        }
                    }

                    h3 {
                        text-transform: capitalize;
                        font-weight: 700;
                        margin-bottom: 0;
                    }
                }

                img {
                    margin-left: 80px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.detail-section {
    background-image: url(../../../common/images/flights/bg.jpg);
    background-size: cover;
    background-position: center center;

    .detail-box {
        text-align: center;
        color: white;
        padding: 0 55px;

        .upper-part {
            margin-bottom: 40px;
            min-height: 180px;

            h6 {
                font-size: 14px;
                letter-spacing: 0.4em;
                text-transform: uppercase;
                margin-bottom: 15px;
            }

            h2 {
                font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
                color: white;
                text-transform: capitalize;
                font-weight: 700;
                position: relative;
                padding-bottom: 15px;
                margin-bottom: 25px;
                line-height: 1.3;

                &:after {
                    position: absolute;
                    width: 90px;
                    height: 3px;
                    background-color: $theme-color1;
                    content: "";
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                }
            }

            h5 {
                text-transform: capitalize;
                margin-bottom: 0;
            }

            &:after {
                content: "";
                position: absolute;
                right: 0;
                width: 1px;
                height: 50%;
                background-color: rgba(255, 255, 255, 0.23);
                top: 24%;
            }
        }
    }

    .row {
        > div {
            &:last-child {
                .upper-part {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    &.no-bg-detail {
        background-image: none;
        position: relative;
        overflow: hidden;

        .detail-box {
            padding: 30px;
            box-shadow: 2.5px 4.33px 25px 0 rgba(0, 0, 0, 0.1);
            background-image: url("../images/flights/sky2.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-blend-mode: overlay;
            background-color: white;
            transition: all 0.5s ease;
            margin: 15px 0;

            .upper-part {
                margin-bottom: 15px;
                min-height: 120px;

                h2 {
                    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                }

                h5 {
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                }

                h6,
                h2,
                h5 {
                    color: black;
                }
            }

            &:hover {
                background-color: rgba(white, 0.8);
                transition: all 0.5s ease;
            }
        }

        .slick-slide {
            > div {
                margin: 0 15px !important;
            }
        }

        .slick-list {
            margin-left: -15px !important;
            margin-right: -15px !important;
        }

        .slick-prev {
            top: -25px;
            right: 30px;
            left: unset;

            &:before {
                opacity: 1;
                color: rgba($theme-color1, 0.3);
                content: "\f053";
                font-family: "Font Awesome 5 free";
                font-weight: 900;
            }

            &:hover {
                &:before {
                    color: rgba($theme-color1, 0.7);
                }
            }
        }

        .slick-next {
            top: -25px;
            right: 0;
            left: unset;

            &:before {
                opacity: 1;
                color: rgba($theme-color1, 0.3);
                content: "\f054";
                font-family: "Font Awesome 5 free";
                font-weight: 900;
            }

            &:hover {
                &:before {
                    color: rgba($theme-color1, 0.7);
                }
            }
        }
    }
}

.cloud {
    animation: slide 50s linear infinite;
    background-repeat: repeat !important;
}

.price-section {
    .container-fluid {
        padding-left: 90px;
        padding-right: 90px;
    }

    .price-box {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .price-img {
            flex: 0 0 42%;
            max-width: 42%;
            overflow: hidden;
            border-radius: 25px 0 0 25px;
            position: relative;

            .bg-size {
                height: 100%;
            }

            img {
                border-radius: 25px 0 0 25px;
                transition: all 0.5s ease;
                width: 100%;
            }

            .label {
                position: absolute;
                left: 20px;
                top: 20px;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                padding: 1px 12px;
                background-color: $theme-color1;
                border-radius: 25px;
                color: white;
                margin-bottom: 0;
                text-transform: capitalize;
                font-weight: 700;
            }
        }

        .price-content {
            flex: 0 0 58%;
            max-width: 58%;
            padding: calc(10px + (34 - 10) * ((100vw - 320px) / (1920 - 320)));
            background-color: white;
            box-shadow: 2.5px 4.33px 25px 0 rgba(0, 0, 0, 0.05);
            border-radius: 0 25px 25px 0;

            .price-title {
                display: flex;
                align-items: center;
                margin-bottom: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
                margin-top: -4px;
                position: relative;

                h3 {
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: 0;
                    transition: all 0.5s ease;
                    color: $grey-3;
                    letter-spacing: 0.001em;

                    &:hover {
                        color: $theme-color2;
                        transition: all 0.5s ease;
                    }
                }

                h6 {
                    font-size: 14px;
                    color: $grey-13;
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: 0;
                    padding: 7px 10px;
                    margin-left: 10px;
                    background-color: $grey-4;
                    border-radius: 20px;
                }

                .like-cls {
                    i {
                        position: absolute;
                        font-size: 16px;
                        right: 0;
                        top: -3px;
                        color: $red-heart;
                        background-color: rgba($red-heart, 0.05);
                        padding: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;

                        .effect {
                            border-radius: 50%;
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            top: 0;
                            right: 0;
                            transition: height 5s ease, width 5s ease;
                            z-index: 0;
                            opacity: 0;

                            &:after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                border-radius: 50%;
                                border: 1px solid $red-heart;
                                animation: effect 2s linear 1s infinite;
                            }
                        }
                    }
                }
            }

            .rating {
                margin-bottom: calc(8px + (25 - 8) * ((100vw - 320px) / (1920 - 320)));
            }

            .price {
                display: flex;
                margin-bottom: 10px;

                a {
                    margin-right: auto;

                    h6 {
                        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                        font-weight: 700;
                        margin-right: auto;
                        color: #565656;
                        text-transform: capitalize;
                        margin-bottom: 0;
                        transition: all 0.5s ease;

                        &:hover {
                            color: $theme-color2;
                            transition: all 0.5s ease;
                        }
                    }
                }

                span {
                    margin: 0 auto;
                    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: uppercase;
                    color: $theme-color1;
                    font-weight: 700;
                }

                h5 {
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 600;
                    margin-left: auto;
                    color: $theme-color2;
                    margin-bottom: 0;

                    span {
                        color: $grey-13;
                        margin-right: 10px;
                        font-weight: 700;
                        text-transform: capitalize;
                    }
                }
            }
        }

        &:hover {
            .price-img {
                img {
                    transform: scale(1.05);
                    transition: all 0.5s ease;
                }
            }

            .price-content {
                .price-title {
                    .like-cls {
                        .effect {
                            opacity: 0.3;
                        }
                    }
                }
            }
        }
    }

    .slick-slider {
        .slick-list {
            margin-left: -15px;
            margin-right: -15px;
        }

        .slick-slide {
            margin: 0 15px;
        }
    }
}

.flight-detail {
    background-image: url(../images/flights/bg.jpg);
    background-size: cover;
    background-position: center center;

    .title-2 {
        h2 {
            color: white;

            span {
                color: white;
            }
        }

        p {
            color: rgba(white, 0.5);
        }
    }

    .flight-box {
        background-color: white;
        border-radius: 15px;
        display: flex;
        overflow: hidden;

        .logo-section {
            width: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: inset -2px -2px 11px 0px rgba(0, 0, 0, 0.05);
        }

        .middle-section {
            width: 75%;

            .flight-info {
                padding: 35px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                position: relative;
                transition: all 0.5s ease;

                &:after {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 2px;
                    height: 55px;
                    background-color: #f2f2f2;
                }

                h4 {
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: black;
                    font-weight: 700;
                    margin-bottom: 10px;
                    text-transform: capitalize;
                    letter-spacing: 0.001em;
                }

                h5 {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: $theme-color1;
                    font-weight: 700;
                    text-transform: capitalize;
                    margin-bottom: 10px;
                }

                i {
                    background-color: rgba($theme-color1, 0.05);
                    padding: 8px;
                    color: $theme-color1;
                    display: inline-block;
                    border-radius: 100%;
                    transform: rotate(-40deg);
                    margin-bottom: 8px;
                }

                h6 {
                    font-size: 14px;
                    color: #bcbcbd;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }

                &:hover {
                    background-color: rgba($theme-color1, 0.03);
                    transition: all 0.5s ease;
                }
            }

            .slick-active {
                &:last-child {
                    .flight-info {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .name-section {
            margin-left: auto;
            width: 180px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            box-shadow: inset 4px -1px 11px 0px rgba(0, 0, 0, 0.05);

            h5 {
                font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;
                font-weight: 700;
                color: black;
                letter-spacing: 0.01em;
                margin-bottom: 10px;
            }

            h6 {
                font-size: 14px;
                color: #bcbcbd;
                letter-spacing: 0.5em;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 15px;
            }

            .btn {
                font-size: 14px;
                padding: 6px 12px;
            }
        }
    }

    .row {
        > div {
            .flight-box {
                + .flight-box {
                    margin-top: 60px;
                }
            }
        }
    }
}

.menu-section {
    overflow: hidden;

    .menu-box {
        border-radius: 25px;
        overflow: hidden;
        border: 1px dashed #e8e8e8;

        .top-bar {
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
            }

            h2 {
                font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;
                color: white;
                font-weight: 700;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 30px;
            }

            .decorate {
                position: absolute;
                font-size: calc(25px + (120 - 25) * ((100vw - 320px) / (1920 - 320)));
                color: rgba(white, 0.3);
                top: 50px;
                font-weight: 900;
                left: 0;
                text-transform: capitalize;
            }

            .overlay {
                position: absolute;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(231, 56, 39, 0) 100%);
                top: 0;
                width: 100%;
                height: 100%;
            }
        }

        .bottom-bar {
            background-color: white;
            padding: 40px;

            .menu-bar {
                display: flex;
                align-items: center;
                padding-bottom: 40px;
                margin-bottom: 40px;
                position: relative;

                &:after {
                    content: "";
                    border-bottom: 1px dashed #e8e8e8;
                    position: absolute;
                    bottom: 0;
                    width: 78%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                img {
                    border-radius: 15px;
                    margin-right: 30px;
                    width: 90px;
                }

                .content {
                    min-width: 65%;

                    h5 {
                        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                        color: black;
                        font-weight: 700;
                        margin-bottom: 6px;
                        text-transform: capitalize;
                    }

                    .rating {
                        margin-bottom: 12px;

                        span {
                            color: $rate-yellow;
                            margin-right: 8px;
                            font-weight: 700;
                        }
                    }

                    p {
                        color: rgba(0, 0, 0, 0.68);
                        text-transform: capitalize;
                        margin-bottom: 6px;
                        font-size: 14px;
                        font-weight: 700;
                    }

                    h6 {
                        font-size: 14px;
                        color: #343434;
                        font-weight: 700;
                        position: relative;
                        margin-bottom: 0;

                        del {
                            color: #717171;
                            margin-right: 5px;
                            font-weight: 400;
                        }

                        label {
                            position: absolute;
                            right: 0px;
                            background-color: #3db137;
                            color: white;
                            padding: 3px 10px 2px;
                            border-radius: 15px;
                            text-transform: uppercase;
                            font-size: 11px;
                            font-weight: 900;
                            top: -2px;
                            margin-bottom: 0;

                            &.red {
                                background-color: #e02d22;
                                box-shadow: 1px 11px 20px 0px rgba(193, 0, 38, 0.12);
                            }
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .slick-list {
        margin-left: -20px;
        margin-right: -20px;
    }

    .slick-slide {
        margin: 0 20px;
    }
}

.book-table {
    position: relative;
    background-image: url("../images/restaurant/table-bg.png");
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;

    .table-form {
        background-color: white;
        border-radius: 25px;
        padding: 40px;
        margin-bottom: 40px;
        position: relative;
        z-index: 1;

        form {
            display: flex;
            align-items: center;

            .row {
                margin: 0;
            }

            .form-group {
                margin-bottom: 0;
                margin-right: 40px;
                position: relative;

                input {
                    background-color: $inner-bg;
                    border: none;
                    border-radius: 35px;
                    padding: 20px 20px 18px;
                    line-height: 1;
                    text-transform: capitalize;
                    font-weight: 700;
                }

                img {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                }
            }

            .form-control {
                background-color: $inner-bg;
                border: none;
                border-radius: 35px;
                padding: 20px 20px 18px;
                line-height: 1;
                text-transform: capitalize;
                font-weight: 700;

                &:focus {
                    z-index: 1;
                    box-shadow: none;
                }
            }

            .input-group-append {
                position: absolute;
                right: 12px;
                top: 12px;

                .btn {
                    border: none;
                    background-color: transparent;
                    padding: 0;

                    i {
                        color: rgba(#c5c5c5, 0.6);
                    }
                }
            }

            .gj-datepicker {
                margin-right: 40px;
                width: auto;
            }

            .btn {
                text-transform: capitalize;
                font-weight: 700;
                margin-left: 15px;
            }

            ::placeholder {
                color: #c5c5c5;
                font-weight: 700;
                font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        &:after {
            content: "";
            position: absolute;
            width: calc(100% + 12px);
            border-radius: 24px;
            background-color: rgba(white, 0.2);
            height: 100%;
            left: -6px;
            top: 0;
            z-index: -1;
        }

        &:before {
            content: "";
            position: absolute;
            width: calc(100% + 24px);
            border-radius: 24px;
            background-color: rgba(white, 0.1);
            height: 100%;
            left: -12px;
            top: 0;
            z-index: -1;
        }
    }

    .detail {
        font-size: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-family: $font_6, $font_4;
        color: white;
        margin-bottom: 0;
        margin-top: 0;
        text-align: center;
    }

    &.single-table {
        background-image: none;
        overflow: visible;

        .table-form {
            margin-bottom: 0;
            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11);
            margin-top: -75px;

            &.classic-form {
                border-radius: 0;
                padding: 25px;
                margin-top: 0;
                box-shadow: none;

                form {
                    .form-group {
                        input {
                            border-radius: 0;
                            padding: 15px 15px 13px 25px;
                        }

                        img {
                            right: 15px;
                            top: 15px;
                        }
                    }

                    .form-control {
                        border-radius: 0;
                        padding: 15px 15px 13px;
                    }

                    .input-group-append {
                        .btn {
                            i {
                                top: 4px;
                                left: 15px;
                            }
                        }
                    }

                    .btn-rounded {
                        border-radius: 0;
                        margin-left: 0;
                    }
                }

                &:after,
                &:before {
                    display: none;
                }
            }
        }
    }

    &.input-radius-cls {
        .table-form {
            &.classic-form {
                form {
                    .form-group {
                        input {
                            background-color: rgba($theme-color1, 0.1);
                            border-radius: 25px;
                        }
                    }

                    .gj-datepicker {
                        margin-right: 0;
                    }

                    .btn-rounded {
                        border-radius: 100px;
                    }
                }
            }
        }
    }
}

.process-steps {
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-position: center center;
    background-color: rgb(255 255 255 / 80%);
    background-blend-mode: hard-light;

    h4 {
        margin-bottom: 40px;
        text-transform: capitalize;
        font-weight: 600;
        line-height: 1.3;
    }

    .step-bg {
        position: relative;
        margin-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

        &:before {
            content: "";
            position: absolute;
            left: -0;
            right: -0;
            height: 100%;
            display: block;
            background: url("../images/icon/step-arrow.png") no-repeat center center;
        }

        .row {
            margin-left: -65px;
            margin-right: -65px;

            > div {
                padding-left: 65px;
                padding-right: 65px;
            }
        }

        .popular-section {
            margin-left: -20px;
            margin-right: -20px;

            > div {
                padding-left: 20px;
                padding-right: 20px;
            }

            .step-box {
                padding: 0;
                box-shadow: none;
                text-align: left;
                display: block;

                .popular-box {
                    width: 100%;

                    .special-content {
                        padding-top: 15px;
                        position: relative;

                        h5 {
                            font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                            text-transform: capitalize;
                            align-items: center;
                            display: flex;
                            overflow: hidden;
                            max-width: 100%;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            color: black;
                            font-weight: 700;
                            line-height: 1.5;
                            margin-bottom: 4px;

                            span {
                                color: $grey-13;
                                font-size: 14px;
                                margin-left: auto;
                                font-weight: 500;

                                i {
                                    color: #a7a7a7;
                                    margin-right: 3px;
                                    font-size: 10px;
                                }
                            }
                        }

                        p {
                            margin-bottom: 10px;
                            color: $grey-13;
                            font-weight: 500;
                        }

                        .bottom-section {
                            .rating {
                                span {
                                    color: $grey-13;
                                    font-size: 12px;
                                    font-weight: 700;
                                    margin-left: 10px;
                                }
                            }

                            .price {
                                display: flex;
                                align-items: center;
                                margin-top: 8px;
                                margin-bottom: -6px;

                                del {
                                    font-size: 14px;
                                    color: black;
                                    font-weight: 700;
                                    margin-right: 10px;
                                }

                                span {
                                    color: $theme-color1;
                                    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                                    font-weight: 700;
                                }

                                .package-cls {
                                    padding-left: 20px;
                                    color: $theme-color1;
                                    font-weight: 700;
                                    font-size: 12px;
                                }

                                .facility-detail {
                                    margin-left: 7px;

                                    span {
                                        color: rgba(black, 0.5);
                                        font-weight: 600;
                                        font-size: 12px;
                                        text-transform: capitalize;
                                        padding: 2px 8px;
                                        border: 1px solid #ddd;
                                        border-radius: 5px;
                                    }
                                }
                            }
                        }

                        .tour-detail {
                            span {
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                font-weight: 700;
                                color: rgba(0, 0, 0, 0.84);
                                text-transform: capitalize;
                            }

                            .include-sec {
                                margin-top: 14px;

                                ul {
                                    text-transform: capitalize;
                                    color: rgba(0, 0, 0, 0.78);
                                    margin-top: 10px;

                                    li {
                                        margin-right: 12px;
                                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                        text-align: center;

                                        img {
                                            width: 18px;
                                            display: block;
                                            margin: 0 auto;
                                        }

                                        i {
                                            font-size: 14px;
                                            margin-right: 5px;
                                            display: block;
                                        }

                                        &.not-include {
                                            opacity: 0.4;
                                        }
                                    }
                                }
                            }

                            h6 {
                                margin-top: 4px;
                                text-transform: capitalize;
                                color: rgba(0, 0, 0, 0.78);
                                max-width: 360px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;

                                i {
                                    font-size: 12px;
                                }
                            }

                            .bottom-section {
                                .price {
                                    display: block;
                                    margin-top: 15px;

                                    h6 {
                                        color: $grey-3;
                                        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                                        font-weight: 700;
                                        margin-bottom: 0;
                                        display: flex;
                                        align-items: center;

                                        del {
                                            font-size: 14px;
                                            color: black;
                                            font-weight: 700;
                                            margin-right: 10px;
                                        }
                                    }

                                    span {
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: rgba(black, 0.57);
                                        text-transform: capitalize;
                                    }
                                }
                            }

                            .nowrap-cls {
                                max-width: 300px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }

        h4 {
            margin-bottom: -3px;
            font-size: calc(16px + 2 * (100vw - 320px)/ 1600);
            color: #ffffff;
        }

        .step-box {
            position: relative;
            padding: 45px 35px 45px;
            box-shadow: 0 15px 39px 0 rgba(8, 18, 109, 0.08);
            background-color: white;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border-radius: 16px;
            transition: all 0.5s ease;
            rotate: 45deg;
            aspect-ratio: 1;
            background-color: $theme-color3;

            > div {
                rotate: -45deg;
            }

            img {
                width: 40px;
                margin-bottom: 20px;
            }

            p {
                line-height: 1.5;
                margin-bottom: 0;
            }

            &:hover {
                transform: translateY(-10px);
                transition: all 0.5s ease;
            }
        }

        &.invert-lines {
            &:before {
                filter: invert(1);
            }
        }
    }

    .title-1 {
        &.detail-title {
            p {
                &.font-design {
                    font-weight: 500;
                    background-color: rgba(255, 255, 255, 0.7);
                    color: black;
                    padding: 15px;
                }
            }
        }
    }

    &.icon-large {
        .step-bg {
            .step-box {
                img {
                    width: 35px;
                }
            }
        }
    }
}

.car-type-section {
    margin-top: calc(0px + (40 - 0) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: -20px;

    .slick-slide {
        transform: scale(0.8);
        transition: all 0.5s ease;

        &.slick-center {
            transform: scale(1);
            transition: all 0.5s ease;

            .type-box {
                .img-part {
                    img {
                        transition: all 0.5s ease;
                    }
                }

                &:hover {
                    .img-part {
                        img {
                            animation: pulse 2s infinite;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }

    .type-box {
        padding: 25px;
        box-shadow: 1px 2px 39px 9px rgba(8, 18, 109, 0.08);
        margin: 20px 0;

        .img-part {
            text-align: center;

            img {
                margin: 0 auto;
            }
        }

        .content {
            text-align: center;

            h5 {
                text-transform: capitalize;
                margin-top: 15px;
                font-weight: 700;
            }

            h6 {
                margin-bottom: 10px;
                text-transform: capitalize;
            }

            p {
                color: rgba(black, 0.6);
                line-height: 1.5;
            }

            ul {
                li {
                    img {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

.cab-slider {
    .image-section {
        &:before {
            background: linear-gradient(to top, rgba(0,0,0,0.63) 0%, rgba(231,56,39,0) 100%);
        }
    }
}

.topTour {
    margin-top: calc(0px + (40 - 0) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: -20px;

    .slick-slide {
        transform: scale(0.8);
        transition: all 0.5s ease;

        &.slick-center {
            transform: scale(1);
            transition: all 0.5s ease;

            .topTour_box {
                .content {
                    opacity: 1;
                }
            }
        }
    }

    .topTour_box {
        padding: 0;
        box-shadow: 1px 2px 39px 9px rgba(8, 18, 109, 0.08);
        margin: 20px 0;
        border-radius: 10px;
        overflow: hidden;

        .content {
            opacity: 0;
            position: absolute;
            bottom: 22px;
            width: 100%;
            text-align: center;
            padding: 30px;
            background: linear-gradient(to top, rgba(111, 111, 111, 0.8) 0%, rgba(231, 56, 39, 0) 100%);
            border-radius: 0 0 10px 10px;

            h6 {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 500;
                color: white;
                text-transform: uppercase;
                margin-bottom: -4px;
            }

            h5 {
                font-size: calc(15px + (22 - 15) * ((100vw - 320px) / (1920 - 320)));
                color: white;
                font-weight: 700;
                line-height: 1.5;
                margin-bottom: 0;
                margin-top: -7px;
                text-transform: capitalize;
            }
        }
    }
}

.cab-full {
    padding-top: 68px;
    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba($theme-color1, 0.5);
        border-radius: 3px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba($theme-color1, 0.8);
    }

    iframe,
    .map-cls {
        width: 100%;
        height: calc(100vh - 68px);
        border: none;
        margin-bottom: -6px;
    }

    .left-bar {
        height: calc(100vh - 68px);
        overflow: auto;

        &.fixed-cls {
            position: fixed;
            width: 400px;
            box-shadow: 3px 0px 4px 0 $box-shadow;
            z-index: 1;
        }
    }

    .flight-search {
        .flight-search-detail {
            .form-group {
                label {
                    font-weight: 700;
                }
            }

            form {
                > div {
                    margin-top: 20px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .location-option {
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
            border-color: #ececec;
            top: 2px;
        }

        [type="radio"]:checked + label:before {
            background-color: #ececec;
            border-color: $theme-color1;
        }
    }

    .cab-search-section {
        padding: 0 15px 15px;

        .cab-search-box {
            background-color: $inner-bg;
            padding: 10px;

            .row {
                align-items: center;
                height: 100%;
            }

            .cab-img {
                img {
                    width: 90px;
                    height: auto;
                }
            }

            .cab_detail {
                li {
                    display: block;
                    text-transform: capitalize;
                    font-weight: 600;
                }
            }

            .btn-solid {
                font-size: 14px;
                padding: 4px 10px;
                text-transform: capitalize;
            }

            + .cab-search-box {
                margin-top: 20px;
            }
        }
    }
}

.scroll-body {
    padding-left: 400px;
}

.top-banner {
    width: 100%;
    position: relative;

    .top-banner-content {
        color: white;
        text-transform: uppercase;
        font-size: 36px;
        font-weight: bold;
        position: absolute;
        right: 25%;
        width: auto;
        top: 50%;
        display: block;
        transform: translateY(-50%);
        text-align: center;
    }

    .flip {
        height: 50px;
        overflow: hidden;

        > div {
            > div {
                color: white;
                padding: 4px 12px;
                height: 40px;
                margin-bottom: 45px;
                display: inline-block;
                line-height: 1;
            }
        }

        div {
            &:first-child {
                animation: show 5s linear infinite;

                div {
                    background: #4ac6da;
                }
            }

            &:last-child {
                div {
                    background: #dc143c;
                }
            }

            div {
                background: #f4c789;
            }
        }
    }

    @keyframes show {
        0% {
            margin-top: -270px;
        }

        5% {
            margin-top: -180px;
        }

        33% {
            margin-top: -180px;
        }

        38% {
            margin-top: -90px;
        }

        66% {
            margin-top: -90px;
        }

        71% {
            margin-top: 0;
        }

        99.99% {
            margin-top: 0;
        }

        100% {
            margin-top: -270px;
        }
    }
}

.classic-section {
    position: relative;
    overflow: hidden;

    .classic-box {
        padding: 30px;
        box-shadow: 2.5px 4.33px 25px 0 rgba(0, 0, 0, 0.1);
        transition: all 0.5s ease;
        margin: 15px 0;

        .top-part {
            padding-left: 30px;
            border-left: 1px dashed #dddddd;
            position: relative;

            h6 {
                text-transform: capitalize;
                margin-bottom: 4px;
                font-weight: 600;
            }

            span {
                color: rgba(black, 0.7);
            }

            .top {
                margin-bottom: 40px;
            }

            &:after {
                top: 0;
            }

            &:before,
            &:after {
                position: absolute;
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 100%;
                background-color: rgba(black, 0.4);
                left: -3px;
                bottom: 0;
            }

            .plane-updown {
                position: absolute;
                left: -8px;
                top: 50%;
                transition: all 0.5s ease;
                transform: translateY(-50%);
            }
        }

        .bottom-part {
            h6 {
                margin-top: 15px;
                margin-bottom: 0;
                text-transform: capitalize;
                font-weight: 600;
                color: $theme-color1;
            }
        }
    }

    .slick-slide {
        > div {
            margin: 0 15px !important;
        }
    }

    .slick-list {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }

    .slick-prev {
        top: -25px;
        right: 30px;
        left: unset;

        &:before {
            opacity: 1;
            color: rgba($theme-color1, 0.3);
            content: "\f053";
            font-family: "Font Awesome 5 free";
            font-weight: 900;
        }

        &:hover {
            &:before {
                color: rgba($theme-color1, 0.7);
            }
        }
    }

    .slick-next {
        top: -25px;
        right: 0;
        left: unset;

        &:before {
            opacity: 1;
            color: rgba($theme-color1, 0.3);
            content: "\f054";
            font-family: "Font Awesome 5 free";
            font-weight: 900;
        }

        &:hover {
            &:before {
                color: rgba($theme-color1, 0.7);
            }
        }
    }
}

.popular-section {
    .popular-box {
        position: relative;

        .content {
            margin-top: 10px;

            h5 {
                text-transform: capitalize;
                font-weight: 700;
                margin-bottom: 3px;
            }

            h6 {
                color: rgba(black, 0.5);
            }

            .special-content {
                padding-top: 5px;
                position: relative;

                h5 {
                    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: capitalize;
                    margin-bottom: 5px;
                    align-items: center;
                    margin-top: -4px;
                    display: flex;
                    overflow: hidden;
                    max-width: 280px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: black;

                    span {
                        color: $grey-13;
                        font-size: 14px;
                        margin-left: 20px;
                        font-weight: 500;

                        i {
                            color: #a7a7a7;
                            margin-right: 3px;
                            font-size: 10px;
                        }
                    }
                }

                p {
                    margin-bottom: 10px;
                    color: $grey-13;
                    font-weight: 500;
                }

                .bottom-section {
                    .rating {
                        span {
                            color: $grey-13;
                            font-size: 12px;
                            font-weight: 700;
                            margin-left: 18px;
                        }
                    }

                    .price {
                        display: flex;
                        align-items: center;
                        margin-top: 5px;
                        margin-bottom: -6px;

                        del {
                            font-size: 14px;
                            color: black;
                            font-weight: 700;
                            margin-right: 10px;
                        }

                        span {
                            color: $grey-3;
                            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                            font-weight: 700;
                        }

                        .package-cls {
                            padding-left: 20px;
                            color: $theme-color1;
                            font-weight: 700;
                            font-size: 12px;
                        }

                        .facility-detail {
                            margin-left: 7px;

                            span {
                                color: rgba(black, 0.5);
                                font-weight: 600;
                                font-size: 12px;
                                text-transform: capitalize;
                                padding: 2px 8px;
                                border: 1px solid #ddd;
                                border-radius: 5px;
                            }
                        }
                    }
                }

                .tour-detail {
                    span {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.84);
                        text-transform: capitalize;
                    }

                    .include-sec {
                        margin-top: 14px;

                        ul {
                            text-transform: capitalize;
                            color: rgba(0, 0, 0, 0.78);
                            margin-top: 10px;

                            li {
                                margin-right: 12px;
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                text-align: center;

                                img {
                                    width: 18px;
                                    display: block;
                                    margin: 0 auto;
                                }

                                i {
                                    font-size: 14px;
                                    margin-right: 5px;
                                    display: block;
                                }

                                &.not-include {
                                    opacity: 0.4;
                                }
                            }
                        }
                    }

                    h6 {
                        margin-top: 4px;
                        text-transform: capitalize;
                        color: rgba(0, 0, 0, 0.78);
                        max-width: 360px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        i {
                            font-size: 12px;
                        }
                    }

                    .bottom-section {
                        .price {
                            display: block;
                            margin-top: 20px;

                            h6 {
                                color: $grey-3;
                                font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                                font-weight: 700;
                                margin-bottom: 0;
                                display: flex;
                                align-items: center;

                                del {
                                    font-size: 14px;
                                    color: black;
                                    font-weight: 700;
                                    margin-right: 10px;
                                }
                            }

                            span {
                                font-size: 14px;
                                font-weight: 500;
                                color: rgba(black, 0.57);
                                text-transform: capitalize;
                            }
                        }
                    }

                    .nowrap-cls {
                        max-width: 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &.restaurant-detail {
                    h5 {
                        margin-top: -4px;

                        span {
                            font-size: 14px;
                            margin-left: 10px;
                            font-weight: 500;
                            padding: 3px;
                            color: white;

                            &.positive {
                                background-color: rgba(green, 0.83);
                            }

                            &.nagative {
                                background-color: rgba(red, 0.83);
                            }

                            i {
                                color: white;
                                margin-right: 0;
                            }
                        }
                    }

                    ul {
                        li {
                            display: block;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                            text-transform: capitalize;
                            line-height: 24px;

                            &:last-child {
                                margin-bottom: -4px;
                            }
                        }
                    }
                }

                &.cab-detail {
                    ul {
                        li {
                            display: block;
                            font-size: 16px;
                            text-transform: capitalize;

                            span {
                                font-weight: 600;
                            }

                            + li {
                                margin-top: 5px;
                            }
                        }
                    }

                    .button-botton {
                        display: flex;

                        a {
                            &:last-child {
                                margin-left: 10px;
                            }
                        }

                        .btn {
                            font-size: 14px;
                            text-transform: capitalize;
                            padding: 5px 12px;
                            width: 50%;
                            margin-top: 10px;
                            background-color: transparent;
                            color: $theme-color1;
                            border: 1px solid $theme-color1;
                            box-shadow: none;
                            transition: all 0.5s ease;

                            &:hover {
                                color: white;
                                background-color: $theme-color1;
                                transition: all 0.5s ease;
                            }
                        }
                    }
                }
            }
        }
    }

    .slick-slide {
        > div {
            /*margin: 0 10px !important;*/
        }
    }

    .slick-list {
        margin-left: -10px !important;
        margin-right: -10px !important;
    }

    .slick-prev {
        top: -25px;
        right: 30px;
        left: unset;

        &:before {
            opacity: 1;
            color: rgba($theme-color1, 0.3);
            content: "\f053";
            font-family: "Font Awesome 5 free";
            font-weight: 900;
        }

        &:hover {
            &:before {
                color: rgba($theme-color1, 0.7);
            }
        }
    }

    .slick-next {
        top: -25px;
        right: 0;
        left: unset;

        &:before {
            opacity: 1;
            color: rgba($theme-color1, 0.3);
            content: "\f054";
            font-family: "Font Awesome 5 free";
            font-weight: 900;
        }

        &:hover {
            &:before {
                color: rgba($theme-color1, 0.7);
            }
        }
    }
}

.destination_section {
    .detail-title {
        margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    }

    .row {
        + .row {
            margin-top: 30px;
        }
    }

    .destination_box {
        position: relative;
        border-radius: 16px;
        overflow: hidden;

        .bg-size {
            transition: all 0.5s ease;
        }

        img {
            width: 100%;
            max-width: 100%;
            transition: all 0.5s ease;
        }

        .content-sec {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            padding: 30px;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(231, 56, 39, 0) 100%);

            h6 {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 500;
                color: white;
                text-transform: uppercase;
                margin-bottom: -4px;
            }

            h5 {
                font-size: calc(15px + (22 - 15) * ((100vw - 320px) / (1920 - 320)));
                color: white;
                font-weight: 700;
                line-height: 1.5;
                margin-bottom: 0;
                margin-top: -7px;
                text-transform: capitalize;
            }
        }

        &:hover {
            img,
            .bg-size {
                transform: scale(1.05);
                transition: all 0.5s ease;
            }
        }
    }
}

.tourSection {
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0,0,0,0) calc(50% - 1px), $theme-color3 calc(50%), $theme-color3 calc(65%), rgba(0,0,0,0) calc(40% + 1px) );

    .tourBox {
        cursor: pointer;

        .bg-size,
        img {
            /*border-radius: 10px;*/
        }

        .clips {
            filter: url(#round) drop-shadow(2px 2px 4px #666);
        }

        .tourImg {
            transition: all 0.5s ease;
            clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
        }

        .tourContent {
            padding-top: 25px;
            text-align: center;

            h3 {
                font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 700;
                text-transform: capitalize;
                margin-bottom: 5px;
                color: $theme-color3;
            }

            h6 {
                margin-bottom: 0;
                color: #828282;
                text-transform: capitalize;
                letter-spacing: 0.8px;
                line-height: 1.4;
                color: $theme-color3;
            }
        }

        &:hover {
            .tourImg {
                transform: scale(0.99);
                transition: all 0.5s ease;
            }
        }
    }

    .slick-slide {
        > div {
            /*margin: 0 15px;*/
        }
    }

    .slick-list {
        margin-left: -15px;
        margin-right: -15px;
    }
}

.mycontent_wrapper {
    position: fixed;
    top: 42%;
    height: 100%;
    pointer-events: none;
    left: 44%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1140px;
    color: white;
    z-index: 99;

    .title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 2px 0 0;
        font-size: 70px;
        white-space: nowrap;
        text-transform: uppercase;
        overflow: hidden;
        width: 100%;
        color: white;
        transform: translateY(60px);
        height: 85px;

        span {
            font-size: calc(26px + (50 - 26) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
            letter-spacing: 0.03em;
            line-height: 1.1;
            text-transform: uppercase;
            width: 100%;
            display: block;
            overflow: hidden;
            transition: transform 800ms cubic-bezier(0.77, 0, 0.175, 1);
            height: 85px;
        }
    }
}

.sticky-cls {
    position: sticky;
    z-index: 1;
    top: 75px;
}

.sticky-cls-top {
    position: sticky;
    z-index: 1;
    top: 22px;
}

.fullpage-content-section {
    position: fixed;
    left: 25%;
    top: 50%;
    transform: translateY(-50%);

    .fullpage-content-wrap {
        &.title {
            overflow: hidden;
            height: 54px;

            h1 {
                color: white;
                margin-bottom: 0;
                line-height: 1;
            }
        }

        &.sub-title {
            height: 25px;
            overflow: hidden;

            h5 {
                color: white;
            }
        }
    }
}


/*=====================
19. Theme modal css
==========================*/

.video-modal {
    .modal-body {
        padding: 0;

        .btn-close {
            position: absolute;
            right: -30px;
            top: -30px;
            opacity: 1;
            background: none;
            padding: 0;

            span {
                text-shadow: none;
                color: white;
                font-size: 26px;
            }
        }
    }

    iframe {
        height: 500px;
        width: 100%;
        vertical-align: middle;
        border: none;
    }
}

.enquiry-modal {
    .form-control {
        margin-bottom: 16px !important;
    }

    .modal-header {
        .modal-title {
            color: black;
            font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
        }

        p {
            margin-bottom: 0;
            margin-top: 5px;
            color: rgba(0, 0, 0, 0.6);
            text-transform: capitalize;
        }
    }
}


/*=====================
20. Map css
==========================*/

.MicrosoftMap {
    .Infobox {
        border-radius: 0 !important;
        border: none !important;

        &.no-title {
            .infobox-info {
                margin-right: 0 !important;
            }
        }

        .infobox-body {
            width: auto !important;
            height: 100% !important;
            max-height: 100% !important;
            padding-bottom: 0 !important;
        }

        .infobox-info {
            padding: 0 !important;
            max-height: max-content !important;
        }
    }
}


/*=====================
21. Inner pages css
==========================*/

.mt-cls {
    margin-top: -30px;
}

// breadcrumb
.breadcrumb-section {
    background-position: center;
    background-size: cover;
    height: 500px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .breadcrumb-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
        padding-top: 93px;
        text-align: center;

        > div {
            padding: 40px 65px;
            background-color: rgba(white, 0.1);
        }

        h2 {
            font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
            color: white;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 16px;
            margin-top: -2px;
        }

        h6 {
            color: white;
            margin-bottom: -4px;
            font-weight: 700;
            font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
        }

        &.dark-content {
            .breadcrumb-item {
                & + .breadcrumb-item {
                    &:before {
                        color: #0c0c0c !important;
                    }
                }
            }

            > div {
                background-color: rgba(255, 255, 255, 0.6);

                h2 {
                    color: $theme-color1;
                }

                li {
                    color: #0c0c0c !important;

                    a {
                        color: #0c0c0c !important;
                    }
                }
            }
        }

        .breadcrumb {
            background-color: transparent;
            margin-bottom: -7px !important;
            padding: 0.75rem 1rem;
            justify-content: center;

            .breadcrumb-item {
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: white;
                text-transform: capitalize;
                font-weight: 700;

                a {
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: white;
                }

                + .breadcrumb-item {
                    &:before {
                        font-family: "Font Awesome 5 free";
                        font-weight: 900;
                        content: "\f101";
                        color: white;
                    }
                }
            }
        }

        &.overlay-black {
            > div {
                background-color: rgba(black, 0.1);
            }
        }

        &.process-section {
            .process-bar {
                background-color: white;
                border-radius: 30px;
            }
        }

        &.restaurant-name {
            padding-top: 0;

            h3 {
                text-transform: capitalize;
                font-weight: 700;
                color: black;
                font-size: calc(22px + (46 - 22) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: 10px;
            }

            h6 {
                color: black;
                margin-bottom: 15px;
            }

            > div {
                position: relative;
                z-index: 1;
                background-color: transparent;
                padding: 0;
            }

            ul {
                li {
                    padding: 0 15px;
                    color: black;
                    position: relative;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

                    + li {
                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 1px;
                            height: 10px;
                            background-color: black;
                        }
                    }

                    i {
                        font-size: 12px;
                        color: black;
                    }
                }
            }

            img {
                width: 30%;
                height: auto;
            }
        }

        &.breadcrumb-right {
            justify-content: flex-end;
            text-align: right;
            padding: 0;

            .breadcrumb {
                justify-content: flex-end;
            }

            > div {
                padding: 0;
                background-color: transparent;
            }
        }
    }

    .title-breadcrumb {
        font-family: $font_5;
        font-size: 510px;
        position: absolute;
        color: rgba(white, 0.02);
        text-transform: capitalize;
        top: 0;
        line-height: 1;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 700;
        z-index: -1;
    }

    .content-bottom {
        background-color: $inner-bg;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;

        .breadcrumb {
            background-color: $inner-bg;
            margin-bottom: 0;

            .breadcrumb-item {
                text-transform: capitalize;

                a {
                    color: #8a8a8a;
                }

                &.active {
                    color: black;
                    font-weight: 700;
                }
            }
        }
    }

    &.no-bg {
        background-image: none;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: black;
            top: 0;
            z-index: -2;
        }
    }

    &.breadcrumb-classic {
        height: 620px;

        .breadcrumb-content {
            align-items: flex-end;
            justify-content: flex-start;
            text-align: left;
            margin-left: 0;

            > div {
                text-align: left;
                padding: 0;
                background-color: transparent;
            }

            .breadcrumb {
                justify-content: end;
            }
        }

        .title-breadcrumb {
            color: rgba(white, 0.1);
        }
    }

    &.breadcrumb-cum-header {
        height: 700px;
        background-position: unset !important;

        .breadcrumb-content {
            height: 700px;

            .package-detail {
                margin-bottom: -3px;

                li {
                    color: white;
                    padding-right: 10px;
                    text-transform: capitalize;
                    font-weight: 500;

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &.flight-sec {
        height: calc(270px + (330 - 270) * ((100vw - 320px) / (1920 - 320)));

        .breadcrumb-content {
            height: calc(270px + (330 - 270) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    &.small-sec {
        height: 180px;

        &.flight-sec {
            height: 225px;
        }
    }
}

.order-food-section {
    height: calc(300px + (520 - 300) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    overflow: hidden;

    .order-food {
        height: calc(300px + (420 - 300) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        align-items: flex-end;

        .book-table {
            &.single-table {
                h3 {
                    text-transform: capitalize;
                    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                    padding: 7px;
                    position: relative;
                    z-index: 1;
                    display: inline-block;
                    background-color: rgba(255, 255, 255, 0.22);

                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        background-color: rgba(white, 0.36);
                        z-index: -1;
                        top: 0;
                        filter: blur(10px);
                    }
                }

                .table-form {
                    box-shadow: 0px -1px 30px 0 rgba(0, 0, 0, 0.15);
                    margin-top: 0;
                    margin-bottom: 60px;
                    border-radius: 10px;
                    padding: 20px 10px;

                    form {
                        .btn {
                            margin-left: 0;
                            width: 100%;
                            border-radius: 5px;
                            padding: 10px;
                            line-height: 1;
                            margin-top: 0;
                        }

                        .form-group {
                            margin-right: 0;

                            input {
                                border-radius: 5px;
                                padding: 10px 15px;
                                background-color: $inner-bg;
                                font-size: 14px;
                            }
                        }

                        ::placeholder {
                            font-weight: 400;
                            font-size: 14px;
                            color: rgba(black, 0.5);
                        }
                    }
                }
            }
        }
    }

    &.not-found {
        height: 270px;

        .order-food {
            height: 270px;
        }
    }
}

.mix-2 {
    .mix-pills {
        align-items: center;
        justify-content: center;
    }
}

.mix-form {
    height: calc(300px + (520 - 300) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    overflow: hidden;

    .form-section {
        height: calc(300px + (520 - 300) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        align-items: center;

        .tab-sec {
            text-align: center;

            .nav-tabs {
                .nav-item {
                    .nav-link {
                        text-transform: capitalize;
                        color: $grey-3;
                        border: none;
                        font-size: 16px;
                        font-weight: 600;
                        background-color: white;

                        &.active {
                            color: white;
                            background-color: $theme-color1;

                            svg {
                                fill: white;
                            }
                        }
                    }
                }
            }
        }

        .tab-content {
            background-color: white;
            padding: 15px;

            .form-group {
                margin-bottom: 0;
            }
        }
    }
}

.up-section {
    margin-top: -70px;
    margin-bottom: 100px;
    box-shadow: 0 0 8px 0 #ddd;
    position: relative;
    background-color: white;

    .about-section {
        background-color: white;
        position: relative;
        padding-top: 85px;
    }

    .team-section {
        .team-box {
            .img-part {
                margin-bottom: 0;

                img {
                    filter: brightness(0.8);
                    transition: all 0.5s ease;
                }
            }

            .team-content {
                position: relative;
                bottom: 0;
            }

            .team-social {
                bottom: 100px;

                .social-box {
                    width: 35px;
                    height: 35px;
                    margin: 0 6px;
                }
            }

            &:hover {
                .img-part {
                    img {
                        filter: brightness(1);
                        transition: all 0.5s ease;
                    }
                }
            }
        }
    }
}

// team section
.team-section {
    .team-box {
        position: relative;

        .img-part {
            margin-bottom: 30px;

            img {
                box-shadow: 5px 8.66px 20px 0px rgba(0, 0, 0, 0.15);
                width: 100%;
            }
        }

        .team-content {
            min-width: 240px;
            padding: 20px;
            background-color: white;
            position: absolute;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;

            h3 {
                font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
                color: black;
                text-transform: capitalize;
                margin-bottom: 5px;
                margin-top: -4px;
            }

            h6 {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: $theme-color1;
                text-transform: capitalize;
                font-weight: 700;
                margin-bottom: -2px;
            }
        }

        .team-social {
            position: absolute;
            bottom: 80px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;

            .social-box {
                width: 45px;
                height: 45px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 8px;
                opacity: 0;
                transform: scale(0.8);
                transition: all 0.5s ease;
            }
        }

        &:hover {
            .team-social {
                .social-box {
                    opacity: 1;
                    transform: scale(1);
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .team-slider {
        margin-bottom: 0;
    }

    .slick-slide {
        > div {
            margin: 0 25px;
        }
    }

    .slick-list {
        margin-left: -25px;
        margin-right: -25px;
    }

    .slick-dots {
        padding-top: 45px;
        position: relative;
        bottom: 0;

        li {
            z-index: 1;

            button {
                &:before {
                    color: $theme-color1;
                    font-size: 12px;
                }
            }

            &.slick-active {
                button {
                    &:after {
                        position: absolute;
                        top: 0;
                        left: -2px;
                        content: "•";
                        width: 20px;
                        height: 20px;
                        font-family: "slick";
                        font-size: 24px;
                        line-height: 20px;
                        text-align: center;
                        color: $theme-color1;
                        opacity: 0.1;
                        z-index: -1;
                    }
                }
            }
        }
    }
}

// facts section
.facts-section {
    .facts-box {
        background-color: white;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-shadow: 2px 3px 13px 0px rgba(0, 0, 0, 0.06);

        .img {
            margin-bottom: 15px;

            img {
                transform: scale(1);
                transition: all 0.5s ease;
            }
        }

        h3 {
            font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
            margin-bottom: 0;
        }

        h6 {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: $theme-color1;
            margin-bottom: 0;
            font-weight: 700;
            text-transform: capitalize;
            margin-bottom: -2px;
        }

        &:hover {
            .img {
                img {
                    transform: scale(1.1);
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

// category page //
.filter-panel {
    background-color: white;
    padding: 25px;
    display: flex;
    align-items: center;
    position: relative;

    .popup-btn {
        display: flex;
        align-items: center;

        h5 {
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            text-transform: capitalize;
            color: black;
            font-weight: 700;
            margin-bottom: 0;
        }

        img {
            margin-left: 10px;
        }
    }

    .respon-filter-btn {
        display: none;
    }

    .left-filter {
        display: inline-block;

        .filters {
            ul {
                h4 {
                    margin-bottom: 0;
                    line-height: 1;
                }

                li {
                    padding: 7px 17px;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    // background-color: #fdfdfd;
                    cursor: pointer;
                    background-color: $inner-bg;
                    transition: all 0.5s ease;
                    font-weight: 700;
                    margin-right: 10px;
                    text-transform: capitalize;

                    &.active {
                        background-color: $theme-color1;
                        color: white;
                        transition: all 0.5s ease;
                    }
                }
            }
        }
    }

    .collection-grid-view {
        display: inline-block;
        margin: 0 auto;
        padding-left: 15px;

        ul {
            display: flex;
            align-items: center;
        }

        > ul {
            > li {
                margin: 0 4px;
            }
        }

        .filter-select {
            li {
                width: 6px;
                height: 17px;
                background-color: $theme-color1;
                margin-left: 4px;
                margin-bottom: -1px;
            }
        }
    }

    .right-panel {
        display: flex;
        align-items: center;
        margin-left: auto;

        ul {
            li {
                + li {
                    margin-left: 8px;
                }
            }
        }
    }

    &.bg-inner {
        background-color: $inner-bg !important;

        .left-filter {
            .filters {
                ul {
                    li {
                        background-color: white;

                        &.active {
                            background-color: $theme-color1;
                        }
                    }
                }
            }
        }
    }

    &.filter-title-bar {
        padding: 0;

        h4 {
            margin-bottom: 0;
            font-weight: 600;
        }

        .left-filter {
            .filters {
                ul {
                    margin-bottom: -15px;

                    li {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

.custom-checkbox .form-check-input:checked ~ .form-check-label::before {
    background-color: $theme-color1;
}

.left-sidebar {
    background-color: white;
    margin-top: 30px;
    transition: all 0.5s ease;
    border-radius: 12px;
    box-shadow: 0px 136px 54px rgba(0, 0, 0, 0.01), 0px 76px 46px rgba(0, 0, 0, 0.05), 0px 34px 34px rgba(0, 0, 0, 0.09), 0px 8px 19px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

    .back-btn {
        padding: 20px 25px;
        border-bottom: 1px solid $inner-bg;
        display: none;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        color: black;
        font-weight: 700;
        margin-bottom: 0;
        cursor: pointer;
    }

    .search-bar {
        position: relative;
        padding: 35px;
        border-bottom: 1px solid $inner-bg;

        input {
            width: 100%;
            border: none;
            background-color: $inner-bg;
            padding: 14px 14px 14px 50px;
        }

        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 50px;
            color: $theme-color1;

            &:after {
                content: "";
                position: absolute;
                width: 1px;
                height: 12px;
                background-color: rgba($theme-color1, 0.5);
                right: -10px;
                top: 2px;
            }
        }

        ::placeholder {
            font-size: 14px;
            color: black;
        }
    }

    .middle-part {
        padding: 25px 35px;

        .section-title {
            display: flex;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid $inner-bg;

            h5 {
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;
                color: black;
                font-weight: 700;
                margin-bottom: 0;
            }

            img {
                margin-left: auto;
            }
        }

        .filter-block {
            .collapse-block-title {
                position: relative;
                font-size: 14px;
                color: black;
                text-transform: capitalize;
                margin: 20px 0;
                cursor: pointer;
                font-weight: 600;

                &:after {
                    position: absolute;
                    display: block;
                    top: 0;
                    right: 0;
                    content: "+";
                    color: black;
                    padding: 0 6px;
                    background-color: $inner-bg;
                }
            }

            .collection-brand-filter {
                .collection-filter-checkbox {
                    margin-bottom: 6px;

                    input {
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
            }

            .sidebar-hotels {
                max-height: 210px;
                overflow-y: auto;

                .hotel-box {
                    display: flex;
                    align-items: center;

                    .img-left {
                        width: 100px;
                        height: auto;
                    }

                    .content-right {
                        padding-left: 20px;

                        h5 {
                            color: black;
                            font-weight: 700;
                            text-transform: capitalize;
                            margin-bottom: 5px;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        }

                        .rating {
                            margin-bottom: 5px;
                        }

                        .price {
                            font-weight: 700;
                            color: rgba(black, 0.6);

                            span {
                                color: rgba(black, 0.6);
                            }
                        }
                    }

                    + .hotel-box {
                        margin-top: 10px;
                    }
                }
            }

            .form-check-input {
                position: absolute;
                z-index: -1;
                opacity: 0;

                &:checked {
                    ~ .form-check-label {
                        &:after {
                            background-image: url("../images/check-mark.svg");
                        }

                        &:before {
                            background-color: $theme-color1;
                        }
                    }
                }
            }

            .form-check-label {
                cursor: pointer;
                margin-left: 5px;
                font-size: 12px;
                color: rgba(black, 0.7);
                text-transform: capitalize;
                position: relative;

                .rating {
                    margin-top: 3px;
                }

                &:before {
                    content: "";
                    top: -2px;
                    height: 20px;
                    width: 20px;
                    background: #f1f1f1;
                    border: none;
                    left: -29px;
                    border-radius: 0;
                    position: absolute;
                    display: block;
                    user-select: none;
                    pointer-events: none;
                }

                &:after {
                    content: "";
                    top: -2px;
                    height: 20px;
                    width: 20px;
                    background-size: 48%;
                    left: -29px;
                    position: absolute;
                    display: block;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }

            .collection-collapse-block {
                border-bottom: 1px solid $inner-bg;

                &.open {
                    .collapse-block-title {
                        &:after {
                            content: "-";
                        }
                    }
                }

                .collection-collapse-block-content {
                    padding-bottom: 12px;
                }
            }
        }
    }

    .bottom-info {
        padding: 0 35px 35px;

        h6 {
            margin-bottom: 0;
            font-size: 12px;
            color: rgba(black, 0.7);
            font-weight: 700;
        }

        h4 {
            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
        }

        h5 {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            background-color: $theme-color1;
            color: white;
            display: inline-block;
            text-transform: capitalize;
            font-weight: 700;
            padding: 6px 10px;
            border-radius: 50px;
            margin-bottom: 12px;

            span {
                text-transform: lowercase;
                font-size: 12px;
                border: 2px solid white;
                border-radius: 100%;
                padding: 0 6px;
            }
        }
    }

    &.sidebar-popup {
        display: none;
        position: absolute;
        z-index: 9;
        top: 80px;
        left: 0;
        border: 1px solid #f1f5f4;
        box-shadow: 0 0 5px #ddd;
        width: 280px;
        max-height: 530px;
        overflow-y: auto;

        &.open {
            display: block;
        }
    }

    &.sidebar-class {
        position: fixed;
        width: 300px;
        left: -300px;
        top: 0;
        margin-top: 0;
        z-index: 5;
        overflow-y: auto;
        height: 100vh;

        .back-btn {
            display: block;
        }
    }
}

.search-panel {
    .search-section {
        margin-top: 0;
        margin-bottom: 0;

        .title-hotel {
            ::placeholder {
                font-size: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320))) !important;
            }
        }

        .search-box {
            box-shadow: none;

            .right-part {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 50px;
            }

            .left-part {
                .search-body {
                    padding: 30px 40px;
                    transition: all 0.5s ease;

                    &:last-child {
                        &:after {
                            display: block;
                        }
                    }

                    h6 {
                        font-weight: 700;
                        color: rgba(0, 0, 0, 0.36);
                    }

                    &.btn-search {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.sticky {
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 1px 3px 20px 0px rgba(0, 0, 0, 0.1);
        animation: smoothScroll 1s forwards;

        .search-section {
            .search-box {
                .left-part {
                    .search-body {
                        padding: 20px 40px;
                        transition: all 0.5s ease;
                    }
                }
            }
        }
    }
}

.mobile-filter {
    align-items: center;
    background-color: white;
    padding: 15px;
    display: none;

    h5 {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        color: black;
        font-weight: 700;
        margin-bottom: -3px;
    }

    img {
        margin-left: auto;
    }
}

.map-section {
    margin-top: 30px;
    z-index: 1;
    position: relative;

    iframe,
    .map {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.infoBox {
    .marker-detail {
        background-color: white;

        img {
            width: 180px;
        }

        .detail-part {
            padding: 6px;

            h6 {
                font-weight: 600;
                margin-bottom: 5px;
                text-transform: capitalize;
                color: black;
            }

            .rating {
                margin-bottom: 3px;

                &.one-star {
                    i {
                        &:first-child {
                            font-weight: 900;
                        }
                    }
                }

                &.two-star {
                    i {
                        &:nth-child(-n+2) {
                            font-weight: 900;
                        }
                    }
                }

                &.three-star {
                    i {
                        &:nth-child(-n+3) {
                            font-weight: 900;
                        }
                    }
                }

                &.four-star {
                    i {
                        &:nth-child(-n+4) {
                            font-weight: 900;
                        }
                    }
                }

                &.five-star {
                    i {
                        font-weight: 900;
                    }
                }
            }

            span {
                display: block;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 400;
                color: #3e3e3e;
            }

            a {
                position: absolute;
                right: 7px;
                bottom: 7px;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                color: $theme-color1;
            }
        }
    }
}

.view-map {
    text-transform: capitalize;
    color: black;
    margin-right: 15px;
    font-weight: 700;
    transition: all 0.5s ease;
    position: relative;

    &:hover {
        color: $theme-color1;
        transition: all 0.5s ease;
    }

    &.mobile-map {
        display: none;
    }

    .arrow {
        position: absolute;
        width: 100%;
        top: -58px;
        font-weight: 600;
        font-size: 14px;
        color: black;
        cursor: auto;

        &:after {
            content: "";
            background-image: url("../images/icon/arrow.png");
            width: 50px;
            height: 50px;
            position: absolute;
            background-size: contain;
            transform: rotate(115deg);
            left: -70px;
            bottom: -30px;
            background-repeat: no-repeat;
        }

        &:hover {
            color: black;
        }
    }
}

.onclick-map {
    display: none;

    iframe,
    .map {
        width: 100%;
        height: 300px;
        margin-top: 30px;
    }

    > div {
        margin-top: 30px;
    }

    &.show {
        display: block;
    }
}

.map-modal {
    .btn-close {
        position: absolute;
        top: 1px;
        right: 10px;
        z-index: 2;
        font-size: 1.9rem;
        background: none;
    }

    .modal-dialog {
        width: 100%;
        height: 90%;
        max-width: 97%;

        .modal-content {
            border: 0 none;
            border-radius: 0;
            background-color: $inner-bg;

            .modal-body {
                display: flex;
                align-items: center;

                .left-sidebar {
                    height: 100%;
                    overflow: scroll;
                }
            }

            iframe,
            .map {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.list-view {
    margin-top: 30px;

    .list-box {
        display: flex;
        padding: 15px;
        background-color: white;

        .list-img {
            width: 35%;
        }

        .list-content {
            margin-left: 25px;
            display: flex;
            align-items: center;
            width: 60%;

            > div {
                width: 100%;
                position: relative;
            }

            h5 {
                font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;
                margin-bottom: 0;
                color: black;
                font-weight: 700;
                line-height: 1.5;
            }

            h6 {
                color: #797979;
            }

            p {
                color: rgba(0, 0, 0, 0.7);
                text-transform: capitalize;
                font-weight: 400;
            }

            .price {
                color: $grey-3;
                font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 700;
                margin-top: 15px;

                del {
                    font-size: 14px;
                    color: rgba(black, 0.6);
                    font-weight: 700;
                    margin-right: 10px;
                }

                span {
                    font-size: 14px;
                }
            }

            .rating {
                span {
                    color: #3e3e3e;
                    font-size: 12px;
                    font-weight: 700;
                    margin-left: 18px;
                }
            }

            .facility-icon {
                display: flex;
                text-align: center;
                margin-top: 20px;
                align-items: center;

                .facility-box {
                    span {
                        text-transform: capitalize;
                        color: #5f5f5f;
                        font-size: 12px;
                    }

                    img {
                        display: block;
                        width: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
                        margin: 0 auto;
                    }

                    i {
                        display: block;
                        font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                        color: rgba(#5f5f5f, 0.64);
                    }

                    + .facility-box {
                        margin-left: 15px;
                    }
                }
            }

            .hotel-info {
                margin-top: 12px;
            }

            .book-now {
                position: absolute;
                right: 0;
                bottom: 0;
            }

            .offer-box {
                position: absolute;
                right: 0;
                top: 0;
                border: 1px solid $inner-bg;
                padding: 10px;
                color: $theme-color1;
                font-weight: 700;
                max-width: 150px;
            }
        }

        + .list-box {
            margin-top: 30px;
        }

        .slick-prev {
            left: 1px;
            z-index: 1;
            transition: all 0.5s ease;
            background-color: transparent;
            height: 36px;

            &:before {
                content: "\f053";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
            }
        }

        .slick-next {
            right: 0;
            z-index: 1;
            transition: all 0.5s ease;
            background-color: transparent;
            height: 36px;

            &:before {
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
            }
        }

        .slider-nav {
            margin-top: 3px;

            .slick-slide {
                margin: 0 3px;
                cursor: pointer;
            }

            .slick-list {
                margin-left: -3px;
                margin-right: -3px;
            }
        }

        &:hover {
            .special-img {
                .bg-size {
                    transform: scale(1);
                }
            }

            .slick-next,
            .slick-prev {
                background-color: #252525;
                transition: all 0.5s ease;
            }
        }
    }
}

.pagination-section {
    margin-top: 40px;

    .pagination {
        justify-content: center;

        .page-item {
            &.active {
                .page-link {
                    background-color: $theme-color1;
                    color: white;
                }
            }

            .page-link {
                color: black;
                background-color: white;
                border: none;
                margin: 0 3px;
                box-shadow: 0 0 8px #dadada;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

.filter_button {
    align-items: center;
    background-color: white;
    display: flex;

    h5 {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        color: black;
        font-weight: 700;
        margin-bottom: -3px;
    }

    img {
        margin-right: 10px;
    }
}

.filter-bottom-content {
    display: none;
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    z-index: 2;
    transition: all 0.5s ease;
    box-shadow: 0px 1px 9px 3px rgba(228, 228, 228, 0.81);
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

    .middle-part {
        .filter-block {
            .collapse-block-title {
                cursor: default;
                margin-top: 0;
                font-weight: 700;

                &:after {
                    display: none;
                }
            }

            .collection-collapse-block {
                border-bottom: none;

                .collection-collapse-block-content {
                    padding-bottom: 0;
                }
            }

            .collection-brand-filter {
                .collection-filter-checkbox {
                    margin-bottom: 10px;
                }
            }
        }

        .button_bottom {
            width: 100%;
        }

        .btn {
            text-transform: capitalize;
            padding: 5px 15px;
        }
    }
}

// single page
.hotel-single-section {
    background-position: center;
    background-size: cover;
    height: 450px;
    position: relative;
    overflow: hidden;

    .hotel-title-section {
        background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(231, 56, 39, 0) 100%);
        position: absolute;
        bottom: 0;
        width: 100%;

        .hotel-name {
            padding: 40px 0;
            display: flex;

            .left-part {
                .top {
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;

                    h2 {
                        color: white;
                        margin-bottom: 0;
                        font-size: calc(16px + (36 - 16) * ((100vw - 320px) / (1920 - 320)));
                    }

                    .rating {
                        margin-left: 15px;
                    }

                    .share-buttons {
                        .btn {
                            padding: 4px 10px;
                            text-transform: capitalize;
                            border-radius: 5px;
                            margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
                            color: black;
                            background-color: $inner-bg;
                        }
                    }
                }

                p {
                    color: rgba(white, 0.9);
                    margin-bottom: 0;
                    font-weight: 700;
                }

                .facility-detail {
                    margin-top: 20px;
                    margin-bottom: -6px;

                    span {
                        color: white;
                        font-weight: 600;
                        font-size: 14px;
                        text-transform: capitalize;
                        padding: 2px 8px;
                        border: 1px solid #ddd;
                        border-radius: 5px;

                        i {
                            margin-right: 3px;
                        }

                        + span {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .right-part {
                margin-left: auto;
                text-align: right;

                .price {
                    font-size: calc(16px + (36 - 16) * ((100vw - 320px) / (1920 - 320)));
                    color: white;

                    span {
                        font-size: 16px;
                    }
                }

                .btn {
                    padding: 7px 13px;
                }
            }
        }
    }
}

.hotel_title_section {
    position: relative;
    width: 100%;

    .hotel-name {
        padding: 0 0 40px 0;
        display: flex;

        .left-part {
            .top {
                display: flex;
                align-items: center;
                margin-bottom: 18px;

                h2 {
                    margin-bottom: 0;
                    font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
                }

                .rating {
                    margin-left: 15px;
                }

                .share-buttons {
                    .btn {
                        padding: 4px 10px;
                        text-transform: capitalize;
                        border-radius: 5px;
                        margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
                        color: black;
                        background-color: white;
                        box-shadow: none;
                    }
                }
            }

            p {
                color: rgba(black, 0.6);
                margin-bottom: 0;
                font-weight: 700;
            }

            .facility-detail {
                margin-top: 15px;
                margin-bottom: -6px;

                span {
                    color: rgba(0, 0, 0, 0.7);
                    font-weight: 700;
                    font-size: 12px;
                    text-transform: capitalize;
                    padding: 2px 8px;
                    border: 1px solid #ddd;
                    border-radius: 5px;

                    i {
                        margin-right: 3px;
                    }

                    + span {
                        margin-left: 10px;
                    }
                }
            }
        }

        .right-part {
            margin-left: auto;
            text-align: right;

            .price {
                font-size: calc(16px + (36 - 16) * ((100vw - 320px) / (1920 - 320)));

                span {
                    font-size: 16px;
                }
            }

            .btn {
                padding: 7px 13px;
            }
        }
    }
}

.single-section {
    position: relative;

    .image_section {
        position: relative;

        .view-all {
            position: absolute;
            width: 100%;
            bottom: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(231, 56, 39, 0) 100%);
            margin-bottom: 0;
            padding: 10px;
            text-transform: capitalize;
            color: white;
            font-weight: 700;
            line-height: 1;
        }

        .slider-thumbnail {
            margin-top: 5px;
            overflow: hidden;

            .slick-slide {
                > div {
                    margin: 0 5px;
                }
            }

            .slick-list {
                margin-left: -5px;
                margin-right: -5px;
            }
        }
    }

    .facility_sec {
        display: flex;
        justify-content: center;
        background-color: white;
        margin-top: 20px;

        .icon-box {
            display: flex;
            align-items: center;
            padding: 0 50px;
            position: relative;

            svg {
                width: 30px;
                height: auto;
                fill: $theme-color1;
            }

            .content {
                margin-left: 15px;

                h5 {
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: 0;
                }

                h6 {
                    font-size: 12px;
                    color: rgba(black, 0.7);
                    text-transform: capitalize;
                    margin-bottom: 0;
                }
            }

            &:after {
                content: "";
                width: 1px;
                height: 25px;
                background-color: $theme-color1;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    .description-section {
        .menu-top {
            margin-top: 30px;
            padding: 12px 0;
            background-color: white;
            box-shadow: 0 2px 4px 0 $box-shadow;

            li {
                a {
                    color: black;
                    text-transform: uppercase;
                    font-weight: 700;
                    padding: 17px 30px;
                    transition: all 0.5s ease;
                }

                &.active {
                    a {
                        color: $theme-color1;
                        border-bottom: 2px solid $theme-color1;
                        transition: all 0.5s ease;
                    }
                }

                &.mobile-menu {
                    display: none;
                }
            }

            &.sticky {
                position: fixed;
                z-index: 3;
                top: 0;
                left: 0;
                width: 100%;
                box-shadow: 1px 3px 20px 0px rgba(0, 0, 0, 0.1);
                margin-top: 0;
                padding: 16px 0;
                animation: smoothScroll 1s forwards;

                li {
                    a {
                        padding: 15px 30px;
                    }
                }
            }

            &.menu-up {
                margin-top: 0;
                margin-bottom: 30px;
            }
        }

        .description-details {
            .content-title {
                text-transform: uppercase;
                font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 700;
            }

            .menu-part {
                margin-top: 20px;
                padding: 30px;
                background-color: white;
                margin-bottom: 30px;
                box-shadow: 0 2px 4px 0 $box-shadow;

                &.page-section {
                    .zoom-gallery {
                        > div {
                            &:nth-child(-n+3) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .accordion-plan {
                    position: relative;

                    .card {
                        border-radius: 0;
                        border: 1px solid $inner-bg;

                        .card-header {
                            background-color: $inner-bg;
                            border-bottom: 0;
                            padding: 6px;

                            button {
                                display: block;
                                width: 100%;
                                text-align: left;
                                color: #353535;
                                text-transform: capitalize;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    border-left: 7px solid transparent;
                                    border-right: 7px solid transparent;
                                    border-top: 6px solid #353535;
                                    right: 20px;
                                    top: 20px;
                                    transition: all 0.3s ease;
                                }
                            }

                            button[aria-expanded="true"] {
                                &:before {
                                    border-top: 0;
                                    border-bottom: 6px solid #353535;
                                    transition: all 0.3s ease;
                                }
                            }
                        }

                        .card-body {
                            color: #444444;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

                            .highlight {
                                margin-top: 10px;

                                li {
                                    display: block;
                                    text-transform: capitalize;
                                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

                                    i {
                                        margin-right: 5px;
                                        font-size: 12px;
                                    }

                                    img {
                                        margin-right: 5px;
                                        width: 18px;
                                    }
                                }
                            }
                        }

                        + .card {
                            margin-top: 20px;
                        }
                    }
                }

                .list-view {
                    margin-top: 0;

                    .list-img {
                        width: 30%;
                    }

                    .facility-icon {
                        margin-top: 10px;
                    }

                    .list-box {
                        padding: 0;
                    }

                    + .list-view {
                        margin-top: 20px;
                    }
                }

                .zoom-gallery {
                    .overlay {
                        position: relative;

                        .overlay-background {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                            transition: all 0.4s ease;

                            i {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: white;
                                font-size: 22px;
                                display: none;
                                transition: all 0.4s ease;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        &:hover {
                            .overlay-background {
                                transition: all 0.4s ease;
                                background-color: rgba(black, 0.6);

                                i {
                                    display: flex;
                                    transition: all 0.4s ease;
                                }
                            }
                        }
                    }

                    > div {
                        &:nth-child(-n+3) {
                            margin-bottom: 30px;
                        }
                    }
                }

                &.facility {
                    h6 {
                        text-transform: capitalize;
                        font-weight: 700;
                        color: $grey-darker;

                        img {
                            margin-right: 5px;
                            width: 20px;
                        }
                    }

                    ul {
                        margin-left: 10px;

                        li {
                            display: block;
                            text-transform: capitalize;
                            line-height: 1.9;

                            i {
                                font-size: 8px;
                                margin-right: 5px;
                                color: rgba(0, 0, 0, 0.6);
                            }
                        }
                    }
                }

                &.about {
                    h6 {
                        font-weight: 700;
                        text-transform: capitalize;
                    }

                    p {
                        color: #383838;
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        line-height: 1.6;
                    }

                    .about-sec {
                        h4 {
                            font-weight: 700;
                            margin-bottom: 10px;
                        }

                        h6 {
                            color: black;
                        }

                        p {
                            color: rgba(black, 0.7);
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

                            &.top-space {
                                margin-top: -6px;
                            }

                            &.bottom-space {
                                margin-bottom: -7px;
                            }
                        }

                        ul {
                            padding-left: 25px;

                            li {
                                list-style-type: square;
                                display: list-item;
                                text-transform: capitalize;
                                color: rgba(black, 0.7);
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                line-height: 1.7;
                                letter-spacing: 0.04em;
                            }
                        }

                        + .about-sec {
                            margin-top: 15px;
                        }

                        .city-detail {
                            margin-bottom: 20px;
                            font-size: 18px;
                            color: #353535;
                        }
                    }

                    .detail-img {
                        margin: 20px 0;
                    }
                }

                &.map {
                    iframe {
                        width: 100%;
                        height: 420px;
                        margin-bottom: -5px;
                    }
                }

                &.review {
                    .review-box {
                        .rating {
                            span {
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                margin-left: 10px;
                                font-weight: 700;
                            }
                        }

                        h6 {
                            font-size: 14px;
                            text-transform: capitalize;
                            color: rgba(0, 0, 0, 0.5);
                            margin-top: 4px;
                        }

                        p {
                            color: #4a4a4a;
                            line-height: 1.6;
                            margin-bottom: 0;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        }

                        + .review-box {
                            border-top: 1px solid $inner-bg;
                            padding-top: 12px;
                            margin-top: 15px;
                        }
                    }
                }

                &.policy {
                    p {
                        color: #212121;
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        line-height: 1.6;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                + .menu-part {
                    margin-top: 20px;
                }

                .rooms-box {
                    width: 100%;

                    tbody {
                        tr {
                            display: block;

                            + tr {
                                margin-top: 20px;
                                padding-top: 10px;
                                border-top: 1px solid $inner-bg;
                            }
                        }
                    }

                    td {
                        &:first-child {
                            width: 25%;
                        }

                        &:nth-child(2) {
                            width: 55%;
                        }

                        &:nth-child(3) {
                            width: 20%;
                            text-align: right;
                        }
                    }

                    .price-details {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        h6 {
                            font-size: 14px;
                            margin-bottom: 3px;
                            color: rgba(0, 0, 0, 0.74);
                        }

                        h5 {
                            font-weight: 700;
                            margin-bottom: 0;
                            font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                        }

                        .btn {
                            padding: 6px 14px;
                            font-size: 14px;
                            border-radius: 0;
                            margin-top: 7px;
                        }

                        span {
                            display: block;
                            color: rgba(0, 0, 0, 0.72);
                        }
                    }

                    .room-detail {
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        h6 {
                            font-weight: 700;
                            text-transform: capitalize;
                            font-size: 14px;
                            letter-spacing: 0.05em;
                        }

                        .row {
                            width: 100%;
                        }

                        .facility-detail {
                            li {
                                display: block;
                                color: black;
                                text-transform: capitalize;
                                line-height: 28px;
                                letter-spacing: 0.06em;

                                img {
                                    margin-right: 8px;
                                    width: 20px;
                                }

                                i {
                                    font-size: 12px;
                                    margin-right: 5px;
                                    color: rgba(black, 0.5);
                                }
                            }
                        }
                    }

                    .room-title {
                        text-transform: capitalize;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }
                }
            }

            &.full-slider {
                .menu-part {
                    margin-top: 0;
                    box-shadow: none;
                    margin-bottom: 0;
                    display: flex;
                    height: 100vh;
                    justify-content: center;
                    align-items: center;
                    padding-top: 70px;

                    .row {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        &.tab-section {
            .menu-top {
                padding: 0;
                background-color: transparent;

                .nav-tabs {
                    margin-bottom: 15px;
                    background-color: white;
                    border-bottom: none;

                    .nav-item {
                        &.active {
                            border: none;
                            border-bottom: 2px solid $theme-color1;
                            color: $theme-color1;
                        }

                        .nav-link {
                            border: none;
                            border-bottom: 2px solid transparent;

                            &.active {
                                border: none;
                                border-bottom: 2px solid $theme-color1;
                                color: $theme-color1;
                            }
                        }
                    }
                }

                &.sticky {
                    background-color: white;
                    margin-bottom: 0;

                    .nav-tabs {
                        margin-bottom: 0;
                    }
                }
            }

            .description-details {
                .menu-part {
                    margin-top: 0;
                }
            }
        }
    }

    .single-sidebar {
        background-color: white;
        padding: 20px;
        box-shadow: 0 2px 4px 0 $box-shadow;

        h4 {
            &.title {
                text-transform: capitalize;
                font-weight: 700;
                margin-top: -4px;
            }
        }

        p {
            color: rgba(0, 0, 0, 0.55);
            margin-bottom: 10px;
            line-height: 1.7;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

            i {
                font-size: 13px;
                margin-right: 10px;
                color: rgba(0, 0, 0, 0.59);
            }
        }

        .selection-section {
            &.flight-search {
                .flight-search-detail {
                    padding: 0;

                    .form-group {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .newsletter-sec {
            padding: 20px;

            .button {
                margin-top: 15px;
                text-align: right;

                .btn {
                    padding: 6px 14px;
                    font-size: 14px;
                    text-transform: capitalize;
                }
            }
        }

        .overlay-map {
            position: relative;
            margin-bottom: 10px;

            img {
                height: 100px;
                width: 100%;
            }

            iframe,
            .map {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.0001;
                z-index: 1;
            }

            h6 {
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                color: black;
                text-transform: capitalize;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                z-index: 0;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        .price-part {
            display: flex;

            .left-part {
                a {
                    color: black;
                    font-weight: 700;
                    text-transform: capitalize;
                }

                span {
                    display: block;
                    text-transform: capitalize;
                    font-weight: 600;
                    line-height: 24px;

                    i {
                        font-size: 10px;
                        margin-right: 5px;
                        color: green;
                    }

                    &.red {
                        color: darkred;
                    }
                }
            }

            .right-part {
                margin-left: auto;
                text-align: right;

                span {
                    color: rgba(black, 0.4);
                    text-transform: capitalize;
                    font-size: 12px;
                    font-weight: 700;
                }

                p {
                    margin-bottom: 0;
                    font-weight: 600;
                    color: black;
                }

                h6 {
                    margin-bottom: 0;
                    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 700;
                    color: black;
                }
            }
        }

        .book-btn-section {
            border-top: 1px solid $inner-bg;
            margin-top: 10px;
            padding-top: 15px;
            text-align: right;

            ::placeholder {
                color: #495057;
                text-transform: capitalize;
                cursor: pointer;
            }

            .detail-top {
                input {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .gj-datepicker {
                + .gj-datepicker {
                    margin-top: 10px;
                }

                ::placeholder {
                    color: #495057;
                }
            }

            .btn-rounded {
                padding: 6px 14px;
                font-size: 14px;
                margin-top: 20px;
                border-radius: 0;
            }

            .selector {
                select {
                    cursor: pointer;
                    width: 100%;
                    height: 38px;
                    background-color: transparent;
                    font-size: 1rem;
                    margin-top: 10px;
                    padding: 5px 14px;
                    color: #495057;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    -webkit-appearance: none;
                    background: url(../images/icon/down-black.png) no-repeat 95%;
                }
            }

            .rooms-section {
                position: relative;
                margin-top: 10px;

                ::placeholder {
                    color: #495057;
                    text-transform: capitalize;
                    cursor: pointer;
                }

                .selector-box {
                    text-align: left;
                    padding: 10px;

                    .room-cls {
                        .title_room {
                            font-size: 16px;
                            text-transform: capitalize;
                            font-weight: 700;
                        }

                        .qty-box {
                            display: flex;
                            align-items: center;

                            label {
                                text-transform: capitalize;
                                margin-right: 30px;
                                margin-bottom: 0;
                            }

                            .input-group {
                                width: 140px;
                                margin-left: auto;

                                .btn {
                                    background-color: transparent;
                                }

                                .form-control {
                                    padding: 0;
                                    text-align: center;
                                    border: none;
                                }
                            }

                            + .qty-box {
                                margin-top: 10px;
                            }
                        }

                        + .room-cls {
                            margin-top: 10px;
                            border-top: 1px solid $inner-bg;
                            padding-top: 10px;
                        }
                    }

                    .bottom-part {
                        border-top: 1px solid $inner-bg;
                        margin-top: 10px;
                        padding-top: 5px;
                        display: flex;
                        align-items: center;

                        .add-room {
                            text-transform: capitalize;
                            color: black;
                            font-weight: 700;
                        }

                        .btn {
                            margin-left: auto;
                        }
                    }
                }
            }
        }

        .contact-title {
            color: black;
            font-weight: 700;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        .bottom_sec {
            margin-top: 10px;
            padding-top: 15px;
            border-top: 1px solid $inner-bg;

            h6 {
                text-transform: capitalize;
                color: black;
                font-size: 14px;
                font-weight: 700;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .weather-sec {
            display: flex;
            text-align: center;
            margin-top: 10px;
            justify-content: center;
            align-items: center;

            li {
                svg {
                    width: 40px;
                    margin: 0;
                }

                h5 {
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                h6 {
                    font-size: 12px;
                    margin-bottom: 0;
                }

                + li {
                    margin-left: 20px;
                }
            }
        }

        .social-box {
            margin-top: 18px;

            i {
                font-size: 14px;
                transition: all 0.5s ease;
                color: rgb(255, 255, 255);
                padding: 9px;
                background-color: $theme-color1;
                border-radius: 100%;
                width: 32px;
                height: 32px;
                text-align: center;
                margin-right: 3px;
            }
        }

        + .single-sidebar {
            margin-top: 30px;
        }

        .order-cart {
            .cart-items {
                margin-top: 20px;

                .items {
                    position: relative;

                    h6 {
                        text-transform: capitalize;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    h5 {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        color: black;
                        font-weight: 500;
                        margin-bottom: 0;
                    }

                    + .items {
                        padding-top: 15px;
                        margin-top: 15px;
                        border-top: 1px solid $inner-bg;
                    }

                    .qty-box {
                        width: 120px;
                        margin-top: 10px;

                        .input-group {
                            box-shadow: 0 2px 4px 0 $box-shadow;

                            .btn {
                                padding: 5px 10px;
                                font-size: 10px;
                                line-height: 1;
                                background-color: $inner-bg;
                            }

                            .form-control {
                                padding: 2px;
                                width: 50px;
                                text-align: center;
                                border-color: $inner-bg;
                            }
                        }
                    }

                    .price {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        font-size: 16px;
                        font-weight: 700;
                    }

                    &.veg {
                        h6 {
                            padding-left: 25px;
                            position: relative;

                            &:after {
                                content: "";
                                left: 0;
                                position: absolute;
                                width: 14px;
                                height: 14px;
                                border: 1px solid green;
                                top: 2px;
                            }

                            &:before {
                                content: "";
                                left: 3px;
                                width: 8px;
                                height: 8px;
                                background-color: green;
                                position: absolute;
                                border-radius: 100%;
                                top: 5px;
                            }
                        }
                    }

                    &.non-veg {
                        h6 {
                            padding-left: 25px;
                            position: relative;

                            &:after {
                                content: "";
                                left: 0;
                                position: absolute;
                                width: 14px;
                                height: 14px;
                                border: 1px solid red;
                                top: 2px;
                            }

                            &:before {
                                content: "";
                                left: 3px;
                                width: 8px;
                                height: 8px;
                                background-color: red;
                                position: absolute;
                                border-radius: 100%;
                                top: 5px;
                            }
                        }
                    }
                }
            }

            .cart-bottom {
                margin-top: 30px;
                padding-top: 20px;
                border-top: 1px solid $inner-bg;

                .sub-total {
                    font-weight: 700;
                    font-size: 18px;
                    text-transform: capitalize;
                    margin-bottom: 5px;

                    span {
                        float: right;
                    }
                }

                p {
                    font-size: 12px;
                    line-height: 1;
                    margin-bottom: 0;
                    max-width: 70%;
                }

                .checkout {
                    margin-top: 20px;
                }
            }

            .empty-cart {
                text-align: center;
                margin-top: 50px;

                h5 {
                    text-transform: capitalize;
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 16px;
                    margin-bottom: 20px;
                }

                img {
                    margin-bottom: 20px;
                    opacity: 0.5;
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.64);
                }
            }
        }
    }
}

.gj-picker-bootstrap {
    table {
        tr td.selected.gj-cursor-pointer {
            div {
                background-color: $theme-color1;
            }
        }
    }
}

.rooms-section {
    position: relative;

    .selector_box {
        padding: 10px;

        .room-cls {
            .title_room {
                font-size: 16px;
                text-transform: capitalize;
                font-weight: 700;
            }

            .qty-box {
                display: flex;
                align-items: center;

                label {
                    text-transform: capitalize;
                    margin-right: 30px;
                    margin-bottom: 0;
                }

                .input-group {
                    width: 140px;
                    margin-left: auto;

                    .btn {
                        background-color: transparent;
                    }

                    .form-control {
                        padding: 0;
                        text-align: center;
                        border: none;
                    }
                }

                + .qty-box {
                    margin-top: 10px;
                }
            }

            + .room-cls {
                margin-top: 10px;
                border-top: 1px solid $inner-bg;
                padding-top: 10px;
            }
        }

        .bottom-part {
            border-top: 1px solid $inner-bg;
            margin-top: 10px;
            padding-top: 5px;
            display: flex;
            align-items: center;

            .add-room {
                text-transform: capitalize;
                color: black;
                font-weight: 700;
            }

            .btn {
                margin-left: auto;
            }
        }
    }
}

.suggestion_box {
    .form-group {
        margin-bottom: 30px;
        position: relative;

        img {
            position: absolute;
            right: 1px;
            bottom: 2px;
            border-left: 1px solid #e5e5e5;
            padding: 8px;
            z-index: 1;
            background-color: white;
        }
    }
}

.m-cls {
    margin-top: 20px;
}

.related-box {
    .slick-slide {
        > div {
            margin: 0 15px !important;
        }
    }

    .slick-list {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }

    .special-box {
        box-shadow: none;
        background-color: white;
        margin-bottom: 0;
    }
}

.overlay-hover {
    .bg-size {
        position: relative;

        &:after {
            transition: all 0.5s ease;
            content: "";
        }

        &:hover {
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(black, 0.2);
                transition: all 0.5s ease;
            }
        }
    }
}

.cab-single-detail {
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 4px 0 $box-shadow;
    margin-top: 25px;

    .title-car {
        display: flex;
        align-items: center;

        h5,
        h6 {
            margin-bottom: 0;
        }

        h5 {
            font-weight: 700;
            text-transform: capitalize;
        }

        h6 {
            margin-left: auto;
            text-transform: capitalize;

            span {
                font-weight: 600;
            }
        }
    }

    .description {
        margin-top: 20px;
        color: rgba(0, 0, 0, 0.52);
    }

    .overview {
        h6 {
            text-transform: capitalize;
            margin-bottom: 16px;
            color: black;
            font-weight: 700;
        }

        ul {
            display: inline-block;

            li {
                display: block;

                + li {
                    margin-top: 15px;
                }
            }

            + ul {
                margin-left: 30px;
            }
        }
    }

    .details {
        margin-top: 25px;

        h6 {
            text-transform: capitalize;
            margin-bottom: 10px;
            color: black;
            font-weight: 700;
        }

        ul {
            li {
                display: block;
                text-transform: capitalize;

                + li {
                    margin-top: 5px;
                }
            }
        }
    }
}

// booking page
.guest-detail {
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 4px 0 $box-shadow;

    h2 {
        font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        margin-top: -6px;
    }

    form {
        .input-group-text {
            border-left: none;
            color: #495057;
            text-transform: uppercase;
            font-weight: 700;

            &:hover {
                background-color: #e9ecef;
                border: 1px solid #ced4da;
                box-shadow: none;
                border-left: 0;
            }
        }

        label {
            text-transform: capitalize;
            font-weight: 600;
            margin-bottom: 5px;
        }

        .form-control {
            border-radius: 0;

            &:focus {
                outline: none;
                box-shadow: none;
                border-color: #ced4da;
            }
        }

        .submit-btn {
            text-align: right;

            .btn {
                width: 25%;
                margin-top: 30px;
            }
        }
    }

    .first-name {
        .iti__flag-container {
            display: none;
        }
    }
}

.summery-box {
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 4px 0 $box-shadow;

    h2 {
        font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        margin-top: -6px;
    }

    .hotel-section {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .hotel-img {
            width: 35%;
        }

        .hotel-detail {
            margin-left: 20px;

            h6 {
                font-weight: 700;
                text-transform: capitalize;
                margin-bottom: 4px;
                margin-top: -2px;
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            }

            p {
                margin-bottom: -3px;
                color: black;
            }
        }
    }

    .summery-section {
        display: flex;
        width: 100%;
        margin-top: 12px;
        padding-top: 15px;
        align-items: center;
        border-top: 1px solid $inner-bg;

        .box {
            width: 100%;

            .right {
                text-align: right;
                width: 49%;
                display: inline-block;
            }

            .left {
                width: 50%;
                display: inline-block;
            }

            .down {
                margin-top: 10px;

                h5 {
                    margin-bottom: -4px;
                }
            }
        }

        h6 {
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 500;
            margin-bottom: 4px;
        }

        h5 {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            text-transform: capitalize;
            font-weight: 600;
        }

        .edit-cls {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: $theme-color1;
            text-transform: capitalize;
            font-weight: 600;
            margin-left: auto;
        }

        .payment-details {
            width: 100%;

            table {
                width: 100%;

                tbody {
                    tr {
                        td {
                            &:first-child {
                                width: 70%;
                                text-transform: capitalize;
                            }

                            &.amount {
                                font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
                            }
                        }

                        td {
                            &:nth-child(2) {
                                text-align: right;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
}

.book-panel {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
    background-color: white;
    text-align: center;
    display: none;
    box-shadow: -1px -2px 6px 0px rgba(168, 168, 168, 0.3);

    .btn {
        width: 50%;
        color: black;
        background-color: white;
        padding: 10px;

        &.theme-color {
            border-right: 1px solid $inner-bg;
        }
    }

    .text {
        width: 50%;
        color: black;
        background-color: white;
        padding: 10px;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $inner-bg;

        span {
            font-weight: 700;
        }
    }
}

.full-page {
    overflow: hidden;
    height: 100vh;

    .single-section {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        padding-top: 47px;

        .description-section {
            width: 100%;

            .description-details {
                .menu-part {
                    margin-top: 0;
                    margin-bottom: 0;
                    background-color: rgba(255, 255, 255, 1);
                    position: relative;

                    h2 {
                        color: #3c7492;
                    }

                    .accordion-plan {
                        margin-left: 0;

                        &:after {
                            display: none;
                        }

                        .card {
                            border: 0;
                            background-color: transparent;

                            .card-header {
                                background-color: transparent;
                                padding: 0;
                                border-bottom: 1px solid $inner-bg;

                                button {
                                    padding-left: 0;

                                    &:before {
                                        display: none;
                                    }
                                }
                            }

                            .card-body {
                                padding: 15px 0 0;
                                line-height: 1.4;

                                .highlight {
                                    li {
                                        display: flex;

                                        + li {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }

                            + .card {
                                margin-top: 12px;
                            }
                        }
                    }

                    .list-view {
                        .list-box {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

    .full-slider {
        .slick-dots {
            bottom: 40px;
            z-index: 1;

            li {
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 500;
                color: white;
                width: auto;

                &.slick-active {
                    .pager__item {
                        background-color: #3c7492;
                        border-radius: 25px;
                    }
                }

                .pager__item {
                    padding: 10px 30px;
                    color: white;
                }

                button {
                    &:before {
                        font-size: 12px;
                        color: $theme-color1;
                    }
                }
            }
        }

        .slick-arrow {
            .next-slick-img,
            .prev-slick-img {
                width: 70px;
                height: 70px;
                border-radius: 100%;
                overflow: hidden;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                transform: scale(0.7);
                transition: all 0.5s ease;

                &:hover {
                    transform: scale(1);
                    transition: all 0.5s ease;
                }
            }
        }

        .slick-prev {
            left: 10px;
            z-index: 1;

            &:before {
                display: none;
            }
        }

        .slick-next {
            right: 60px;
            z-index: 1;

            &:before {
                display: none;
            }
        }
    }

    .overlay {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

.full-width-detail {
    .place-detail {
        text-align: center;

        li {
            padding: 0 30px;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: black;
            font-weight: 700;
            text-transform: uppercase;
            position: relative;

            + li {
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 12px;
                    background-color: rgba($grey-darker, 0.5);
                }
            }
        }
    }

    .about-section {
        margin-top: 40px;

        .about-text {
            align-items: center;

            &.about-text-gray {
                .bottom-detail p {
                    color: gray !important;
                }
            }

            .right-detail {
                h4 {
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                .bottom-detail {
                    p {
                        font-weight: 400;
                        color: black;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.full-page-demo {
    height: 100vh;
    position: relative;

    .container {
        position: relative;
        height: 100%;

        .title {
            position: absolute;
            bottom: 20%;

            h1 {
                color: white;
            }
        }
    }

    .fullpage-content {
        position: absolute;
        width: 55%;
        right: -80px;
        bottom: 70px;
    }
}

.vs-center-wrap {
    .vs-subview-prev,
    .vs-subview-next {
        cursor: pointer;
        position: absolute;
        z-index: 9;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background-color: rgba(white, 0.52);
        border-radius: 100%;
        padding: 8px 15px;
        left: 20px;

        &:focus {
            outline: none;
        }
    }

    .vs-subview-next {
        left: unset;
        right: 20px;
    }
}

.full-page-menu {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
}

// payment page
.vertical-tab {
    .tab-product {
    }
}

// restaurant pages
.order-menu {
    padding: 20px 20px 20px 20px;

    .nav {
        text-align: left;
        background-color: white;

        ul {
            li {
                display: block;
            }
        }

        .nav-link {
            text-transform: capitalize !important;
            font-size: 14px;
            color: black;
            font-weight: 600;
            padding: 5px 15px !important;

            &.active {
                color: $theme-color1;
                background-color: $inner-bg;
            }
        }

        .nav {
            .nav-link {
                font-size: 14px;
                font-weight: 500;

                &.active {
                    color: black;
                    font-weight: 700;
                    background-color: transparent;
                }
            }

            &.show {
                display: block;
            }
        }
    }

    .search-bar {
        border-bottom: 1px solid $inner-bg;
        padding-bottom: 15px;
        margin-bottom: 5px;

        .search {
            position: relative;

            input {
                width: 100%;
                border: none;
                background-color: $inner-bg;
                padding: 8px 16px;
            }

            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
                color: #a0a0a0;
            }

            ::placeholder {
                font-size: 14px;
                color: #a0a0a0;
            }
        }
    }
}

.order-menu-section {
    padding: 20px;
    border-left: 1px solid $inner-bg;

    .order-section {
        h4 {
            text-transform: capitalize;
            font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
            color: rgba(0, 0, 0, 0.6);
        }

        .order-title {
            padding-bottom: calc(0px + (10 - 0) * ((100vw - 320px) / (1920 - 320)));

            h5 {
                font-weight: 700;
                margin-bottom: 3px;
                text-transform: capitalize;
            }

            h6 {
                color: rgba(black, 0.4);
                font-weight: 600;
            }
        }

        .order-items {
            .items {
                position: relative;

                h6 {
                    text-transform: capitalize;
                    font-weight: 600;
                    margin-bottom: 4px;
                }

                p {
                    color: rgba(0, 0, 0, 0.6);
                    margin-bottom: 10px;
                    max-width: 80%;
                    line-height: 1.4;
                }

                h5 {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: black;
                    font-weight: 500;
                    margin-bottom: 0;
                }

                + .items {
                    padding-top: 15px;
                    margin-top: 15px;
                    border-top: 1px solid $inner-bg;

                    .addtocart_btn {
                        top: 15px;
                    }
                }

                .addtocart_btn {
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                    text-align: right;

                    .add-button,
                    .add_cart {
                        width: 120px;
                        border-color: transparent;
                        color: black;
                        background-color: $inner-bg;
                        text-transform: capitalize;
                        cursor: pointer;
                        box-shadow: 0 2px 4px 0 $box-shadow;

                        span {
                            position: absolute;
                            left: 8px;
                            right: 0;
                            bottom: -21px;
                            font-size: 12px;
                        }
                    }

                    .qty-box {
                        display: none;

                        &.open {
                            display: block;
                            position: absolute;
                            right: 0;
                            width: 120px;
                            top: 0;
                        }

                        .input-group {
                            box-shadow: 0 2px 4px 0 $box-shadow;

                            .btn {
                                padding: 5px 10px;
                                font-size: 10px;
                                line-height: 1;
                                background-color: $inner-bg;
                            }

                            .form-control {
                                padding: 2px;
                                width: 50px;
                                text-align: center;
                                border-color: $inner-bg;
                            }
                        }
                    }
                }

                &.veg {
                    h6 {
                        padding-left: 25px;
                        position: relative;

                        &:after {
                            content: "";
                            left: 0;
                            position: absolute;
                            width: 14px;
                            height: 14px;
                            border: 1px solid green;
                            top: 2px;
                        }

                        &:before {
                            content: "";
                            left: 3px;
                            width: 8px;
                            height: 8px;
                            background-color: green;
                            position: absolute;
                            border-radius: 100%;
                            top: 5px;
                        }
                    }
                }

                &.non-veg {
                    h6 {
                        padding-left: 25px;
                        position: relative;

                        &:after {
                            content: "";
                            left: 0;
                            position: absolute;
                            width: 14px;
                            height: 14px;
                            border: 1px solid red;
                            top: 2px;
                        }

                        &:before {
                            content: "";
                            left: 3px;
                            width: 8px;
                            height: 8px;
                            background-color: red;
                            position: absolute;
                            border-radius: 100%;
                            top: 5px;
                        }
                    }
                }
            }
        }

        + .order-section {
            margin-top: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
            padding-top: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
            border-top: 1px solid rgba(0, 0, 0, 0.13);
        }

        &.top-section {
            .order-section {
                padding-top: 0;
                border-top: none;
            }
        }
    }
}

.customized {
    text-align: left;

    .modal-content {
        padding: 20px 30px;
        border-radius: 0;
        border: none;

        .modal-header {
            padding: 0;
            padding-bottom: 10px;
            border-radius: 0;
            border-bottom: 1px dashed $box-shadow;

            .modal-title {
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))) !important;
                font-weight: 700 !important;
            }
        }

        .modal-footer {
            padding: 1rem 0 0 0;
            border-top: 1px dashed $box-shadow;

            .btn {
                padding: 5px 12px;
                text-transform: capitalize;
            }
        }
    }

    .size-option {
        .item {
            h5 {
                margin-bottom: 10px !important;
                font-weight: 600 !important;
            }

            form {
                .form-check {
                    line-height: 1.7;
                    margin-bottom: 6px;
                }

                .form-check-label {
                    text-transform: capitalize;
                }
            }

            + .item {
                margin-top: 15px;
            }
        }
    }
}

.cart {
    width: 35px;
    height: 35px;
    border-radius: 0 8px 8px 0;
    position: fixed;
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    align-items: center;
    box-shadow: 0 2px 4px 0 $box-shadow;
    justify-content: center;
    display: none;
    z-index: 1;

    i {
        color: $theme-color1;
    }
}

.order-cart-right {
    .back-btn {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #ececec;
        display: none;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        color: black;
        font-weight: 700;
        cursor: pointer;
    }
}

.top-title-bar {
    .hotel_title_section {
        display: flex;
        padding: 20px 0;
        align-items: center;

        h2 {
            margin-bottom: 5px;
            font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
            margin-top: -6px;
        }

        h6 {
            margin-bottom: -3px;
            color: rgba(black, 0.5);
        }

        ul {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 5px;
            margin-top: -6px;

            li {
                padding-left: 15px;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                position: relative;
                margin-left: 10px;

                i {
                    font-size: 10px;
                    color: rgba(black, 0.6);
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 5px;
                    height: 5px;
                    background-color: black;
                    border-radius: 100%;
                }
            }
        }

        .right-part {
            margin-left: auto;
            text-align: right;
        }
    }
}

.checkout-process {
    .checkout-box {
        background-color: white;
        padding: 20px;
        box-shadow: 0 2px 4px 0 $box-shadow;

        h4 {
            &.title {
                text-transform: capitalize;
                font-weight: 700;
                margin-top: -4px;
            }
        }

        .sub-title {
            display: flex;
            margin-top: 20px;
            align-items: center;
            margin-bottom: 15px;

            h5 {
                text-transform: capitalize;
                font-weight: 600;
                margin-bottom: 0;
            }

            a {
                margin-left: auto;
                border: 1px solid rgba(black, 0.2);
                color: rgba(black, 0.9);
                padding: 3px 10px;
                text-transform: capitalize;
            }
        }

        .address-sec {
            margin-top: 5px;

            .select-box {
                cursor: pointer;

                .address-box {
                    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
                    border: 1px solid #dddddd;
                    transition: all 0.5s ease;

                    .top {
                        h6 {
                            text-transform: capitalize;
                            font-weight: 700;

                            span {
                                float: right;
                                background-color: rgba(green, 0.9);
                                color: white;
                                padding: 5px 15px;
                                font-size: 80%;
                                border-radius: 3px;
                            }
                        }
                    }

                    .middle {
                        margin-top: 15px;

                        .address {
                            p {
                                margin-bottom: 5px;
                                color: rgba(black, 0.7);
                            }
                        }

                        .number {
                            margin-top: 15px;

                            p {
                                color: rgba(black, 0.7);
                                text-transform: capitalize;
                            }
                        }
                    }

                    .bottom {
                        border-top: 1px solid #dddddd;
                        display: flex;
                        text-align: center;
                        padding-top: 10px;

                        .bottom_btn {
                            width: 47.5%;
                            text-transform: capitalize;
                            font-weight: 600;
                            padding: 4px;
                            border-radius: 5px;
                            background-color: rgba(red, 0.15);
                            color: rgba(red, 0.9);

                            &:first-child {
                                margin-right: 5%;
                                background-color: rgba(green, 0.15);
                                color: rgba(green, 0.9);
                            }
                        }
                    }
                }

                &.active {
                    .address-box {
                        border: 1px solid rgba(black, 0.5);
                        transition: all 0.5s ease;
                    }
                }
            }
        }

        .review-section {
            margin-top: 20px;

            .review_box {
                box-shadow: none;

                .flight_detail {
                    padding: 0;
                }
            }
        }

        + .checkout-box {
            margin-top: 20px;
        }
    }
}

.checkout-cart {
    .single-sidebar {
        .order-cart {
            .cart-bottom {
                .sub-total {
                    font-weight: 500;

                    &.grand-total {
                        font-weight: 700;
                        border-top: 1px solid $inner-bg;
                        padding-top: 13px;
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}

// flight page
.flight-search {
    .flight-search-detail {
        background-color: white;
        padding: 30px 15px;

        .form-group {
            margin-bottom: 0;
            position: relative;

            img {
                position: absolute;
                right: 0;
                bottom: 0;
                border-left: 1px solid #e5e5e5;
                padding: 10px;
            }

            label {
                text-transform: capitalize;
                margin-bottom: 5px;
                font-weight: 700;
                font-size: 15px;
            }

            .form-control {
                border-radius: 0;
                text-transform: capitalize;
                border: 1px solid #e5e5e5;

                &:focus {
                    box-shadow: none;
                }
            }

            .gj-datepicker,
            .gj-timepicker {
                button {
                    border: 1px solid #e5e5e5 !important;
                    background-color: white;
                    height: 38px;

                    i {
                        opacity: 0.2;
                    }
                }
            }

            ::placeholder {
                color: #a5a5a5;
                text-transform: capitalize;
            }
        }

        .search-btn {
            display: flex;
            align-items: flex-end;
            height: 100%;

            .btn {
                width: 100%;
            }
        }

        .responsive-close {
            display: none;
        }
    }

    .responsive-detail {
        display: none;
        background-color: white;
        padding: 15px 25px;
        position: relative;

        .destination {
            span {
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;
                color: black;
                font-weight: 700;
                margin-bottom: -3px;
            }
        }

        .details {
            span {
                text-transform: capitalize;
                color: #5d5d5d;
            }
        }

        .modify-search {
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);

            .btn {
                padding: 4px 6px;
                text-transform: capitalize;
                font-size: 14px;
            }
        }
    }
}

.selector-box-flight {
    text-align: left;
    padding: 10px;
    position: absolute;
    width: 100%;
    min-width: 200px;
    background-color: white;
    z-index: 3;
    border: 1px solid $box-shadow;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    top: auto;
    display: none;

    .room-cls {
        .title_room {
            font-size: 16px;
            text-transform: capitalize;
            font-weight: 700;
        }

        .qty-box {
            display: flex;
            align-items: center;

            label {
                text-transform: capitalize;
                margin-right: 30px;
                margin-bottom: 0;
            }

            .input-group {
                width: 90px;
                margin-left: auto;

                .btn {
                    background-color: transparent;
                }

                .form-control {
                    padding: 0;
                    text-align: center;
                    border: none;
                }
            }

            + .qty-box {
                margin-top: 10px;
            }
        }

        + .room-cls {
            margin-top: 10px;
            border-top: 1px solid $inner-bg;
            padding-top: 10px;
        }
    }

    .flight-class {
        border-top: 1px solid #e7e7e7;
        margin-top: 10px;
        padding-top: 5px;
        padding-bottom: 3px;

        .form-check {
            margin-top: 7px;
        }

        .form-check-label {
            text-transform: capitalize;
        }
    }

    .bottom-part {
        border-top: 1px solid #e7e7e7;
        margin-top: 5px;
        padding-top: 5px;
        display: flex;
        align-items: center;

        .btn {
            margin-left: auto;
        }
    }

    &.show {
        display: block;
    }
}

.top-bar-flight {
    display: flex;
    margin-top: 30px;
    position: relative;

    .date-fare-slider {
        background-color: white;
        border-right: 1px solid $light_grey;
        padding: 0 40px;
        width: 92%;

        .slick-slider {
            border-left: 1px solid $light_grey;
            border-right: 1px solid $light_grey;

            .slick-prev {
                left: -30px;

                &:before {
                    content: "\f053";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    color: #a0a0a0;
                }
            }

            .slick-next {
                right: -30px;

                &:before {
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    color: #a0a0a0;
                }
            }
        }

        .fare-box {
            text-align: center;
            border-right: 1px solid $light_grey;
            padding: 15px 10px;

            h6 {
                margin-bottom: 0;
                color: #585858;
                font-size: 12px;
            }

            h5 {
                text-transform: capitalize;
                font-size: 14px;
                margin-bottom: 5px;
                color: black;
                font-weight: 700;
            }

            &.active {
                h5 {
                    color: $theme-color1;
                    font-weight: 700;
                }
            }
        }
    }

    .fare-calender {
        width: 8%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;

        i {
            font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
            color: #484848;
            display: block;
            margin-bottom: -5px;
        }

        h6.title {
            font-size: 12px;
            margin-bottom: 0;
            margin-top: 10px;
            text-transform: capitalize;
        }

        .calender-external {
            position: absolute;
            z-index: 1;
            right: 0;
            top: 0;
            background-color: white;
            padding: 25px;
            display: none;
            box-shadow: -2px 6px 8px 4px rgba(0, 0, 0, 0.1);

            .fc-dayGrid-view .fc-body .fc-row {
                height: 60px !important;
            }

            .fc-view,
            .fc-view > table {
                display: inline-block;
            }

            .fc th,
            .fc td {
                width: 80px;
            }

            .fc-scroller.fc-day-grid-container {
                height: auto !important;
            }

            .fc-event,
            .fc-event-dot {
                border: none;
                background-color: transparent;
                text-align: right;
            }

            .fc-content {
                font-weight: 700;
                color: $theme-color1;
            }

            &.show {
                display: block;
            }
        }
    }
}

.flight-detail-sec {
    margin: 20px 0;

    .title-bar {
        text-align: center;
        padding: 10px 0;

        p {
            margin-bottom: 0;
            color: #545454;
            text-transform: capitalize;
        }
    }

    .selection-bar {
        background-color: white;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            text-transform: capitalize;
            color: black;
            font-weight: 500;
        }

        .details {
            margin-left: auto;
        }
    }

    .detail-bar {
        .detail-wrap {
            background-color: #FDF9F9;
            /*padding: 15px 0;*/
            border: 0.4px solid rgba(0, 0, 0, 0.4);
            transition: all 0.5s ease;

            .logo-sec {
                text-align: center;
                background: rgba(75, 32, 112, 0.1);
                border-radius: 5px;

                img {
                    width: 40px;
                    height: auto;
                    margin-bottom: 5px;
                }

                .title {
                    display: block;
                    text-transform: capitalize;
                }
            }

            .airport-part {
                display: flex;
                text-align: center;
                align-items: center;
                height: 100%;

                .airport-name {
                    h4 {
                        margin-bottom: 3px;
                        margin-top: -4px;
                        color: $grey-darker;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    h6 {
                        margin-bottom: -5px;
                        color: #909090;
                        font-size: 12px;
                    }

                    &.arrival {
                        margin-left: auto;
                    }
                }

                .stop {
                    font-size: calc(10px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                    width: 100%;
                    color: rgba(0, 0, 0, 0.5);
                    font-weight: 400;
                }

                .airport-progress {
                    width: 100%;
                    padding: 0 25px;
                    position: relative;



                    i {
                        color: $theme-color1;
                    }
                }
            }

            .price {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                text-align: center;

                h4 {
                    margin-bottom: -4px;
                    font-weight: 600;
                    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                }

                span {
                    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
                    color: grey;
                    text-transform: capitalize;
                }
            }

            .book-flight {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                .btn {
                    padding: 5px 16px;
                    text-transform: capitalize;
                }
            }

            + .detail-wrap {
                margin-top: 20px;
            }

            &.active {
                border: 1px dashed $theme-color1;
                transition: all 0.5s ease;
                box-shadow: 0px 1px 9px 3px rgba(228, 228, 228, 0.81);
            }
        }
    }

    &.full_width-detail {
        .detail-bar {
            .detail-wrap {
                padding: 30px 0;

                .logo-sec {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        margin-bottom: 0;
                        width: 60px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &.book_panel {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 2;
        background-color: white;
        box-shadow: -1px -1px 5px 0 rgba(77, 77, 77, 0.14);
        margin: 0;

        .detail-bar {
            .detail-wrap {
                .logo-sec {
                    img {
                        width: 60px;
                        margin-bottom: 0;
                    }
                }

                .airport-part {
                    .airport-progress {
                        &:after {
                            display: none;
                        }

                        i {
                            color: black;
                        }
                    }
                }

                .price {
                    h4 {
                        font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                    }
                }
            }

            &.border-cls {
                .detail-wrap {
                    .row {
                        border-left: 1px solid $box-shadow;
                        border-right: 1px solid $box-shadow;
                    }
                }
            }
        }

        .grand-total {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .total {
                margin-bottom: 0;
                margin-right: 30px;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                text-transform: capitalize;

                span {
                    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 700;
                    margin-left: 5px;
                }
            }
        }
    }

    &.round_trip {
        .detail-bar {
            .detail-wrap {
                cursor: pointer;
            }
        }
    }

    &.cab-detail-sec {
        .detail-bar {
            .detail-wrap {
                .logo-sec {
                    img {
                        width: 160px;
                        margin-bottom: 10px;
                    }

                    .title {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    }

                    label {
                        margin-bottom: 0;
                        text-transform: capitalize;
                        color: rgba(black, 0.56);
                    }
                }

                .car-details {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    ul {
                        li {
                            display: block;
                            text-transform: capitalize;
                            color: #3e3e3e;

                            img {
                                margin-right: 5px;
                                width: 16px;
                            }

                            + li {
                                margin-top: 10px;
                            }
                        }

                        + ul {
                            margin-left: 30px;
                        }
                    }
                }

                .price {
                    h6 {
                        font-size: 14px;
                        color: grey;
                        text-transform: capitalize;
                        margin-bottom: 0;
                        margin-top: 10px;

                        span {
                            font-size: 14px;
                            color: black;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.top-filter-section {
    background-color: white;
    margin-top: 20px;
    padding: 15px 30px;

    ul {
        display: flex;
        align-items: center;
        justify-content: center;

        .back-btn {
            display: none;
        }

        .onclick-title,
        .filter-title {
            min-width: 170px;
            cursor: pointer;
            position: relative;
            border-left: 1px solid #e0e0e0;

            h6 {
                text-transform: capitalize;
                font-size: 16px;
                text-align: center;
                margin-bottom: 0;
                padding: 0 20px 0 0;
                position: relative;

                &:before {
                    font-family: "Font Awesome 5 free";
                    font-weight: 900;
                    content: "\f107";
                    position: absolute;
                    right: 15px;
                    opacity: 0.2;
                }
            }

            &:nth-child(-n+3) {
                border-left: none;
            }

            .onclick-content {
                display: none;
                position: absolute;
                background-color: white;
                z-index: 1;
                padding: 10px 20px;
                width: 200px;
                left: 30px;
                top: 34px;
                box-shadow: 0px 1px 9px 3px rgba(228, 228, 228, 0.81);

                .custom-control {
                    .form-check-label {
                        text-transform: capitalize;
                    }
                }
            }

            &.show {
                .onclick-content {
                    display: block;
                }
            }
        }

        .filter-title {
            min-width: 60px;

            h6 {
                padding: 0;
                font-weight: 700;

                &:before {
                    display: none;
                }
            }
        }
    }

    .form-check {
        display: block;
        text-transform: capitalize;
    }
}

.review-section {
    .review_box {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0px 136px 54px rgba(0, 0, 0, 0.01), 0px 76px 46px rgba(0, 0, 0, 0.05), 0px 34px 34px rgba(0, 0, 0, 0.09), 0px 8px 19px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

        .title-top {
            padding: 20px 30px;
            background-color: $inner-bg;

            h5 {
                margin-bottom: 0;
                text-transform: capitalize;
                line-height: 1;
                font-weight: 600;
            }
        }

        .flight_detail {
            padding: 20px 30px;

            .logo-sec {
                img {
                    width: 60px;
                    margin-right: 10px;
                }

                .title {
                    text-transform: capitalize;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 600;
                }
            }

            .airport-part {
                display: flex;
                text-align: center;
                align-items: center;
                height: 100%;

                .airport-name {
                    h6 {
                        margin-bottom: 3px;
                        font-weight: 700;

                        span {
                            font-weight: 500;
                        }
                    }

                    p {
                        text-transform: capitalize;
                        margin-bottom: 0;
                        color: #7b7b7b;
                    }

                    &.arrival {
                        margin-left: auto;
                    }
                }

                .airport-progress {
                    width: 100%;
                    padding: 0 25px;
                    position: relative;
                    margin: 0 auto;

                    .stop {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 16px;
                        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                        width: 100%;
                    }

                    i {
                        /*color: rgba(black, 0.17);*/
                    }

                    /*&:after {
                        content: "";
                        position: absolute;
                        height: 1px;
                        width: 60%;
                        background-image: linear-gradient(90deg, var(--bs-info), var(--bs-info) 75%, transparent 75%, transparent 100%);
                        background-size: 5px 1px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        top: 50%;
                    }*/
                }
            }

            .duration {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                text-align: center;

                h6 {
                    margin-bottom: 3px;
                    font-weight: 400;
                    font-size: 14px;
                }

                p {
                    text-transform: capitalize;
                    margin-bottom: 0;
                    color: #7b7b7b;
                }
            }

            .boxes {
                h6 {
                    text-transform: capitalize;
                    font-weight: 600;
                }

                ul {
                    li {
                        display: block;
                        text-transform: capitalize;
                    }
                }

                .form-check {
                    .form-check-label {
                        text-transform: capitalize;
                    }

                    + .form-check {
                        margin-top: 5px;
                    }
                }

                + .boxes {
                    margin-top: 20px;
                }

                &:last-child {
                    ul {
                        li {
                            &:last-child {
                                margin-bottom: -2px;
                            }
                        }
                    }
                }
            }

            .form_flight {
                form {
                    h6 {
                        text-transform: capitalize;
                        font-weight: 600;
                    }

                    .form-group {
                        margin-bottom: 0;

                        label {
                            text-transform: capitalize;
                            margin-bottom: 2px;
                        }
                    }

                    + form {
                        margin-top: 20px;
                    }
                }
            }

            .summery_box {
                .table {
                    margin-bottom: 0;

                    .title {
                        height: 30px;
                        display: flex;
                        align-items: flex-end;
                        margin-top: 10px;

                        td {
                            font-weight: 600;
                            font-size: 18px;
                        }
                    }

                    th,
                    td {
                        padding: 0;
                    }

                    tr {
                        td {
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                            text-transform: capitalize;

                            &:nth-child(2) {
                                text-align: right;
                            }
                        }
                    }
                }

                .grand_total {
                    padding: 10px;
                    background-color: $inner-bg;
                    margin-top: 20px;

                    h5 {
                        margin-bottom: 0;
                        text-transform: capitalize;

                        span {
                            float: right;
                        }
                    }
                }
            }

            .promo-section {
                label {
                    font-size: 14px;
                    text-transform: capitalize;
                    font-weight: 700;
                }

                .promos {
                    margin-top: 20px;

                    .form-check {
                        display: flex;

                        + .form-check {
                            margin-top: 15px;
                        }
                    }

                    label {
                        display: block;
                        font-weight: 500;

                        &.title {
                            font-weight: 700;
                        }
                    }
                }
            }

            .addons-according {
                .card {
                    border: none;

                    .card-header {
                        padding: 0;
                        border: none;
                        background-color: white;

                        .btn {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            color: black;
                            text-transform: capitalize;
                            background-color: $inner-bg;
                            padding: 10px 5px;

                            img {
                                width: 24px;
                                margin-right: 12px;
                            }
                        }
                    }

                    .card-body {
                        padding: 20px 0 0;

                        &.order-menu-section {
                            border-left: none;
                        }
                    }

                    + .card {
                        border-top: 1px solid #ececec;
                        margin-top: 10px;
                    }

                    &:last-child {
                        .card-body {
                            padding-bottom: 0;
                        }
                    }
                }

                .seat-select {
                    position: relative;

                    .plane {
                        margin: 0 auto;
                        max-width: 300px;
                        border-bottom: 5px solid #ececec;

                        .cockpit {
                            height: 140px;
                            position: relative;
                            overflow: hidden;
                            text-align: center;
                            border-bottom: 5px solid #ececec;
                            display: flex;
                            align-items: center;

                            &:before {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 290px;
                                width: 100%;
                                border-radius: 50%;
                                border-right: 5px solid #ececec;
                                border-left: 5px solid #ececec;
                            }

                            img {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }

                        .exit {
                            position: relative;
                            height: 50px;

                            &:before,
                            &:after {
                                content: "EXIT";
                                font-size: 14px;
                                line-height: 18px;
                                padding: 0px 2px;
                                font-family: "Arial Narrow", Arial, sans-serif;
                                display: block;
                                position: absolute;
                                background: red;
                                color: white;
                                top: 50%;
                                transform: translate(0, -50%);
                            }

                            &:before {
                                left: 0;
                            }

                            &:after {
                                right: 0;
                            }
                        }

                        .fuselage {
                            border-right: 5px solid #ececec;
                            border-left: 5px solid #ececec;
                            overflow: hidden;
                            padding: 0 20px;
                        }

                        ol {
                            list-style: none;
                            padding: 0;
                            margin: 0;
                        }

                        .seats {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            width: 100%;
                        }

                        .seat {
                            display: flex;
                            flex: 0 0 14.28571428571429%;
                            padding: 5px;
                            position: relative;

                            &:nth-child(3) {
                                margin-right: 14.28571428571429%;
                            }

                            input[type="checkbox"] {
                                position: absolute;
                                opacity: 0;
                            }

                            input[type="checkbox"]:checked {
                                + label {
                                    background: #afafaf;
                                    -webkit-animation-name: rubberBand;
                                    animation-name: rubberBand;
                                    animation-duration: 300ms;
                                    animation-fill-mode: both;
                                }
                            }

                            input[type="checkbox"]:disabled {
                                + label {
                                    background: #dddddd;
                                    text-indent: -9999px;
                                    overflow: hidden;

                                    &:after {
                                        content: "X";
                                        text-indent: 0;
                                        position: absolute;
                                        top: 4px;
                                        left: 50%;
                                        transform: translate(-50%, 0%);
                                    }

                                    &:hover {
                                        box-shadow: none;
                                        cursor: not-allowed;
                                    }
                                }
                            }

                            label {
                                display: block;
                                position: relative;
                                width: 100%;
                                text-align: center;
                                font-size: 12px;
                                font-weight: bold;
                                line-height: 1.5rem;
                                padding: 4px 0;
                                background: rgba($theme-color1, 0.25);
                                border-radius: 5px;
                                animation-duration: 300ms;
                                animation-fill-mode: both;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    width: 75%;
                                    height: 75%;
                                    top: 1px;
                                    left: 50%;
                                    transform: translate(-50%, 0%);
                                    background: rgba(255, 255, 255, 0.4);
                                    border-radius: 3px;
                                }

                                &:hover {
                                    cursor: pointer;
                                    box-shadow: 0 0 0px 2px rgba($theme-color1, 0.7);
                                }
                            }
                        }

                        @-webkit-keyframes rubberBand {
                            0% {
                                -webkit-transform: scale3d(1, 1, 1);
                                transform: scale3d(1, 1, 1);
                            }

                            30% {
                                -webkit-transform: scale3d(1.25, 0.75, 1);
                                transform: scale3d(1.25, 0.75, 1);
                            }

                            40% {
                                -webkit-transform: scale3d(0.75, 1.25, 1);
                                transform: scale3d(0.75, 1.25, 1);
                            }

                            50% {
                                -webkit-transform: scale3d(1.15, 0.85, 1);
                                transform: scale3d(1.15, 0.85, 1);
                            }

                            65% {
                                -webkit-transform: scale3d(0.95, 1.05, 1);
                                transform: scale3d(0.95, 1.05, 1);
                            }

                            75% {
                                -webkit-transform: scale3d(1.05, 0.95, 1);
                                transform: scale3d(1.05, 0.95, 1);
                            }

                            100% {
                                -webkit-transform: scale3d(1, 1, 1);
                                transform: scale3d(1, 1, 1);
                            }
                        }

                        @keyframes rubberBand {
                            0% {
                                -webkit-transform: scale3d(1, 1, 1);
                                transform: scale3d(1, 1, 1);
                            }

                            30% {
                                -webkit-transform: scale3d(1.25, 0.75, 1);
                                transform: scale3d(1.25, 0.75, 1);
                            }

                            40% {
                                -webkit-transform: scale3d(0.75, 1.25, 1);
                                transform: scale3d(0.75, 1.25, 1);
                            }

                            50% {
                                -webkit-transform: scale3d(1.15, 0.85, 1);
                                transform: scale3d(1.15, 0.85, 1);
                            }

                            65% {
                                -webkit-transform: scale3d(0.95, 1.05, 1);
                                transform: scale3d(0.95, 1.05, 1);
                            }

                            75% {
                                -webkit-transform: scale3d(1.05, 0.95, 1);
                                transform: scale3d(1.05, 0.95, 1);
                            }

                            100% {
                                -webkit-transform: scale3d(1, 1, 1);
                                transform: scale3d(1, 1, 1);
                            }
                        }

                        .rubberBand {
                            -webkit-animation-name: rubberBand;
                            animation-name: rubberBand;
                        }
                    }

                    .seat-details {
                        position: absolute;
                        left: 0;
                        top: 5%;

                        ul {
                            li {
                                display: flex;
                                align-items: center;

                                .box {
                                    width: 25px;
                                    height: 25px;

                                    &.taken {
                                        background: rgba(#dddddd, 0.4);
                                    }

                                    &.selected {
                                        background: rgba(#afafaf, 0.4);
                                    }

                                    &.available {
                                        background: rgba($theme-color1, 0.15);
                                    }
                                }

                                h6 {
                                    margin-left: 15px;
                                    text-transform: capitalize;
                                    margin-bottom: 0;
                                }

                                + li {
                                    margin-top: 8px;
                                }
                            }
                        }
                    }
                }
            }

            &.payment-gateway {
                .card {
                    border: 1px solid rgba(0, 0, 0, 0.09);
                    border-radius: 0;

                    .card-header {
                        padding: 0;
                        border-bottom: none;
                        border-radius: 0;

                        .btn {
                            padding: 0;
                            width: 100%;
                            text-align: left;
                            text-decoration: none;

                            label {
                                margin-bottom: 0;
                                width: 100%;
                                color: rgba(black, 0.9);
                                padding: 10px 15px;
                                font-weight: 700;
                                text-transform: capitalize;
                                background-color: #f9f9f9;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }

                    .card-body {
                        border-top: 1px solid rgba(0, 0, 0, 0.09);
                    }

                    + .card {
                        margin-top: 10px;
                    }
                }

                form {
                    .form-control {
                        border-color: rgba(0, 0, 0, 0.09);
                    }

                    .form-group {
                        position: relative;

                        label {
                            text-transform: capitalize;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        }

                        img {
                            height: 23px;
                            position: absolute;
                            right: 10px;
                            bottom: 8px;
                        }
                    }

                    .payment-btn {
                        text-align: right;
                        margin-top: 20px;
                    }

                    &.wallet-section {
                        h6 {
                            text-transform: uppercase;
                        }

                        .row {
                            margin-left: 15px;
                            margin-top: 20px;

                            .form-check {
                                .form-check-label {
                                    margin-bottom: 10px;
                                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                }
                            }
                        }
                    }
                }
            }
        }

        + .review_box {
            margin-top: 20px;
        }
    }
}

.res-margin {
    .single-section {
        margin-top: 20px;
    }
}

.continue-btn {
    margin-top: 30px;
    text-align: center;
}

// booking/order sucess page
.success-section {
    overflow: hidden;

    .animation {
        max-width: 100%;
    }

    .success-detail {
        text-align: center;
        margin-top: 15px;

        img {
            margin-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
        }

        p {
            font-size: 16px;
            max-width: 48%;
            margin: 0 auto;
            color: rgba(0, 0, 0, 0.7);
            line-height: 30px;
            padding-top: 10px;
            text-transform: capitalize;
        }

        .btn {
            padding: 8px 20px;
            text-transform: capitalize;
            margin-top: 18px;
        }
    }
}

// contact us page
.contact_section {
    .contact_wrap {
        .title_bar {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            h4 {
                margin-bottom: 0;
                margin-left: 10px;
                text-transform: capitalize;
                color: black;
                font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 600;
            }

            i {
                color: $theme-color1;
                font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        .contact_content {
            ul {
                padding-left: 20px;

                li {
                    display: block;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: #848484;
                }
            }

            p {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                line-height: 1.5;
                margin-bottom: 0;
                color: #848484;
                margin-left: 20px;
            }
        }
    }

    &.contact_right {
        .row {
            > div {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.get-in-touch {
    h3 {
        text-transform: capitalize;
        margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
    }

    form {
        .form-control {
            border-radius: 0;
            font-size: 14px;
            line-height: 1.8;
            text-transform: capitalize;
            padding: 10px 18px;
        }

        .submit-btn {
            text-align: right;
            margin-top: 10px;
        }
    }
}

.contact-map {
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.contact-bottom {
    height: calc(180px + (350 - 180) * ((100vw - 320px) / (1920 - 320)));
}

// map css
.gm-style div[title] {
    opacity: 1 !important;
}

.gm-style div[title] img {
    display: block !important;
    height: 40px !important;
    width: auto !important;
}

.leaflet-marker-icon {
    height: 50px !important;
    width: auto !important;
}

.add-animation {
    animation: 1s linear infinite bounce-1;
}

.add-filter {
    filter: grayscale(1);
}

@keyframes bounce-1 {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-7px);
    }

    100% {
        transform: translateY(0);
    }
}

// coming soon //
.coming-soon {
    background-size: cover;
    height: 100vh;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .coming-soon-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100vh;
        width: 100%;

        > div {
            width: 100%;
        }

        .logo {
            margin-bottom: calc(14px + (25 - 14) * ((100vw - 320px) / (1920 - 320)));
        }

        h2 {
            color: white;
            font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
            margin-bottom: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
        }

        form {
            width: 50%;
            margin: 0 auto;

            label {
                color: white;
                font-size: 20px;
                text-transform: capitalize;
            }

            input {
                padding: 13px 25px;
                border-radius: 25px;
                font-size: 16px;

                &:focus {
                    box-shadow: none;
                    border: 1px solid #dddddd;
                }
            }

            .actions {
                margin-top: 20px;

                .btn {
                    padding: 14px 40px;
                    border-radius: 25px;
                }
            }
        }

        .timer {
            margin-bottom: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .object {
        position: absolute;
        animation: sway 2.4s infinite;
        animation-timing-function: ease-in-out;
        transform-origin: top;
        left: calc(10% + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
        height: 5%;
        z-index: 999;
        text-transform: uppercase;

        .object-shape {
            width: calc(60px + (100 - 60) * ((100vw - 320px) / (1920 - 320)));
            height: calc(60px + (100 - 60) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 50%;
            display: block;
            background-color: white;
            margin: 0 auto;
            position: relative;
            color: black;
            text-align: center;
            padding-top: calc(14px + (25 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
            box-sizing: border-box;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));

            span {
                font-size: calc(12px + (22 - 12) * ((100vw - 320px) / (1920 - 320)));
                color: black;
            }
        }

        .object-rope {
            height: 100%;
            width: 5px;
            background-color: white;
            content: "";
            display: block;
            margin-left: 50%;
        }
    }

    &.travel {
        background-color: rgba($theme-color1, 0.05);

        svg {
            position: absolute;
            top: 0;
            width: 1920px;
            height: 1600px;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 50%;
            transform: translateX(-50%);
        }

        .coming-soon-detail {
            position: absolute;
            align-items: flex-end;
            padding-bottom: 60px;
            z-index: 1;

            .logo {
                img {
                    filter: invert(1);
                }
            }

            h2 {
                color: black;
            }

            form {
                label {
                    color: black;
                }
            }
        }
    }

    &.ripple-effect {
        canvas {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
        }
    }
}

// typography page //
.typography_section {
    p {
        color: #777777;
    }

    .typography-box {
        margin-bottom: 30px;

        p {
            line-height: 22px;
        }

        .headings {
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 20px;
            background-color: rgba(#f7f7f7, 0.5);
            padding: 8px 12px;

            h3 {
                text-transform: capitalize;
                color: #2d2d2d;
                margin-bottom: 0;
                font-weight: 400;
                font-size: 20px;
            }

            span {
                color: #777777;

                code {
                    color: #777777;
                }
            }
        }

        .typo-content {
            &.heading_content {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-weight: 400;
                    color: black;
                    text-transform: uppercase;
                    line-height: 1;
                    margin-bottom: 10px;
                }
            }

            .sub-title {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-bottom: 5px;
                margin-bottom: 8px;
                font-size: 18px;
                color: $grey-darker;
                text-transform: capitalize;
            }

            &.input_button {
                input {
                    margin-bottom: 10px;
                }

                label {
                    font-size: 16px;
                    text-transform: capitalize;
                    padding-left: 5px;
                }
            }

            &.loader-typo {
                width: 100%;
                justify-content: center;
                display: flex;

                .pre-loader {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    background-color: $theme-color1;
                    border-radius: 50%;

                    &:after {
                        content: "";
                        position: absolute;
                        border-radius: 50%;
                        top: 50%;
                        left: 50%;
                        border: 0 solid white;
                        transform: translate(-50%, -50%);
                        animation: loading 1000ms ease-out forwards infinite;
                        transition: all 0.3s ease;
                    }
                }
            }

            .footer-social {
                margin-top: 0;
                justify-content: center;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    code {
        color: rgba($theme-color1, 0.6);
        background-color: #f7f7f7;
        padding: 3px;
        margin: 0 3px;
        border-radius: 2px;
    }
}

@keyframes sway {
    0% {
        transform: rotate(8deg);
    }

    50% {
        transform: rotate(-8deg);
    }

    100% {
        transform: rotate(8deg);
    }
}

// travel animation
@-webkit-keyframes rotate-right {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate-left {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(-360deg);
    }
}

@-webkit-keyframes hover {
    0% {
        -webkit-transform: translateY(0%);
    }

    50% {
        -webkit-transform: translateY(5%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}

@-webkit-keyframes pull {
    0% {
        -webkit-transform: scaleY(1);
    }

    40% {
        -webkit-transform: scaleY(1.01);
    }

    60% {
        -webkit-transform: scaleY(0.99);
    }

    80% {
        -webkit-transform: scaleY(1.01);
    }

    100% {
        -webkit-transform: scaleY(0.99);
    }

    80% {
        -webkit-transform: scaleY(1.01);
    }

    100% {
        -webkit-transform: scaleY(1);
    }
}

#airplane2,
#airplane1 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 200px 200px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-right 60s linear 0s infinite;
}

#countryObjects {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 200px 200px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-right 240s linear 0s infinite;
}

#floatingGlobe {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 200px 200px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-left 360s linear 0s infinite;
}

#globe {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: hover 0s linear 0s infinite;
}

#windmill {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 331px 201px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-right 2s linear 0s infinite;
}

#cloud1 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: hover 3s linear 1s infinite;
}

#cloud2 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: hover 3s linear 2s infinite;
}

#cloud3 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: hover 3s linear 3s infinite;
}

#circle1 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 200px 200px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-right 12s linear 0s infinite;
}

#circle2 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 200px 200px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-left 24s linear 0s infinite;
}

#circle3 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 200px 200px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-right 12s linear 0s infinite;
}

#circle4 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 200px 200px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-left 24s linear 0s infinite;
}

#circle5 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 200px 200px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-right 12s linear 0s infinite;
}

// faq section
.faq-tab {
    .nav-tabs {
        display: block;
        border-bottom: none;
        background-color: white;
        padding: 10px 0;
        box-shadow: 0 2px 4px 0 $box-shadow;

        .nav-item {
            display: block;

            .nav-link {
                text-transform: capitalize;
                color: black;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                border: none;
                transition: all 0.5s ease;
                border-radius: 0;
                font-weight: 600;

                &.active {
                    border: none;
                    border-right: 2px solid $theme-color1;
                    border-radius: 0;
                    color: $theme-color1;
                    transition: all 0.5s ease;
                }

                &:hover {
                    border: none;
                    border-right: 2px solid $theme-color1;
                    color: $theme-color1;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.dashboard-section #top-tabContent {
    padding: 0;
    box-shadow: none;
}

.faq-content {
    display: block;
    background-color: white;
    padding: 30px;
    box-shadow: 0 2px 4px 0 $box-shadow;

    .tab-pane {
        .card {
            margin-bottom: 25px;
            border-radius: 0;
            border: none;

            .card-header {
                border-bottom: none;
                background-color: transparent;
                padding: 0;

                h5 {
                    padding: 0;
                    width: 100%;
                    text-align: left;
                    color: black;
                    text-transform: capitalize;
                    font-weight: 700;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                }
            }

            .card-body {
                color: #444444;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                padding: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

// login & register
.account-sign-in {
    .title {
        margin-bottom: 20px;

        h3 {
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    form {
        .form-group {
            label {
                text-transform: capitalize;
                font-weight: 600;
            }

            .form-control {
                border-radius: 0;
            }
        }

        .button-bottom {
            .btn {
                box-shadow: none;
            }
        }
    }

    .divider {
        text-align: center;
        position: relative;
        margin: 20px 0;
        z-index: 1;

        h6 {
            display: inline-block;
            padding: 5px 15px;
            margin-bottom: 0;
            text-transform: uppercase;
            background-color: white;
        }

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #dddddd;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
        }
    }

    .login-with {
        h6 {
            text-transform: capitalize;
            margin-bottom: 1rem;
        }

        .login-social {
            .boxes {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background: white;
                box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05);
                padding: 15px 0;

                h6 {
                    margin-bottom: 0;
                    margin-left: 20px;
                    text-transform: capitalize;
                    font-weight: 600;
                }
            }
        }

        p {
            margin-top: 15px;
        }
    }
}

// dashboard
.dashboard-section {
    .faq-content {
        min-height: 700px;
    }

    .dashboard-sidebar {
        box-shadow: 0px 136px 54px rgba(0, 0, 0, 0.01), 0px 76px 46px rgba(0, 0, 0, 0.05), 0px 34px 34px rgba(0, 0, 0, 0.09), 0px 8px 19px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 12px;
        overflow: hidden;

        .profile-top {
            padding: 30px 0 15px 0;
            background-color: #fafafa;
            margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

            .profile-image {
                position: relative;

                img {
                    width: 130px;
                    margin: 0 auto;
                    display: block;
                    border-radius: 100%;
                }

                .profile-edit {
                    position: absolute;
                    bottom: 0;
                    right: 30%;
                    background-color: #ffffff;
                    padding: 7px 7px 3px 7px;
                    border-radius: 100%;
                    box-shadow: 2px 3px 5px 5px #f3f3f3;

                    svg {
                        stroke: $theme-color1;
                        height: 18px;
                        width: 18px;
                    }
                }
            }

            .profile-detail {
                text-align: center;
                margin-top: 15px;

                h5 {
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: 5px;
                }

                h6 {
                    color: #777777;
                    margin-bottom: 3px;
                }
            }
        }

        .faq-tab {
            .nav-tabs {
                box-shadow: none;
                padding: 0;
                padding-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .dashboard-box {
        .dashboard-title {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            h4 {
                text-transform: capitalize;
                font-weight: 700;
                margin-bottom: 0;
            }

            span {
                margin-left: 30px;
                background-color: $inner-bg;
                padding: 2px 16px;
                border-radius: 2px;
                text-transform: capitalize;
                color: $theme-color1;
                cursor: pointer;
            }
        }

        .dashboard-detail {
            margin-bottom: 20px;

            ul {
                li {
                    display: block;
                    margin-bottom: 10px;

                    .details {
                        display: flex;

                        h6 {
                            text-transform: capitalize;
                            margin-bottom: 0;
                            font-weight: 600;
                        }

                        span {
                            margin-left: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
                            border-radius: 2px;
                            text-transform: capitalize;
                            color: $theme-color1;
                            cursor: pointer;
                        }

                        .left {
                            width: 150px;
                            margin-right: 15px;
                        }

                        .right {
                            display: flex;
                            align-items: center;

                            h6 {
                                color: #4e4e4e;
                                font-weight: 500;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .booking-box {
                background-color: $inner-bg;
                padding: 15px 25px;
                display: flex;
                align-items: center;
                box-shadow: -1px 2px 8px #ddd;

                .date-box {
                    display: inline-block;
                    text-transform: capitalize;
                    width: 160px;

                    span {
                        line-height: 1.3;
                        display: block;
                        font-weight: 700;

                        &.day {
                            color: #adb0b8;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        }

                        &.date {
                            color: black;
                            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                        }

                        &.month {
                            color: $theme-color1;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        }
                    }
                }

                .detail-middle {
                    width: 50%;

                    .media {
                        align-items: center;

                        .icon {
                            i {
                                padding: 12px;
                                background-color: white;
                                border-radius: 100%;
                                box-shadow: -1px 2px 8px #ddd;
                            }
                        }

                        .media-body {
                            margin-left: 35px;

                            h6 {
                                text-transform: capitalize;
                                color: black;
                            }

                            p {
                                margin-bottom: 0;
                                color: $title-grey;
                                text-transform: capitalize;

                                span {
                                    font-weight: 500;
                                    color: #3c3c3c;
                                }
                            }
                        }
                    }
                }

                .detail-last {
                    margin-left: auto;

                    i {
                        color: black;
                    }

                    span {
                        font-size: 14px;
                        margin-left: 10px;
                        text-transform: capitalize;
                    }
                }
            }

            .card-payment {
                .payment-card {
                    width: 100%;
                    min-height: 183px;
                    padding: 30px 20px;
                    border-radius: 5px;
                    position: relative;
                    background-image: repeating-linear-gradient( 45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.2) 70%, rgba( 255, 255, 255, 0) 90%);

                    .card-details {
                        .card-number {
                            h3 {
                                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                                color: white;
                                font-weight: 600;
                            }
                        }

                        .valid-detail {
                            display: flex;
                            align-items: center;
                            margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

                            .title {
                                span {
                                    display: block;
                                    color: rgba(white, 0.7);
                                    text-transform: uppercase;
                                    font-size: 12px;
                                    line-height: 1.3;
                                }
                            }

                            .date {
                                h3 {
                                    margin-bottom: 0;
                                    color: white;
                                    margin-left: 15px;
                                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                                }
                            }

                            .primary {
                                margin-left: auto;

                                .badge {
                                    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                                }
                            }
                        }

                        .name-detail {
                            margin-top: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
                            display: flex;
                            align-items: center;

                            .name {
                                width: 100%;

                                h5 {
                                    color: white;
                                    text-transform: uppercase;
                                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                    margin-bottom: 0;
                                }
                            }

                            .card-img {
                                display: inline-block;
                                margin-left: auto;
                                text-align: right;

                                img {
                                    width: 40px;
                                }
                            }
                        }
                    }

                    .edit-card {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        top: 0;
                        left: 0;
                        background-color: rgba(black, 0.8);
                        border-radius: 5px;
                        opacity: 0;
                        transition: all 0.5s ease;

                        a {
                            padding: 0 10px;
                            text-transform: capitalize;
                            color: white;
                        }
                    }

                    &.master {
                        background-color: #4b77a0;
                    }

                    &.visa {
                        background-color: #777876;
                    }

                    &.american-express {
                        background-color: #86b8cf;

                        .card-details {
                            .card-number {
                                h3 {
                                    color: black;
                                }
                            }

                            .valid-detail {
                                .title {
                                    span {
                                        color: rgba(black, 0.7);
                                    }
                                }

                                .date {
                                    h3 {
                                        color: black;
                                    }
                                }
                            }

                            .name-detail {
                                .name {
                                    h5 {
                                        color: black;
                                    }
                                }
                            }
                        }
                    }

                    &.add-card {
                        background-color: $inner-bg;
                        cursor: pointer;
                        padding: 0;

                        .card-details {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            width: 100%;
                            height: 183px;

                            h5 {
                                margin-bottom: 0;
                                text-transform: capitalize;
                            }
                        }
                    }

                    &:hover {
                        .edit-card {
                            opacity: 1;
                            transition: all 0.5s ease;
                        }
                    }
                }

                .edit-card-mobile {
                    display: none;
                }

                > div {
                    .payment-card {
                        margin-top: 30px;
                    }

                    &:nth-child(-n+3) {
                        .payment-card {
                            margin-top: 0;
                        }
                    }
                }
            }

            .delete-section {
                p {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: rgba(0, 0, 0, 0.64);
                    text-transform: capitalize;
                    line-height: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));

                    .text-bold {
                        font-weight: 700;
                        color: rgba(black, 0.8);
                    }
                }

                .btn {
                    padding: 7px 15px;
                    text-transform: capitalize;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .special-section {
            .special-box {
                .special-img {
                    .top-icon {
                        i {
                            padding: 6px 8px;
                        }
                    }
                }
            }
        }

        + .dashboard-box {
            margin-top: 30px;
        }
    }

    .dashboard-main {
        .dashboard-intro {
            background-color: $inner-bg;
            padding: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 5px;
            margin-bottom: 25px;

            h5 {
                text-transform: capitalize;
                font-weight: 700;
            }

            p {
                margin-bottom: 0;
                color: rgba(black, 0.8);
                text-transform: capitalize;
                letter-spacing: 0.08em;
            }

            .complete-profile {
                margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

                .complete-box {
                    display: flex;
                    align-items: center;
                    background-color: white;
                    padding: 15px;
                    text-align: center;
                    justify-content: center;
                    border-radius: 5px;
                    box-shadow: 0 2px 4px 0 rgba($box-shadow, 0.22);

                    i {
                        margin-right: 12px;
                        color: green;
                        font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                    }

                    h6 {
                        margin-bottom: 0;
                        text-transform: capitalize;
                        font-weight: 600;
                        font-size: 14px;
                    }

                    &.not-complete {
                        i {
                            color: red;
                        }
                    }
                }
            }
        }

        .counter-section {
            .counter-box {
                background-color: $inner-bg;
                padding: 15px;
                text-align: center;

                img {
                    width: 35px;
                    margin-bottom: 15px;
                }

                h3 {
                    margin-bottom: 0;
                    font-weight: 600;
                }

                h5 {
                    color: rgba(0, 0, 0, 0.61);
                    margin-bottom: -5px;
                    text-transform: capitalize;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    margin-top: 5px;
                }
            }
        }

        .dashboard-info {
            margin-top: 25px;
            position: relative;

            #chart,
            .chart-box {
                padding-top: 20px;
                padding-left: 10px;
                background: $inner-bg;
            }

            select.flat-select {
                appearance: none;
                background: #008ffb url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='60px' height='60px'><polyline fill='white' points='46.139,15.518 25.166,36.49 4.193,15.519'/></svg>") no-repeat scroll right 2px top 9px / 16px 16px;
                border: 0 none;
                border-radius: 3px;
                color: white;
                font-size: 16px;
                font-weight: bold;
                outline: 0 none;
                height: 33px;
                padding: 5px 20px 5px 10px;
                text-align: center;
                text-indent: 0.01px;
                text-overflow: "";
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                transition: all 0.3s ease 0s;
                width: auto;
            }

            select.flat-select:focus,
            select.flat-select:hover {
                border: 0;
                outline: 0;
            }

            .apexcharts-canvas {
                margin: 0 auto;
            }

            path#apexcharts-radialbarTrack-0 {
                stroke: rgba(251, 96, 122, 0.08);
            }

            path#apexcharts-radialbarTrack-1 {
                stroke: rgba(85, 169, 248, 0.08);
            }

            path#apexcharts-radialbarTrack-2 {
                stroke: rgba(162, 100, 255, 0.08);
            }

            .detail-left {
                position: absolute;

                ul {
                    display: block;

                    li {
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        font-weight: 600;

                        span {
                            width: 14px;
                            height: 5px;
                            display: block;
                            border-radius: 5px;
                            margin-right: 5px;

                            &.completed {
                                background-color: #fa4962;
                            }

                            &.upcoming {
                                background-color: #379cf9;
                            }

                            &.cancelled {
                                background-color: #a264ff;
                            }
                        }
                    }
                }
            }

            .activity-box {
                padding: 20px 10px;
                background: $inner-bg;

                h6 {
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: 15px;
                }

                ul {
                    li {
                        display: block;
                        background-color: white;
                        padding: 8px 15px;
                        text-transform: capitalize;
                        position: relative;

                        i {
                            margin-right: 6px;
                        }

                        span {
                            float: right;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            height: 100%;
                            width: 2px;
                            background-color: red;
                            top: 0;
                        }

                        &.blue-line {
                            &:after {
                                background-color: #03a9f4;
                            }
                        }

                        &.yellow-line {
                            &:after {
                                background-color: #ffc107;
                            }
                        }

                        + li {
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
}

.edit-profile-modal {
    .btn {
        padding: 0.375rem 0.75rem;
    }

    .modal-title {
        text-transform: capitalize;
        font-weight: 700;
    }

    .modal-body {
        label {
            text-transform: capitalize;
        }
    }
}

.modal {
    .modal-header {
        /*padding:20px 30px;*/
    }

    .modal-body {
        /*padding:20px 30px;*/
    }

    .modal-footer {
        /*padding:20px 30px;*/
    }
}

/*=====================
22. Portfolio css
==========================*/

.portfolio-section {
    .filter-button-group {
        text-align: center;

        ul {
            li {
                padding: 7px 17px;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                cursor: pointer;
                transition: all 0.5s ease;
                font-weight: 700;
                margin: 0 10px;
                text-transform: capitalize;
                background-color: $inner-bg;

                &.active {
                    background-color: $theme-color1;
                    color: white;
                }
            }
        }
    }

    .grid {
        .grid-item {
            margin-top: 30px;

            .overlay {
                overflow: hidden;
                margin: -1px;
                transition: all 0.5s ease;

                .portfolio-image {
                    transition: all 0.5s ease;
                    cursor: pointer;
                }

                .portfolio-text {
                    margin-top: 10px;

                    h3 {
                        font-weight: 600;
                        margin-bottom: 5px;
                        text-transform: capitalize;
                        font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                    }

                    h6 {
                        margin-bottom: 0;
                        text-transform: capitalize;
                        font-size: 14px;
                        color: grey;
                    }
                }

                &:hover {
                    .portfolio-image {
                        transform: scale(1.02);
                        transition: all 0.5s ease;
                    }
                }
            }

            a {
                cursor: zoom-in;
            }
        }

        &.blog-masonry {
            .grid-item {
                a {
                    cursor: pointer;
                }

                .overlay {
                    overflow: visible;

                    .blog-details {
                        padding-top: 20px;

                        h6 {
                            color: $grey-13;
                            text-transform: capitalize;
                            font-size: 14px;
                            font-weight: 700;
                            margin-bottom: 10px;

                            i {
                                color: rgba(35, 0, 0, 0.24);
                                margin-right: 12px;
                            }
                        }

                        h5 {
                            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                            color: $grey-3;
                            font-weight: 700;
                            line-height: 1.5;
                            margin-bottom: 5px;
                            transition: all 0.5s ease;
                        }

                        p {
                            font-size: 14px;
                            color: $grey-13;
                            line-height: 1.5;
                            margin-bottom: -3px;
                        }

                        .link {
                            margin-bottom: 0;
                            margin-top: 15px;

                            a {
                                color: rgba(0, 0, 0, 0.74);
                            }
                        }
                    }

                    &:hover {
                        .portfolio-image {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }

    .isotopeSelector {
        float: left;
        width: 100%;
        position: relative;
        margin-top: 30px;

        img {
            width: 100%;
            height: auto;
        }
    }

    &.creative-section {
        .grid {
            .grid-item {
                margin-top: 0;
            }
        }

        .filter-button-group {
            margin-bottom: 40px;
        }
    }
}

.effect-cls {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        background: url(../images/effect.png);
        bottom: -12px;
        width: 100%;
        height: 110px;
        left: 0;
        z-index: 1;
    }

    &.breadcrumb-section {
        height: calc(300px + (580 - 300) * ((100vw - 320px) / (1920 - 320)));
    }
}

.effect-cls-up {
    &:before {
        content: "";
        position: absolute;
        background: url(../images/effect.png);
        top: -1px;
        width: 100%;
        height: 85px;
        z-index: 1;
        background-repeat: no-repeat;
        transform: rotate(180deg);
    }
}

.parallax-section {
    .full_banner {
        height: 93vh;
        background-attachment: fixed;
    }
}

.center-slide {
    margin-bottom: -3px;

    .image-center {
        height: 93vh;

        .center-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 93vh;
            text-align: center;
            position: relative;
            z-index: 1;

            h3 {
                font-weight: 600;
                margin-bottom: 5px;
                text-transform: capitalize;
            }

            h6 {
                margin-bottom: 0;
                text-transform: capitalize;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(white, 0.65);
                z-index: -1;
            }
        }
    }

    .slick-slide {
        .image-center {
            .center-content {
                opacity: 0;
                transition: all 0.5s ease;
            }
        }

        &.slick-center {
            .image-center {
                .center-content {
                    opacity: 1;
                    transition: all 0.5s ease;

                    h3 {
                        animation: fadeInLeft 0.4s ease;
                    }

                    h6 {
                        animation: fadeInRight 0.4s ease;
                        color: gray;
                        padding: 10px 20px;
                        background-color: #ffffff;
                    }
                }
            }
        }
    }
}

.portfolio-creative {
    &.odd-even-con {
        .container {
            .row {
                .content-sec {
                    text-align: left;
                    padding-left: 60px;

                    h3 {
                        font-size: 200%;
                    }

                    p {
                        max-width: 80%;
                        margin: 0;
                        font-size: 16px;
                        line-height: 1.5;
                    }
                }

                &:nth-child(even) {
                    .content-sec {
                        text-align: right;
                        padding-left: unset;
                        padding-right: 60px;
                    }

                    p {
                        margin-right: 0;
                        margin-left: auto;
                    }
                }
            }
        }
    }

    .content-sec {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;

        &.center-con {
            p {
                max-width: 80%;
                font-size: 16px;
                line-height: 1.4;
            }

            h3 {
                font-size: 175%;
            }
        }

        h3 {
            text-transform: capitalize;
            font-weight: 700;
            position: relative;
        }

        p {
            color: #888888;
            max-width: 70%;
            margin: 0 auto;
        }

        .btn {
            text-transform: capitalize;
            padding: 6px 14px;
            margin-top: 25px;
        }
    }
}


/*=====================
23. Blog pages css
==========================*/

.blog-sidebar {
    .blog-wrapper {
        margin-bottom: 25px;

        .search-bar {
            position: relative;

            input {
                width: 100%;
                border: none;
                padding: 14px 14px 14px 50px;
                background-color: #f7f7f7;
            }

            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 14px;
                color: $theme-color1;

                &:after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 12px;
                    background-color: rgba($theme-color1, 0.5);
                    right: -10px;
                    top: 2px;
                }
            }
        }

        .sidebar-title {
            h5 {
                text-transform: capitalize;
                font-weight: 700;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: 20px;
            }
        }

        .sidebar-content {
            .sidebar-list {
                li {
                    display: block;
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    position: relative;

                    a {
                        color: black;
                    }

                    i {
                        margin-right: 15px;
                        color: gray;
                    }

                    &:first-child {
                        padding-top: 0;
                    }
                }
            }

            .blog-post {

                li {
                    margin-bottom: 20px;

                    .media {
                        h6 {
                            font-weight: 600;
                        }

                        img {
                            width: 100px;
                            margin-right: 15px;
                        }

                        .media-body {
                            p {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .tags {
                margin-top: -8px;

                li {
                    padding: 4px 10px;
                    background-color: rgba($inner-bg, 0.8);
                    margin: 8px 8px 0 0;
                    border-radius: 4px;
                    text-transform: capitalize;

                    a {
                        color: black;
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.blog-inner {
    &.blog_section {
        overflow: visible;

        .blog-wrap {
            border-radius: 0;
            box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.1);

            .blog-image {
                .blog-label {
                    border-radius: 0;
                    padding: 8px 10px;

                    h3 {
                        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                    }

                    h6 {
                        font-size: 13px;
                    }
                }
            }

            .blog-details {
                padding: 20px;

                h6 {
                    margin-bottom: 5px;

                    i {
                        color: rgba(35, 0, 0, 0.24);
                    }
                }

                h5 {
                    margin-bottom: 10px;
                }

                .link {
                    margin-bottom: 0;
                    margin-top: 15px;

                    a {
                        color: rgba(0, 0, 0, 0.74);
                    }
                }
            }
        }

        .blog-list {
            .blog-wrap {
                display: flex;

                .blog-image {
                    width: 30%;
                }

                .blog-details {
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    h6 {
                        i {
                            color: rgba(35, 0, 0, 0.24);
                        }
                    }
                }
            }
        }
    }
}

.blog-single-detail {
    .top-image {
        position: relative;

        .video {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            i {
                font-size: calc(25px + (40 - 25) * ((100vw - 300px) / (1920 - 300)));
                color: white;
                transition: all 0.5s ease;
            }
        }

        &:hover {
            .video {
                i {
                    color: $theme-color1;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .title-part {
        .post-detail {
            margin-top: calc(10px + (30 - 10) * ((100vw - 300px) / (1920 - 300)));

            li {
                font-weight: 600;
                color: #777777;

                + li {
                    padding-left: 15px;
                    margin-left: 15px;
                    border-left: 1px solid #ddd;
                }
            }
        }

        h3 {
            margin-bottom: calc(7px + (15 - 7) * ((100vw - 320px) / (1920 - 320)));
            margin-top: 5px;
            text-transform: capitalize;
            font-weight: 600;
            font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .detail-part {
        p {
            font-weight: 400;
            color: #777;
            margin-bottom: calc(10px + (25 - 10) * ((100vw - 300px) / (1920 - 300)));
            line-height: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300)));
            font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1920 - 300)));
        }
    }

    .comment-section {
        .comment {
            text-transform: capitalize;
            margin-bottom: 20px;
        }

        .comment-wrapper {
            .comment-box {
                .media {
                    img {
                        width: calc(60px + (70 - 60) * ((100vw - 320px) / (1920 - 320)));
                        border-radius: 100%;
                    }

                    .media-body {
                        margin-left: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320)));

                        .title {
                            display: flex;
                            padding-bottom: 10px;
                            margin-bottom: 10px;
                            border-bottom: 1px solid #dddddd;

                            .comment-user,
                            .comment-date {
                                display: flex;

                                h6 {
                                    color: #484848;
                                    text-transform: capitalize;
                                    font-weight: 500;
                                    margin-bottom: 0;
                                    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                                }

                                i {
                                    color: rgba(0, 0, 0, 0.5);
                                    margin-right: 7px;
                                }
                            }

                            .comment-date {
                                margin-left: 15px;
                            }
                        }

                        .comment-detail {
                            p {
                                margin-bottom: 8px;
                                color: $grey-13;
                                line-height: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
                            }
                        }

                        .reply-btn {
                            text-align: right;

                            a {
                                color: #555555;
                                text-transform: capitalize;
                            }
                        }
                    }

                    &.inner-comment {
                        margin-left: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
                        margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
                    }
                }

                + .comment-box {
                    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
                }
            }
        }
    }

    .leave-comment {
        margin-top: 30px;

        .comment {
            text-transform: capitalize;
            margin-bottom: 20px;
        }

        .form-control {
            border: 0;
            border-radius: 0;
            background-color: #f7f7f7;
            font-size: 14px;
            line-height: 1.8;
            text-transform: capitalize;
            padding: 10px 18px;
        }

        .submit-btn {
            text-align: right;
            margin-top: 10px;

            .btn {
                text-transform: capitalize;
                padding: 5px 10px;
            }
        }
    }
}


/*=====================
24. Footer css
==========================*/

footer {
    .footer {
        background-color: $footer-bg;

        .footer-space {
            padding-left: 50px;
        }

        .footer-title {
            &.mobile-title {
                display: none;
            }

            h5 {
                color: white;
                text-transform: capitalize;
                margin-bottom: 15px;
                font-weight: 700;
                position: relative;
            }

            .according-menu {
                position: absolute;
                right: 15px;
                top: 2px;
                display: none;
            }
        }

        .footer-content {
            .contact-detail {
                .footer-logo {
                    margin-bottom: 35px;
                }

                p {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: $footer-color;
                    line-height: 1.4;
                    font-weight: 700;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 40px;
                }

                .contact-list {
                    li {
                        color: $footer-color;
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        margin-bottom: 12px;
                        text-transform: capitalize;
                        display: block;

                        i {
                            margin-right: 5px;
                            font-size: 12px;
                        }

                        &:last-child {
                            margin-bottom: -3px;
                        }
                    }
                }
            }

            .footer-links {
                ul {
                    li {
                        display: block;
                        margin-bottom: 7px;

                        a {
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                            text-transform: capitalize;
                            color: $footer-color;
                            font-weight: 400;
                            transition: all 0.5s ease;

                            &:hover {
                                color: white;
                                transition: all 0.5s ease;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .footer-map {
                iframe {
                    width: 330px;
                    height: 200px;
                }
            }

            .footer-blog {
                .media {
                    .img-part {
                        overflow: hidden;
                    }

                    .media-body {
                        margin-left: 25px;

                        h5 {
                            color: white;
                            text-transform: capitalize;
                            font-weight: 700;
                        }

                        p {
                            color: $footer-color;
                            font-weight: 600;
                            display: block;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-bottom: 0;
                        }
                    }

                    + .media {
                        margin-top: 18px;
                    }
                }
            }

            .footer-place {
                .row {
                    > div {
                        &:nth-last-child(-n+3) {
                            margin-top: 25px;
                        }
                    }
                }

                .place {
                    overflow: hidden;
                    position: relative;

                    .overlay {
                        opacity: 0;
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(black, 0.4);
                        transform: scale(0);
                        transition: all 0.5s ease;

                        h6 {
                            color: white;
                            text-transform: capitalize;
                            font-weight: 700;
                        }
                    }

                    &:hover {
                        .overlay {
                            opacity: 1;
                            transform: scale(1);
                            transition: all 0.5s ease;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .sub-footer {
        background-color: $footer-bottom;
        padding: 12px 0;

        .footer-social {
            ul {
                li {
                    margin-right: 35px;

                    a {
                        i {
                            color: $footer-social;
                            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                            transition: all 0.5s ease;
                        }

                        &:hover {
                            i {
                                color: rgba(white, 0.5);
                                transition: all 0.5s ease;
                            }
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .copy-right {
            p {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                text-align: right;
                margin-bottom: 0;
                margin-top: 3px;
                color: $footer-social;
                text-transform: capitalize;
                font-weight: 700;

                i {
                    color: $red-heart;
                    font-size: 13px;
                }
            }
        }
    }

    &.footer-light {
        .footer {
            background-color: rgba($theme-color1, 0.15);

            .footer-content {
                .contact-detail {
                    p {
                        color: rgba(black, 0.6);
                    }

                    .contact-list {
                        li {
                            color: black;
                        }
                    }

                    .footer-logo {
                        img {
                            filter: invert(1);
                        }
                    }
                }

                .footer-links {
                    ul {
                        li {
                            a {
                                color: black;
                            }
                        }
                    }
                }

                .footer-blog {
                    .media {
                        .media-body {
                            h5 {
                                color: black;
                            }

                            p {
                                color: rgba(black, 0.6);
                            }
                        }
                    }
                }
            }

            .footer-title {
                h5 {
                    color: black;
                }
            }
        }

        .sub-footer {
            background-color: rgba($theme-color1, 0.3);
        }
    }

    &.footer-bg {
        position: relative;
        background-blend-mode: overlay;
        background-color: rgba($theme-color1, 0.5);

        .footer {
            background-color: rgba(27, 27, 27, 0.1411764705882353);

            .footer-content {
                .contact-detail {
                    p {
                        color: white;
                    }

                    .contact-list {
                        li {
                            color: white;
                        }
                    }
                }

                .footer-links {
                    ul {
                        li {
                            a {
                                color: white;
                            }
                        }
                    }
                }

                .footer-blog {
                    .media {
                        .media-body {
                            p {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}


/*=====================
25. Loader css
==========================*/

.loader-wrapper {
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: block;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 12;
    top: 0;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.5);
        width: 200px;
        height: 150px;
    }

    &.loader-text {
        .loader {
            width: auto;
            height: auto;
        }
    }

    &.inner-loader {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $inner-bg;

        .loader {
            top: 25%;
            width: 100%;
            height: 100%;
            display: flex;
            margin-top: 9%;
            justify-content: center;
        }
    }

    &.food-loader {
        .loader {
            width: auto;
            height: auto;
        }
    }

    &.img-gif {
        display: flex;

        img {
            width: 20%;
        }
    }
}

.loader-section {
    position: relative;
    overflow: hidden;
}

.typewriter h1 {
    color: black;
    overflow: hidden;
    border-right: 0.15em solid $theme-color1;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.15em;
    animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
    }

    50% {
        border-color: $theme-color1;
    }
}

// flight loader
$c-loader-front: #cee4e7;

@mixin classPrefix($className) {
    [class^="#{$className}"],
    [class*=" #{$className}"] {
        @content;
    }
}

.animation {
    position: relative;
    width: 100%;
    height: 20rem;
    max-width: 55rem;
    background-color: $inner-bg;

    @include classPrefix("animation__") {
        position: absolute;
    }

    &__plane {
        position: absolute;
        z-index: 1;
        top: calc(40% - (62px / 2));
        left: calc(50% - (272px / 2));
        width: 17rem;
        height: 6.2rem;
        animation: 2s ease-in-out takeOff, 5s ease-in-out infinite alternate flight 2s;

        &--shadow {
            bottom: 1rem;
            left: calc(54% - (8rem / 2));
            width: 8rem;
            height: 1rem;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.15);
            animation: 2s ease-in-out takeOffShadow, 5s ease-in-out infinite alternate flightShadow 2s;
        }
    }

    &__cloud {
        &--front {
            z-index: 2;
            top: 50%;
            left: 20%;
            width: 8.5rem;
            height: 3rem;
            fill: $c-loader-front;
            animation: 4s linear infinite cloudFront;
        }

        &--middle {
            top: 22%;
            left: 75%;
            width: 6rem;
            height: 3rem;
            fill: $c-loader-front;
            animation: 5s linear infinite cloudMiddle;
        }

        &--back {
            top: 6%;
            left: 34%;
            fill: $c-loader-front;
            animation: 8s linear infinite cloudBack;
        }
    }
}

@keyframes loader {
    0% {
        left: -100%;
    }

    100% {
        left: 100%;
    }
}

@keyframes takeOff {
    0% {
        transform: translate(-220%, 110%);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes takeOffShadow {
    0% {
        transform: translate(-440%, 110%);
        opacity: 1;
    }

    100% {
        transform: translate(0, 0);
        opacity: 0.8;
    }
}

@keyframes flight {
    0% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(0, 10%);
    }

    75% {
        transform: translate(0, -10%);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes flightShadow {
    0% {
        transform: scale(0.8);
        opacity: 0.8;
    }

    25% {
        transform: scale(0.9);
        opacity: 1;
    }

    75% {
        transform: scale(1.1);
        opacity: 0.6;
    }

    100% {
        transform: scale(0.8);
        opacity: 0.8;
    }
}

@keyframes cloudFront {
    0% {
        transform: translate(520%, 0);
    }

    100% {
        transform: translate(-600%, 0);
    }
}

@keyframes cloudMiddle {
    0% {
        transform: translate(230%, 0);
    }

    100% {
        transform: translate(-900%, 0);
    }
}

@keyframes cloudBack {
    0% {
        transform: translate(910%, 0);
    }

    100% {
        transform: translate(-1000%, 0);
    }
}

%loader-dark {
    background: linear-gradient(90deg, rgba(212, 212, 212, 0.3) 8%, #e4e4e4 18%, rgba(212, 212, 212, 0.3) 33%);
    background-size: 800px 104px;
    animation: skeleton-loader 2s infinite linear;
}

// skeleton loader
.skeleton_loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: block;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;

    .dark-bg {
        background-color: #444444 !important;
    }

    .breadcrumb-section {
        background-color: $bg-loader;
    }

    .home_section {
        background-color: $bg-loader;
    }

    .blog-sidebar {
        .blog-wrapper {
            .search-bar {
                width: 100%;
                height: 49px;
                @extend %loader-dark;
            }

            .sidebar-title {
                h5 {
                    width: 114px;
                    height: 21px;
                    @extend %loader-dark;
                }
            }

            .sidebar-content {
                .sidebar-list {
                    li {
                        width: 100%;
                        height: 22px;
                        border: none;
                        @extend %loader-dark;

                        + li {
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }
                    }
                }

                .blog-post {
                    li {
                        .media {
                            .ldr-img {
                                width: 100px;
                                height: 72px;
                                margin-right: 15px;
                                background-color: $bg-loader;
                            }

                            .media-body {
                                h6 {
                                    width: 93px;
                                    height: 18px;
                                    @extend %loader-dark;
                                }

                                p {
                                    width: 65px;
                                    height: 18px;
                                    @extend %loader-dark;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .blog_section {
        .blog-wrap {
            .blog-image {
                width: 100%;
                height: calc(190px + (278 - 190) * ((100vw - 320px) / (1920 - 320)));
                background-color: $bg-loader;
            }
        }
    }

    .blog-inner {
        &.blog_section {
            .blog-wrap {
                .blog-image {
                    .blog-label {
                        width: 48px;
                        height: 55px;
                    }
                }

                .blog-details {
                    h6 {
                        width: 138px;
                        height: 16px;
                        @extend %loader-dark;
                    }

                    h5 {
                        height: 25px;
                        width: 93%;
                        @extend %loader-dark;
                    }

                    p {
                        span {
                            width: 89%;
                            height: 16px;
                            display: block;
                            @extend %loader-dark;

                            + span {
                                margin-top: 5px;
                            }

                            &:nth-child(2) {
                                width: 85%;
                            }

                            &:nth-child(3) {
                                width: 15%;
                            }
                        }
                    }
                }
            }

            .blog-list {
                .blog-wrap {
                    .blog-details {
                        justify-content: unset;

                        > div {
                            width: 100%;
                        }

                        &.text-md-right {
                            h5,
                            h6,
                            span,
                            p {
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    .blog-single-detail {
        .top-image {
            height: calc(90px + (280 - 90) * ((100vw - 320px) / (1920 - 320)));
            background-color: $bg-loader;
            width: 100%;
        }

        .title-part {
            h4 {
                width: 80px;
                height: 20px;
                @extend %loader-dark;
            }

            h5 {
                width: 62%;
                height: 20px;
                @extend %loader-dark;
            }

            p {
                width: 100%;
                height: 20px;
                margin-top: 8px;
                @extend %loader-dark;
            }
        }

        .comment-section {
            .comment {
                width: 120px;
                height: 30px;
                @extend %loader-dark;
            }

            .comment-wrapper {
                .comment-box {
                    .media {
                        .ldr-img {
                            width: calc(60px + (70 - 60) * ((100vw - 320px) / (1920 - 320)));
                            height: calc(60px + (70 - 60) * ((100vw - 320px) / (1920 - 320)));
                            background-color: $bg-loader;
                            border-radius: 100%;
                        }

                        .media-body {
                            .title {
                                .comment-user,
                                .comment-date {
                                    width: 78px;
                                    height: 16px;
                                    @extend %loader-dark;
                                }
                            }

                            .comment-detail {
                                p {
                                    span {
                                        width: 89%;
                                        height: 16px;
                                        display: block;
                                        @extend %loader-dark;

                                        + span {
                                            margin-top: 5px;
                                        }

                                        &:nth-child(2) {
                                            width: 85%;
                                        }

                                        &:nth-child(3) {
                                            width: 15%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .flight-search {
        .responsive-detail {
            .destination {
                span {
                    width: 20%;
                    height: 22px;
                    display: block;
                    @extend %loader-dark;
                }
            }

            .details {
                span {
                    width: 31%;
                    height: 20px;
                    display: block;
                    margin-top: 10px;
                    @extend %loader-dark;
                }
            }

            .modify-search {
                .ldr-btn {
                    width: 106px;
                    height: 30px;
                    background-color: $bg-loader;
                }
            }
        }

        .flight-search-detail {
            .form-group {
                label {
                    width: 105px;
                    height: 20px;
                    @extend %loader-dark;
                }

                .form-control {
                    width: 100%;
                    height: 38px;
                    background-color: $bg-loader;
                    border: none;
                }
            }

            .search-col {
                display: flex;
                align-items: flex-end;

                .form-group {
                    width: 100%;
                }
            }
        }
    }

    .left-sidebar {
        .search-bar {
            input {
                height: 49px;
                width: 100%;
                background-color: $bg-loader;
            }
        }

        .middle-part {
            .section-title {
                h5 {
                    width: 140px;
                    height: 22px;
                    @extend %loader-dark;
                }
            }

            .filter-block {
                .collapse-block-title {
                    width: 70%;
                    height: 16px;
                    @extend %loader-dark;

                    &:after {
                        display: none;
                    }
                }

                .form-check-label {
                    width: 45%;
                    height: 21px;
                    @extend %loader-dark;
                }

                .collection-brand-filter {
                    .collection-filter-checkbox {
                        &:nth-child(2),
                        &:nth-child(5) {
                            .form-check-label {
                                width: 60%;
                            }
                        }

                        &:nth-child(3),
                        &:nth-child(6) {
                            .form-check-label {
                                width: 70%;
                            }
                        }
                    }
                }
            }
        }
    }

    .flight-detail-sec {
        &.cab-detail-sec {
            .detail-bar {
                .detail-wrap {
                    .logo-sec {
                        .title {
                            width: 130px;
                            height: 20px;
                            margin-left: auto;
                            margin-right: auto;
                            @extend %loader-dark;
                        }

                        .ldr-img {
                            width: calc(90px + (160 - 90) * ((100vw - 767px) / (1920 - 767)));
                            margin-bottom: 10px;
                            margin-left: auto;
                            margin-right: auto;
                            height: 56px;
                            background-color: $bg-loader;
                        }

                        label {
                            width: 48px;
                            height: 20px;
                            margin-top: 6px;
                            @extend %loader-dark;
                        }
                    }

                    .price {
                        h6 {
                            width: 88px;
                            height: 16px;
                            margin-left: auto;
                            margin-right: auto;
                            @extend %loader-dark;
                        }
                    }

                    .car-details {
                        ul {
                            li {
                                width: 96px;
                                height: 20px;
                                @extend %loader-dark;
                            }
                        }
                    }
                }
            }
        }

        .detail-bar {
            .detail-wrap {
                .logo-sec {
                    .ldr-img {
                        width: 50px;
                        height: 40px;
                        margin-left: auto;
                        margin-right: auto;
                        @extend %loader-dark;
                    }

                    .title {
                        width: 38%;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 6px;
                        height: 20px;
                        @extend %loader-dark;
                    }
                }

                .price {
                    span {
                        width: 86px;
                        height: 16px;
                        margin-top: 6px;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                        @extend %loader-dark;
                    }

                    h4 {
                        width: 65px;
                        height: 24px;
                        margin-left: auto;
                        margin-right: auto;
                        @extend %loader-dark;
                    }
                }

                .book-flight {
                    .ldr-btn {
                        width: 110px;
                        height: 32px;
                        @extend %loader-dark;
                    }

                    .ldr-btn {
                        width: 110px;
                        height: 32px;
                        margin-left: auto;
                        margin-right: auto;
                        background-color: $bg-loader;
                    }
                }

                .airport-part {
                    .airport-name {
                        h4 {
                            width: 45px;
                            height: 20px;
                            @extend %loader-dark;
                        }

                        h6 {
                            width: 35px;
                            margin-left: auto;
                            margin-right: auto;
                            height: 16px;
                            @extend %loader-dark;
                        }
                    }
                }
            }
        }
    }

    .mobile-filter {
        h5 {
            width: 92px;
            height: 18px;
            background-color: $bg-loader;
        }

        img {
            opacity: 0.3;
        }

        .ldr-img {
            margin-left: auto;
            width: 16px;
            height: 13px;
            background-color: $bg-loader;
        }
    }

    .special-section {
        .special-box {
            .special-img {
                width: 100%;
                height: 218px;
                background-color: $bg-loader;

                &.tour-img {
                    height: calc(195px + (325 - 195) * ((100vw - 320px) / (1920 - 320)));
                }
            }
        }

        &.grid-box {
            .special-box {
                .special-content {
                    h5 {
                        height: 30px;
                        @extend %loader-dark;
                    }

                    &.restaurant-detail {
                        ul {
                            li {
                                width: 30%;
                                height: 24px;
                                @extend %loader-dark;

                                &:after {
                                    display: none;
                                }

                                + li {
                                    margin-top: 7px;
                                }

                                &:nth-child(2) {
                                    width: 35%;
                                }
                            }
                        }
                    }

                    p {
                        span {
                            display: block;
                            width: 95%;
                            height: 18px;
                            @extend %loader-dark;

                            &:nth-child(2) {
                                width: 75%;
                                margin-top: 5px;
                            }
                        }
                    }

                    .bottom-section {
                        .price {
                            span {
                                width: 28%;
                                height: 25px;
                                @extend %loader-dark;
                            }
                        }

                        .facility-detail {
                            width: 15%;
                            height: 25px;
                            @extend %loader-dark;
                        }
                    }

                    .tour-detail {
                        h6 {
                            width: 80%;
                            height: 18px;
                            @extend %loader-dark;
                        }

                        span {
                            width: 48px;
                            height: 22px;
                            display: block;
                            @extend %loader-dark;
                        }

                        .bottom-section {
                            .price {
                                h6 {
                                    width: 25%;
                                    margin-bottom: 8px;
                                }
                            }
                        }

                        .include-sec {
                            ul {
                                li {
                                    .ldr-img {
                                        width: 18px;
                                        height: 18px;
                                        margin-left: auto;
                                        margin-right: auto;
                                        background-color: $bg-loader;
                                    }

                                    .ldr-text {
                                        width: 48px;
                                        height: 22px;
                                        margin-top: 5px;
                                        @extend %loader-dark;
                                    }
                                }
                            }
                        }
                    }
                }

                &.cab-box {
                    .special-content {
                        .button-botton {
                            margin-top: 10px;

                            .ldr-btn {
                                width: 100%;

                                div {
                                    width: 100%;
                                    height: 42px;
                                    background-color: $bg-loader;
                                }
                            }
                        }

                        ul {
                            margin-top: 10px;

                            li {
                                width: 40%;
                                height: 24px;
                                @extend %loader-dark;

                                &:nth-child(3),
                                &:nth-child(5) {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .rating {
        width: 43%;
        height: 18px;
        @extend %loader-dark;
    }

    .map-section {
        iframe,
        .map {
            background-color: white;
        }
    }

    .filter-panel {
        .left-filter {
            .filters {
                ul {
                    li {
                        width: 90px;
                        height: 38px;
                    }
                }
            }
        }

        .collection-grid-view {
            .filter-select {
                li {
                    background-color: $bg-loader;
                }
            }
        }

        .popup-btn {
            h5 {
                width: 112px;
                height: 20px;
                @extend %loader-dark;
            }

            img {
                filter: invert(0.8);
            }
        }
    }

    .filter_button {
        h5 {
            width: 118px;
            height: 20px;
            @extend %loader-dark;
        }
    }

    .filter_button {
        img {
            -webkit-filter: invert(80%);
        }
    }

    .top-bar-flight {
        .date-fare-slider {
            .fare-box {
                h5 {
                    width: 44%;
                    height: 16px;
                    margin-left: auto;
                    margin-right: auto;
                    @extend %loader-dark;
                }

                h6 {
                    width: 38%;
                    height: 16px;
                    margin-left: auto;
                    margin-right: auto;
                    @extend %loader-dark;
                }
            }
        }

        .fare-calender {
            h6 {
                &.title {
                    width: 62px;
                    height: 12px;
                    @extend %loader-dark;
                }
            }

            i {
                width: 20px;
                margin-left: auto;
                margin-right: auto;
                height: 20px;
                @extend %loader-dark;
            }
        }
    }

    .single-section {
        .description-section {
            .menu-top {
                li {
                    margin: 10px 10px 0;

                    a {
                        width: 112px;
                        height: 25px;
                        margin-left: auto;
                        margin-right: auto;
                        padding: 11px 30px;
                        @extend %loader-dark;
                    }
                }
            }

            .description-details {
                .menu-part {
                    &.about {
                        h6 {
                            width: 23%;
                            height: 18px;
                            @extend %loader-dark;
                        }

                        p {
                            span {
                                display: block;
                                width: 97%;
                                height: 18px;
                                @extend %loader-dark;

                                + span {
                                    margin-top: 7px;
                                }

                                &:last-child {
                                    width: 70%;
                                    ;
                                }
                            }
                        }

                        .about-sec {
                            h4 {
                                width: 21%;
                                height: 22px;
                                @extend %loader-dark;
                            }

                            ul {
                                padding-left: 0;

                                li {
                                    width: 68%;
                                    height: 24px;
                                    display: inline-block;
                                    @extend %loader-dark;

                                    &:nth-child(2),
                                    &:nth-child(4) {
                                        width: 60%;
                                    }

                                    &:nth-child(3),
                                    &:nth-child(5) {
                                        width: 75%;
                                    }

                                    + li {
                                        margin-top: 6px;
                                    }
                                }
                            }
                        }

                        .detail-img {
                            height: calc(60px + (250 - 60) * ((100vw - 320px) / (1920 - 320)));
                            width: 100%;
                            background-color: $bg-loader;
                        }
                    }

                    .rooms-box {
                        .room-title {
                            width: 100px;
                            height: 18px;
                            @extend %loader-dark;
                        }

                        .zoom-gallery {
                            width: 250px;
                            height: 160px;
                            background-color: $bg-loader;
                        }

                        .room-detail {
                            h6 {
                                width: 70px;
                                height: 16px;
                                @extend %loader-dark;
                            }

                            .facility-detail {
                                li {
                                    width: 48%;
                                    height: 20px;
                                    @extend %loader-dark;
                                }
                            }
                        }

                        .price-details {
                            h6 {
                                width: 60px;
                                margin-left: auto;
                                height: 16px;
                                @extend %loader-dark;
                            }

                            h5 {
                                width: 70px;
                                margin-left: auto;
                                height: 25px;
                                margin-top: 8px;
                                @extend %loader-dark;
                            }

                            .ldr-btn {
                                width: 100px;
                                height: 38px;
                                margin-top: 8px;
                                background-color: $bg-loader;
                            }
                        }
                    }
                }
            }

            &.tab-section {
                .menu-top {
                    .nav-tabs {
                        overflow: hidden;
                    }
                }
            }
        }

        .single-sidebar {
            .overlay-map {
                height: 100px;
                background-color: $bg-loader;
            }

            h4 {
                &.title {
                    width: 120px;
                    height: 26px;
                    @extend %loader-dark;
                }
            }

            .order-cart {
                .cart-items {
                    .items {
                        h6 {
                            width: 174px;
                            height: 18px;
                            @extend %loader-dark;
                        }

                        h5 {
                            width: 55px;
                            height: 18px;
                            @extend %loader-dark;
                        }

                        .qty-box {
                            .input-group {
                                width: 120px;
                                height: 30px;
                                background-color: $bg-loader;
                            }
                        }

                        .price {
                            width: 52px;
                            height: 22px;
                            @extend %loader-dark;
                        }
                    }
                }

                .cart-bottom {
                    p {
                        width: 200px;
                        height: 24px;
                        @extend %loader-dark;
                    }

                    .sub-total {
                        width: 70px;
                        height: 24px;
                        @extend %loader-dark;
                    }

                    .checkout {
                        .ldr-btn {
                            height: 40px;
                            width: 100%;
                            background-color: $bg-loader;
                        }
                    }
                }
            }

            .price-part {
                .left-part {
                    span {
                        width: 120px;
                        height: 18px;
                        @extend %loader-dark;

                        + span {
                            margin-top: 6px;
                        }
                    }

                    .ldr-link {
                        width: 78px;
                        height: 18px;
                        @extend %loader-dark;
                    }
                }

                .right-part {
                    span {
                        width: 52px;
                        height: 16px;
                        display: block;
                        margin-left: auto;
                        @extend %loader-dark;
                    }

                    p {
                        width: 45px;
                        margin-top: 4px;
                        height: 22px;
                        margin-left: auto;
                        @extend %loader-dark;
                    }

                    h6 {
                        width: 50px;
                        height: 22px;
                        margin-left: auto;
                        margin-top: 4px;
                        @extend %loader-dark;
                    }
                }
            }

            .book-btn-section {
                .ldr-input {
                    width: 100%;
                    height: 38px;
                    background-color: $bg-loader;

                    + .ldr-input {
                        margin-top: 5px;
                    }
                }

                .ldr-btn {
                    width: 130px;
                    height: 38px;
                    background-color: $bg-loader;
                    margin-left: auto;
                    margin-top: 16px;
                }
            }

            .contact-title {
                width: 96px;
                height: 18px;
                @extend %loader-dark;
            }

            .address {
                span {
                    display: block;
                    width: 90%;
                    height: 22px;
                    @extend %loader-dark;

                    + span {
                        width: 80%;
                        margin-top: 5px;
                    }
                }
            }

            .social-box {
                width: 120px;
                height: 18px;
                @extend %loader-dark;
            }

            .bottom_sec {
                h6 {
                    width: 120px;
                    height: 18px;
                    @extend %loader-dark;

                    + h6 {
                        width: 140px;
                    }
                }
            }

            .newsletter-sec {
                p {
                    span {
                        display: block;
                        width: 98%;
                        height: 18px;
                        @extend %loader-dark;

                        + span {
                            margin-top: 5px;
                        }

                        &:last-child {
                            width: 25%;
                        }
                    }
                }

                .ldr-input {
                    width: 100%;
                    height: 38px;
                    background-color: $bg-loader;
                }

                .ldr-btn {
                    width: 130px;
                    height: 38px;
                    background-color: $bg-loader;
                    margin-left: auto;
                    margin-top: 16px;
                }
            }
        }

        .image_section {
            .left {
                .ldr-img {
                    width: 100%;
                    height: calc(300px + (412 - 300) * ((100vw - 320px) / (1920 - 320)));
                    background-color: $bg-loader;
                }
            }

            .right {
                .ldr-img {
                    width: 100%;
                    height: calc(140px + (196 - 140) * ((100vw - 320px) / (1920 - 320)));
                    background-color: $bg-loader;

                    + .ldr-img {
                        margin-top: 20px;
                    }
                }
            }

            .list-img {
                .slider-image {
                    .ldr-img {
                        width: 100%;
                        height: calc(194px + (695 - 194) * ((100vw - 320px) / (1920 - 320)));
                        background-color: white;
                    }
                }

                .slider-thumbnail {
                    span {
                        display: block;
                        height: calc(38px + (85 - 38) * ((100vw - 320px) / (1920 - 320)));
                        background-color: white;
                    }
                }
            }
        }
    }

    .slider-img {
        height: calc(280px + (400 - 280) * ((100vw - 320px) / (1920 - 320)));
        background-color: $bg-loader;
    }

    .order-menu {
        .nav {
            .nav-link {
                width: 70%;
                height: 30px;
                margin-top: 8px;
                @extend %loader-dark;
            }
        }
    }

    .order-food-section {
        background-color: $bg-loader;
    }

    .order-menu-section {
        .order-section {
            .order-title {
                h5 {
                    width: 96px;
                    height: 24px;
                    @extend %loader-dark;
                }

                h6 {
                    width: 54px;
                    height: 20px;
                    @extend %loader-dark;
                }
            }

            .order-items {
                .items {
                    h6 {
                        width: 174px;
                        height: 18px;
                        @extend %loader-dark;
                    }

                    p {
                        span {
                            display: block;
                            width: 80%;
                            height: 16px;
                            @extend %loader-dark;

                            + span {
                                margin-top: 6px;
                            }

                            &:nth-child(2) {
                                width: 30%;
                            }
                        }
                    }

                    h5 {
                        width: 55px;
                        height: 18px;
                        @extend %loader-dark;
                    }

                    .addtocart_btn {
                        .add-button,
                        .add_cart {
                            height: 28px;
                        }
                    }
                }
            }
        }
    }

    .top-title-bar {
        .hotel_title_section {
            h2 {
                width: 140px;
                height: 26px;
                @extend %loader-dark;
            }

            h6 {
                width: 160px;
                height: 20px;
                @extend %loader-dark;
            }

            ul {
                width: 270px;
                height: 24px;
                @extend %loader-dark;
            }

            .right-part {
                h6 {
                    margin-left: auto;
                }
            }
        }
    }

    .search-panel {
        .search-section {
            .search-box {
                .left-part {
                    .search-body {
                        width: 100%;

                        h6 {
                            width: 46px;
                            height: 16px;
                            margin-left: auto;
                            margin-right: auto;
                            @extend %loader-dark;
                        }

                        .form-control {
                            width: 80px;
                            height: 26px;
                            margin-left: auto;
                            margin-right: auto;
                            @extend %loader-dark;
                        }
                    }
                }

                .right-part {
                    .ldr-btn {
                        width: 108px;
                        height: 40px;
                        background-color: $bg-loader;
                    }
                }
            }
        }
    }

    .list-view {
        .list-box {
            .list-img {
                height: calc(180px + (210 - 180) * ((100vw - 320px) / (1920 - 320)));
                background-color: $bg-loader;
            }

            .list-content {
                h5 {
                    width: 26%;
                    height: 22px;
                    @extend %loader-dark;
                }

                p {
                    width: 22%;
                    height: 18px;
                    margin-top: 6px;
                    @extend %loader-dark;
                }

                .facility-icon {
                    .facility-box {
                        width: 42px;
                        height: 42px;
                        background-color: $bg-loader;
                    }
                }

                .price {
                    span {
                        width: 65px;
                        height: 18px;
                        display: block;
                        @extend %loader-dark;
                    }
                }

                .book-now {
                    width: 135px;
                    height: 40px;
                    background-color: $bg-loader;
                }

                h6 {
                    width: 60%;
                    height: 18px;
                    @extend %loader-dark;
                }
            }
        }
    }

    .hotel-single-section {
        background-color: $bg-loader;
    }

    .hotel_title_section {
        .hotel-name {
            .left-part {
                .top {
                    h2 {
                        width: 340px;
                        height: 30px;
                        @extend %loader-dark;
                    }

                    .share-buttons {
                        display: flex;
                        align-items: center;

                        .ldr-btn {
                            width: 88px;
                            height: 30px;
                            background-color: $bg-loader;
                            margin-left: 10px;
                        }
                    }
                }

                p {
                    width: 65%;
                    height: 18px;
                    @extend %loader-dark;
                }

                .facility-detail {
                    div {
                        width: 70px;
                        height: 22px;
                        @extend %loader-dark;
                        display: inline-block;
                        margin-right: 7px;
                    }
                }
            }

            .right-part {
                .price {
                    width: 160px;
                    height: 30px;
                    @extend %loader-dark;
                }

                .ldr-btn {
                    width: 145px;
                    height: 38px;
                    background-color: $bg-loader;
                    margin-left: auto;
                }
            }
        }
    }

    .full-width-detail {
        .about-section {
            .about-text {
                > div {
                    width: 100%;
                }

                .right-detail {
                    h4 {
                        width: 25%;
                        height: 25px;
                        @extend %loader-dark;
                    }
                }

                .bottom-detail {
                    p {
                        width: 100%;
                        height: 100%;

                        span {
                            display: block;
                            width: 97%;
                            height: 18px;
                            @extend %loader-dark;

                            + span {
                                margin-top: 5px;
                            }

                            &:last-child {
                                width: 85%;
                            }
                        }
                    }
                }
            }
        }

        .place-detail {
            li {
                .ldr-text {
                    width: calc(55px + (125 - 55) * ((100vw - 320px) / (1920 - 320)));
                    height: 22px;
                    @extend %loader-dark;
                }

                + li {
                    &:after {
                        background-color: rgba(black, 0.06);
                    }
                }
            }
        }
    }

    .about-section {
        .image-section {
            .img-box {
                height: calc(175px + (460 - 175) * ((100vw - 320px) / (1920 - 320)));
                width: 100%;
                background-color: $bg-loader;
            }
        }
    }
}

@-webkit-keyframes skeleton-loader {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: -468px 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: 468px 0;
    }
}

@keyframes skeleton-loader {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: -468px 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: 468px 0;
    }
}


/*=====================
26.Tap to top CSS
==========================*/

.tap-top {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    position: fixed;
    bottom: 50px;
    right: -60px;
    z-index: 8;
    color: white;
    text-align: center;
    background: $theme-color1;
    border: none;
    font-size: 22px;
    padding: 6px 5px 5px 5px;
    cursor: pointer;
    transform: rotate(-15deg);
    transition: all 0.5s ease;

    &:after {
        position: absolute;
        content: "";
        bottom: -12px;
        background-color: black;
        width: 100%;
        height: 11px;
        left: 0;
        transform: rotate(13deg);
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.top {
        display: block;
        right: 30px;
        transition: all 0.5s ease;
    }
}


/*=====================
27. Animation & effect css
==========================*/

.circle {
    border-radius: 50%;
    background-color: white;
    position: absolute;
    height: 150px;
    width: 150px;
    top: 50%;
    left: 92px;
    transition: height 5s ease, width 5s ease;
    transform: translate(-50%, -50%);
    z-index: 0;
    opacity: 0.5;

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        border: 1px solid white;
    }

    &:before {
        animation: ripple 2s linear infinite;
    }

    &:after {
        animation: ripple 2s linear 1s infinite;
    }

    &.b-round {
        height: 230px;
        width: 230px;
        left: 50%;
    }
}

.animation-circle-inverse {
    i {
        background: rgba($theme-color1, 0.15);
        right: 0;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11);
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 100%;
        opacity: 0.3;
        top: 0;
        transform: scale(1);
        animation: ripple1 3s linear infinite;

        &:nth-child(2) {
            animation: ripple2 3s linear infinite;
        }

        &:nth-child(3) {
            animation: ripple3 3s linear infinite;
        }
    }
}

.animated-section {
    overflow: hidden;
    position: relative;

    .animation-section {
        opacity: 0.3;

        .round {
            width: 12px;
            height: 12px;
            background-color: transparent;
            border: 2px solid $theme-color1;
            border-radius: 100%;
            position: absolute;
            animation: myOrbit 9s linear infinite;

            &.r-2 {
                width: 15px;
                height: 15px;
            }

            &.r-y {
                border-color: #e8b10b;
            }
        }

        .square {
            width: 12px;
            height: 12px;
            background-color: transparent;
            position: absolute;
            border: 2px solid $theme-color1;
            animation: animationFramesTwo 50s infinite linear;

            &.s-2 {
                width: 15px;
                height: 15px;
            }
        }

        .cross {
            height: 12px;
            width: 12px;
            position: absolute;
            animation: animationFramesTwo 50s infinite linear;

            &:after,
            &:before {
                position: absolute;
                content: " ";
                height: 12px;
                width: 2px;
                background-color: $theme-color1;
            }

            &:after {
                transform: rotate(-45deg);
            }

            &:before {
                transform: rotate(45deg);
            }
        }

        .po-1 {
            top: 50%;
            left: 5%;
        }

        .po-2 {
            right: 5%;
            bottom: 7%;
        }

        .po-3 {
            top: 20%;
            right: 8%;
        }

        .po-4 {
            bottom: 5%;
            left: 30%;
        }

        .po-5 {
            right: 20%;
            top: 10%;
        }

        .po-6 {
            top: 2%;
            left: 47%;
        }

        .po-7 {
            right: 1%;
            top: 53%;
        }

        .po-8 {
            bottom: 1%;
            right: 27%;
        }

        .po-9 {
            top: 24%;
            right: 30%;
        }

        .po-10 {
            top: 5%;
            left: 15%;
        }

        .po-11 {
            bottom: 32%;
            left: 36%;
        }

        .po-12 {
            left: 2%;
            bottom: 10%;
        }
    }
}

.zig-zag-effect {
    position: relative;

    &:after {
        position: absolute;
        content: "";
        background: url("../../../common/images/zig-zag-1.png");
        bottom: 0;
        width: 100%;
        height: 5px;
    }

    &:before {
        position: absolute;
        content: "";
        background: url(../../../common/images/zig-zag-1.png);
        top: 0;
        width: 100%;
        height: 5px;
        transform: rotate(180deg);
    }

    &.detail-section {
        .detail-box {
            .upper-part {
                margin-bottom: 20px;
                min-height: unset;

                h2 {
                    padding-bottom: 15px;
                    margin-bottom: 0px;

                    &:after {
                        display: none;
                    }
                }

                h6 {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.parallax-effect {
    .food-img {
        position: absolute;
        z-index: -1;

        &.food1 {
            left: 19%;
        }

        &.food2 {
            left: 9%;

            img {
                width: 90px;
            }
        }

        &.food3 {
            img {
                width: 100px;
            }
        }

        &.food4 {
            left: 7%;

            img {
                width: 80px;
            }
        }
    }

    img {
        width: 100px;
    }
}

.animation-bg {
    position: relative;
    z-index: 1;

    &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: "";
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        opacity: 0.6;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes ani {
    0% {
        transform: translate3d(0, 0, 0);
        text-shadow: 0em 0em 0 $theme-color1;
        color: white;
    }

    30% {
        transform: translate3d(0, 0, 0);
        text-shadow: 0em 0em 0 $theme-color1;
        color: white;
    }

    70% {
        transform: translate3d(0.08em, -0.08em, 0);
        text-shadow: -0.08em 0.08em $theme-color1;
        color: white;
    }

    100% {
        transform: translate3d(0.08em, -0.08em, 0);
        text-shadow: -0.08em 0.08em $theme-color1;
        color: white;
    }
}

@keyframes ripple2 {
    0% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1.6);
    }
}

@keyframes ripple3 {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1.4);
    }
}

@keyframes ripple {
    0% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.75);
        opacity: 0.4;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes effect {
    0% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.4);
        opacity: 0.4;
    }

    100% {
        transform: scale(1.6);
        opacity: 0;
    }
}

@keyframes slide {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -1000px 0;
    }
}

@-webkit-keyframes myOrbit {
    from {
        -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}

@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-5px);
    }
}

@keyframes zoom_in {
    0% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

// bird animation
.bird {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells.svg);
    background-size: auto 100%;
    width: 88px;
    height: 125px;
    will-change: background-position;
    animation-name: fly-cycle;
    animation-timing-function: steps(10);
    animation-iteration-count: infinite;

    &--one {
        animation-duration: 1s;
        animation-delay: -0.5s;
    }

    &--two {
        animation-duration: 0.9s;
        animation-delay: -0.75s;
    }

    &--three {
        animation-duration: 1.25s;
        animation-delay: -0.25s;
    }

    &--four {
        animation-duration: 1.1s;
        animation-delay: -0.5s;
    }
}

.bird-container {
    position: absolute;
    top: 20%;
    left: -10%;
    transform: scale(0) translateX(-10vw);
    will-change: transform;
    animation-name: fly-right-one;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &--one {
        animation-duration: 15s;
        animation-delay: 0s;
    }

    &--two {
        animation-duration: 16s;
        animation-delay: 1s;
    }

    &--three {
        animation-duration: 14.6s;
        animation-delay: 9.5s;
    }

    &--four {
        animation-duration: 16s;
        animation-delay: 10.25s;
    }
}

@keyframes fly-cycle {
    100% {
        background-position: -900px 0;
    }
}

@keyframes fly-right-one {
    0% {
        transform: scale(0.3) translateX(-10vw);
    }

    10% {
        transform: translateY(2vh) translateX(10vw) scale(0.4);
    }

    20% {
        transform: translateY(0vh) translateX(30vw) scale(0.5);
    }

    30% {
        transform: translateY(4vh) translateX(50vw) scale(0.6);
    }

    40% {
        transform: translateY(2vh) translateX(70vw) scale(0.6);
    }

    50% {
        transform: translateY(0vh) translateX(90vw) scale(0.6);
    }

    60% {
        transform: translateY(0vh) translateX(110vw) scale(0.6);
    }

    100% {
        transform: translateY(0vh) translateX(110vw) scale(0.6);
    }
}

@keyframes fly-right-two {
    0% {
        transform: translateY(-2vh) translateX(-10vw) scale(0.5);
    }

    10% {
        transform: translateY(0vh) translateX(10vw) scale(0.4);
    }

    20% {
        transform: translateY(-4vh) translateX(30vw) scale(0.6);
    }

    30% {
        transform: translateY(1vh) translateX(50vw) scale(0.45);
    }

    40% {
        transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
    }

    50% {
        transform: translateY(0vh) translateX(90vw) scale(0.45);
    }

    51% {
        transform: translateY(0vh) translateX(110vw) scale(0.45);
    }

    100% {
        transform: translateY(0vh) translateX(110vw) scale(0.45);
    }
}

// flight fly animation
@mixin matrix($x, $y, $angle) {
    transform: translate($x, $y) rotate($angle);
}

.flight {
    width: calc(140px + (200 - 140) * ((100vw - 320px) / (1920 - 320)));
    height: calc(70px + (100 - 70) * ((100vw - 320px) / (1920 - 320)));
    background: url("../images/flights/airliner.png") center no-repeat;
    background-size: 100%;
    position: absolute;
    bottom: -4px;
    right: 0;

    &.run {
        animation: landFlight linear 10s;
    }
}

@keyframes landFlight {
    0% {
        @include matrix(0, 0, 0deg);
    }

    20% {
        @include matrix(-600px, -1px, 0deg);
    }

    50% {
        @include matrix(-650px, -2px, 0deg);
    }

    58% {
        @include matrix(-700px, -5px, 10deg);
    }

    59% {
        @include matrix(-710px, -10px, 15deg);
    }

    60% {
        @include matrix(-720px, -12px, 20deg);
    }

    70% {
        @include matrix(-800px, -50px, 20deg);
    }

    80% {
        @include matrix(-900px, -100px, 20deg);
    }

    100% {
        @include matrix(-2200px, -750px, 20deg);
    }
}

.plane-animation {
    animation-name: plane-animation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes plane-animation {
    0% {
        //margin-top: 135px;
        transform: translateY(-50px);
    }

    50% {
        //margin-top: 165px;
        transform: translateY(0);
    }

    100% {
        //margin-top: 130px;
        transform: translateY(-50px);
    }
}


/*=====================
28. Theme setting css
==========================*/

.theme-setting {
    position: fixed;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    right: -52px;
    display: flex;
    z-index: 10;

    .dark,
    .rtl {
        margin-left: 5px;
        margin-right: 5px;
    }

    .rtl {
        .tgl-skewed {
            &:checked {
                + .tgl-btn {
                    background: #dcdcdc;
                }
            }
        }
    }

    .tgl {
        display: none;

        + .tgl-btn {
            outline: 0;
            display: block;
            width: 4em;
            height: 2em;
            position: relative;
            cursor: pointer;
            user-select: none;

            &:before {
                display: none;
            }

            &:after {
                left: 0;
            }

            &:after,
            &:before {
                position: relative;
                display: block;
                content: "";
                width: 50%;
                height: 100%;
            }
        }

        &:checked {
            + .tgl-btn {
                &:after {
                    left: 50%;
                }
            }
        }
    }

    .tgl-skewed {
        + .tgl-btn {
            overflow: hidden;
            transform: skew(-10deg);
            backface-visibility: hidden;
            transition: all .2s ease;
            font-family: sans-serif;
            background-color: #424242;
            outline: 0;
            display: block;
            width: 4em;
            height: 2em;
            position: relative;
            cursor: pointer;
            user-select: none;

            &:after,
            &:before {
                transform: skew(10deg);
                display: inline-block;
                transition: all .2s ease;
                width: 100%;
                text-align: center;
                position: absolute;
                line-height: 2em;
                font-weight: bold;
                color: #000;
                text-shadow: 0 1px 0 rgba(0, 0, 0, .4);
            }

            &:after {
                left: 100%;
                content: attr(data-tg-on);
            }

            &:before {
                left: 0;
                color: white;
                content: attr(data-tg-off);
            }

            &:active {
                background: #888;

                &:before {
                    left: -10%;
                }
            }
        }

        &:checked + .tgl-btn {
            background: #ffffff;

            &:before {
                left: -100%;
            }

            &:after {
                left: 0;
            }

            &:active:after {
                left: 10%;
            }
        }
    }

    &.back {
        z-index: 1;
    }

    &.one-setting {
        right: -20px;
    }
}
